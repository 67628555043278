import React, { useEffect, useState } from 'react'

export default function CommonActiveModal({show,confirm,setShow,status}) {
    const [ShowModal,setShowModal]=useState("none")
    useEffect(()=>{
    setShowModal(show);
    },[show])
  return (
    <div> 
  
<div class={`modal  d-${show}`}  tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content p-5">
      {/* <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{status=="active"?"Deactive":"Active"}</h5>
        <button type="button" class="close" onClick={e=>setShow("none")} data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> */}
      <div class="modal-body text-center"> 
      <h5 className='mb-3 text-black'>Do you want to {status=="active"?"Deactivate":status=="deactive"?"Activate":status} this ?</h5>
      
      </div>
      <div class="text-center">
        <button type="button" onClick={e=>setShow("none")} class="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
        <button type="button" onClick={e=>confirm()} class="btn btn-primary">Confirm</button>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}
