/*
 * @Author: Ghost
 * @Email: Ghost@yopmail.com
 * @Date: 2024-01-12 18:42:15
 * @Last Modified by: Someone
 * @Last Modified time: 2024-01-24 18:58:53
 * @Description: file:///home/jc-21/LentifyProject/clone_kiralabunu_frontend/src/pages/CheckOutSteps/index.js
 */
import { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import "./style.scss";
import { Accordion } from "react-bootstrap";
import ApiClient from "../../apimethods/apiClient";
import loader from "../../components/loader/loader";
import { useSelector } from "react-redux";
import GooglePlaceAutoComplete from "../../components/common/googleplaceautocomplete";
import addressModel from "../../models/address.model";
import methodModel from "../../components/validations/Validations";
import { toast } from "react-toastify";
import Footer from "../../components/global/footer/Footer";
import { useNavigate } from "react-router-dom";
import Header from "../../components/global/layout/Header";
import CryptoJS from "crypto-js";
import environment from "../../environment";
import TokenIframe from "../Tokenize/Tokenize";

const CheckoutSection = () => {
  const history = useNavigate();
  const mode = useSelector((state) => state.user);
  const userid = localStorage.getItem("userid");
  const [FreeDelivery, setFreeDelivery] = useState(false);
  const [ShippingEditData, setShippingEditData] = useState(null);
  const user = useSelector((state) => state.user);
  const [step, setstep] = useState("0");
  const [Orders, setOrders] = useState([]);
  const [Amount, setAmount] = useState(0);
  const [SelectedShippingAddress, setSelectedShippingAddress] = useState({});
  const [selectedCard, setSelectedCard] = useState({});
  const [selectedBankAcc, setSelectedbankAcc] = useState({});
  const [cardprofileId, setCardProfileId] = useState("");
  const [bankProfileId, setBankProfileId] = useState("");
  const [AddShippingAddress, setAddShippingAddress] = useState({
    type: "common",
    address: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    lat: "",
    lng: "",
  });
  const [AddNewCard, setAddNewCard] = useState({
    nameoncard: "",
    cvv: "",
    expiry: "",
    amount: "",
  });
  const [ShowShippingAddress, setShowShippingAddress] = useState(false);
  const [showNewCard, setShowNewCard] = useState(false);
  const [totalAmount, setTotalAmount] = useState("");
  const cartData = JSON.parse(localStorage.getItem("cart_products"));
  const [accountNumber, setAccountNumber] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [cvv, setCvv] = useState("");
  const [routingNo, setroutingNo] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [nameonCard, setNameOnCard] = useState("");
  const [bankingname, setbankingName] = useState("");
  const [bankAccNo, setBankAccNo] = useState("");
  const [marchantId, setMarchantId] = useState("");
  const [AllAddress, setAllAddress] = useState([]);
  console.log(AllAddress, "iterm address")
  const [allCards, setAllCards] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [retref, setRetRef] = useState("");
  const [SendBiller, setSendBiller] = useState(false);
  const [token, setToken] = useState("");
  const [error, setError] = useState("")
  const [showNewBankAcc, setShowNewBankAcc] = useState(false);
  const [BillingAddress, setBillingAddress] = useState({
    is_same_billing_add: SendBiller == "true" ? false : true,
    billing_address: "",
    billing_country: "",
    billing_state: "",
    billing_city: "",
    billing_pincode: "",
    billing_lat: "",
    billing_lng: "",
  });
  const [savedAddressId, setSavedAddressId] = useState({})
  const [choosePayment, setChoosePayment] = useState("paybycard");
  const secretKey = "jCu31UkI100ooYo230sc";
  useEffect(() => {
    if (user?.role_name == "Merchant") {
      history("/");
    }
  }, []);



  //------------------------function to calculate the amount ---------------

  useEffect(() => {
    if (Amount) {
      ApiClient.get(`settings`).then((res) => {
        if (res.success) {
          if (Number(res?.data?.free_delivery_on) < Amount) {
            setFreeDelivery(true);
          } else {
            setFreeDelivery(false);
          }
        }
      });
    }
  }, [Amount]);

  useEffect(() => {
    GetuserCheckoutdata();
  }, []);

  const GetuserCheckoutdata = async () => {
    loader(true);
    const res = await JSON.parse(localStorage.getItem("cart_products"));
    const newdata = await JSON.parse(localStorage.getItem("cart_products"));
    let totalprice = 0;
    res?.map((item) => {
      let data = parseFloat(item.price);
      totalprice += parseFloat(data * parseInt(item.qty));
    });
    setTotalAmount(totalprice);
    setOrders([...newdata]);
    loader(false);
  };

  useEffect(() => {
    if (user._id) {
      GetAllAddress();
    }
  }, [user._id]);

  //-----------------------get all address api integration ----------------------

  const GetAllAddress = () => {
    loader(true);
    ApiClient.get(`address/all?user_id=${user._id}`).then((res) => {
      if (res.success) {
        let data = res?.data
        setAllAddress(res.data && res.data);
      }
      loader(false);
    });
  };

  //---------------------function on change on expiry date -------------------------

  const handleExpiryDateChange = (event) => {
    let inputText = event.target.value;
    inputText = inputText.replace(/\D/g, "");
    let month = "";
    let year = "";
    if (inputText.length >= 2) {
      month = inputText.slice(0, 2);
      year = inputText.slice(2, 4);
    } else if (inputText.length > 0) {
      month = inputText;
    }
    let formattedExpiryDate = `${month}/${year}`;
    setMonth(month);
    setYear(year);
    setExpiryDate(formattedExpiryDate);
  };

  //-------------------- function on change on card number -------------------------

  const handleAccountNumberChange = (event) => {
    const inputText = event.target.value;
    if (
      inputText === "" ||
      (/^[0-9]+$/.test(inputText) && inputText.length <= 16)
    ) {
      setAccountNumber(inputText);
    }
  };

  //-------------------- function on change on bank account number -------------------------

  const handleBankAccountNumberChange = (event) => {
    const inputText = event.target.value;
    if (
      inputText === "" ||
      (/^[0-9]+$/.test(inputText) && inputText.length <= 16)
    ) {
      setBankAccNo(inputText);
    }
  };

  //-----------------------  place order and payment api ------------------------

  const PaymentHandler = async (e) => {
    e.preventDefault();
    loader(true);
    const BillingPayload = BillingAddress;
    const payload = {
      address_id: SelectedShippingAddress?.id,
      type: "cart",
      mode: mode?.mode ? "live" : "sandbox",
      ...BillingPayload,
      isAch: choosePayment == "paybycard" ? false : true,
    };
    ApiClient.post("order", payload).then((response) => {
      if (response.success) {
        toast?.success(response?.message);
        ApiClient.post("v1/pay-now", {
          order_id: response?.data?._id,
          mode: mode?.mode ? "live" : "sandbox",
          retref: retref,
          merchant_id: marchantId,
          isAch: choosePayment == "paybycard" ? false : true,
        }).then((res) => {
          if (res.success) {
            toast?.success(res?.message);
            ApiClient.post("inquire", {
              mode: mode?.mode ? "live" : "sandbox",
              merchant_id: marchantId,
              retref: res?.data?.retref,
            }).then((res) => {
              if (res.success) {
                localStorage.removeItem("cart_products");
                history("/placeorder");
              }
            });
          } else {
            toast?.error(res?.message);
          }
        });
        loader(false);
      } else {
        toast?.error(response?.message);
      }
    });
  };

  useEffect(() => {
    if (user?.role_name != "Marchant") {
      if (localStorage?.getItem("merSlug")) {
        getUserDetailBySlug();
      }
    }
  }, [choosePayment]);

  //------------------------ get marchant id by mmarchant slug api -------------------

  const getUserDetailBySlug = () => {
    const slug = localStorage?.getItem("merSlug");
    ApiClient.get(`user/slug?slug=${slug}`).then((res) => {
      if (res.success) {
        getCustomerCardDetail(res?.data?.id);
      }
    });
  };

  //----------------------------get cards details of customer api ----------------------

  const getCustomerCardDetail = (id) => {
    setMarchantId(id);
    loader(true);
    ApiClient.get(
      `cp-profile-detail?merchant_id=${id}&mode=${mode?.mode ? "live" : "sandbox"
      }&user_id=${userid}&isAch=${choosePayment === "paybycard" ? false : true}`
    ).then((res) => {
      if (res.success) {
        const cards = res?.data?.cards || [];
        const filteredCards = cards.filter((itm) => itm?.accttype === "ECHK");
        const otherCards = cards.filter((itm) => itm?.accttype !== "ECHK");
        setAllCards(otherCards);
        setBankAccounts(filteredCards);
        setBankProfileId(filteredCards ? filteredCards[0]?.profileid : "Y");
        setCardProfileId(otherCards ? otherCards[0]?.profileid : "Y");
      }
      loader(false);
    });
  };

  //----------------------------- delete address api -----------------------------------

  const ShippingAddressDelete = (id) => {
    loader(true);
    ApiClient.delete(`address?id=${id}`).then((res) => {
      if (res.success) {
        GetAllAddress();
      }
    });
  };

  //------------------- RADIO BUTTON HANDLER FOR SHIPPING FORM ------------------------
  const HandleShippingSelection = (item) => {
    setShowShippingAddress(false);
    setSelectedShippingAddress(item);
  };

  //----------------------RADIO BUTTON HANDLER FOR CARD FORM --------------------------------------

  const HandlecardSelection = (item) => {
    setShowNewCard(false);
    setSelectedCard(item);
  };

  //-----------------------

  const HandleBankAccSelection = (item) => {
    setShowNewBankAcc(false);
    setSelectedbankAcc(item);
  };

  //---------------------------- function on change on shipping address fields change -------------

  const ShippingAddressChangeHandler = (e) => {
    const { name, value } = e.target;
    setAddShippingAddress((prev) => ({ ...prev, [name]: value }));
  };

  //------------------------google  place autocomplete for billing  address -----------------------

  const addressResult = async (e, type = "") => {
    let address = {};
    if (e.place) {
      address = addressModel.getAddress(e.place);
      if (type == "") {
        setAddShippingAddress((prev) => ({ ...prev }));
      } else {
        setBillingAddress((prev) => ({ ...prev }));
      }
    } else {
      if (type == "") {
        setAddShippingAddress((prev) => ({ ...prev }));
      } else {
        setBillingAddress((prev) => ({ ...prev }));
      }
    }

    if (type == "") {
      setAddShippingAddress((prev) => ({
        ...prev,
        address: e.value,
        country: address.country || "",
        city: address.city || "",
        state: address.state || "",
        pincode: address.zipcode || "",
        // lat: `${address.lat}` || "",
        // lng: `${address.lng}` || "",
        lat: "30.7094643",
        lng: "76.695229",
      }));
    } else {
      setBillingAddress((prev) => ({
        ...prev,
        billing_address: e.value,
        billing_country: address.country || "",
        billing_city: address.city || "",
        billing_state: address.state || "",
        billing_pincode: address.zipcode || "",
        // billing_lat: `${address.lat}` || "",
        // billing_lng: `${address.lng}` || "",
        billing_lat: "30.7094643",
        billing_lng: "76.695229",
      }));
    }
  };

  //------------------------ function for showing  address ---------------------------------------

  const addressResultShipping = async (e) => {
    let address = {};
    if (e.place) {
      address = await addressModel.getAddress(e.place);
      setAddShippingAddress((prev) => ({ ...prev, addressSelected: true }));
    } else {
      setAddShippingAddress((prev) => ({ ...prev, addressSelected: false }));
    }
    setAddShippingAddress((prev) => ({
      ...AddShippingAddress,
      address: e.value,
      country: address.country || "",
      city: address.city || "",
      state: address.state || "",
      pincode: address.zipcode || "",
      lat: "30.7094643",
      lng: "76.695229",
      // lat: `${address.lat}` || "",
      // lng: `${address.lng}` || "",
    }));
  };

  //------------------------ edit and add address api ------------------------------------------

  const HandleShippingAddressAdd = (e) => {
    e.preventDefault();
    const payload = AddShippingAddress;
    setAddShippingAddress((pre) => ({ ...pre, submitted: true }));
    if (payload.address == "") return;
    loader(true);
    let method = "post";
    if (ShippingEditData) {
      method = "put";
      payload.id = ShippingEditData.id;
    }
    ApiClient.allApi(`address`, { ...payload }, method).then((res) => {
      if (res?.success) {
        if (method == "post") {
        }
        setSelectedShippingAddress({ ...payload });
        setShowShippingAddress(false);
        GetAllAddress();
        setAddShippingAddress({
          firstName: "",
          lastName: "",
          dialCode: "",
          mobileNo: "",
          address: "",
          country: "",
          state: "",
          city: "",
          pincode: "",
          lat: "",
          lng: "",
          address_type: "",
          addressSelected: false,
          submitted: false,
        });
        toast.success(
          `Address ${method == "post" ? "Added" : "updated"} Successfully`
        );
        setShippingEditData(null);
      }
      loader(false);
    });
  };

  //-------------------------------- function after added address --------------------------------------

  const ShippingStep = (e) => {
    e.preventDefault();
    if (AllAddress?.length > 0) {
      if (!SelectedShippingAddress?.id) {
        toast.error("Please Select Address First");
        return;
      }
    } else {
      toast.error("Please Add the  Address First");
      return;
    }
    if (SendBiller) {
      setstep("2");
      setBillingAddress({
        is_same_billing_add: SendBiller == "true" ? false : true,
        billing_address: SelectedShippingAddress.address,
        billing_country: SelectedShippingAddress.country,
        billing_state: SelectedShippingAddress.state,
        billing_city: SelectedShippingAddress.city,
        billing_pincode: SelectedShippingAddress.pincode,
        billing_lat: SelectedShippingAddress.lat,
        billing_lng: SelectedShippingAddress.lng,
      });
    } else {
      setstep("1");
      setBillingAddress({
        is_same_billing_add: SendBiller == "true" ? false : true,
        billing_address: "",
        billing_country: "",
        billing_state: "",
        billing_city: "",
        billing_pincode: "",
        billing_lat: "",
        billing_lng: "",
      });
    }
  };

  //---------------------------- function on change on billing address fields change -------------

  const BillingAddressChanger = (e) => {
    const { name, value } = e.target;
    setBillingAddress((prev) => ({ ...prev, [name]: value }));
  };

  //--------------------------------- add billing address funtion -----------------------------------

  const BillingStepSubmit = (e) => {
    e.preventDefault();
    setBillingAddress((prev) => ({ ...prev }));
    if (BillingAddress.billing_address == "") {
      return;
    }
    setstep("2");
  };

  //---------------------------------- check the authorization of card api -----------------------

  const handleAuthorizeCard = (e) => {
    e.preventDefault();
    if (allCards && allCards.length > 0) {
      if (!selectedCard.profileid) {
        toast.error("Please Select Card First");
        return;
      }
    }

    const payload = {
      mode: mode?.mode ? "live" : "sandbox",
      merchant_id: marchantId,
      account: selectedCard?.token
        ? selectedCard?.token
        : encryptValue(token),
      expiry: selectedCard?.expiry
        ? selectedCard?.expiry
        : encryptValue(expiryDate),
      amount: totalAmount,
      currency: encryptValue("USD"),
      name: selectedCard?.name ? selectedCard?.name : nameonCard,
      address: SelectedShippingAddress?.address,
      city: SelectedShippingAddress?.city,
      region: SelectedShippingAddress?.state,
      country: SelectedShippingAddress?.country,
      postal: SelectedShippingAddress?.pincode,
      profile: selectedCard?.profileid ? selectedCard?.profileid : "Y",
      acctid: selectedCard?.acctid ? selectedCard?.acctid : "",
      isAch: false,
      cof: user?.role == "customer" ? "M" : "C",
      cofscheduled: "Y"
      // profileupdate: "Y"
    };
    if (allCards?.length == 0 || allCards?.length == undefined) {
      delete payload?.acctid;
    }

    loader(true);
    ApiClient.post("v1/authorize", payload).then((response) => {
      if (response.success) {
        loader(false);
        toast?.success(
          nameonCard
            ? "Card added and authorized successfully"
            : "card authorized successfully"
        );
        setRetRef(response?.data?.retref);
        // setAccountNumber("");
        // setExpiryDate("");
        // setCvv("");
        // setNameOnCard("");
        setstep("3");
      } else {
        toast?.error(response?.message);
      }
    });
  };

  //-----------------------------------check the authorization of bank account api ----------------

  const handleAuthorizeBankAcc = (e) => {
    e.preventDefault();
    if (bankAccounts && bankAccounts.length > 0) {
      if (!selectedBankAcc.profileid) {
        toast.error("Please Select Bank Account First");
        return;
      }
    }

    const payload = {
      mode: mode?.mode ? "live" : "sandbox",
      merchant_id: marchantId,
      account: selectedBankAcc?.token
        ? selectedBankAcc?.token
        : encryptValue(bankAccNo),
      amount: totalAmount,
      currency: encryptValue("USD"),
      name: selectedBankAcc?.name ? selectedBankAcc?.name : bankingname,
      address: SelectedShippingAddress?.address,
      city: SelectedShippingAddress?.city,
      region: SelectedShippingAddress?.state,
      country: SelectedShippingAddress?.country,
      postal: SelectedShippingAddress?.pincode,
      profile: selectedBankAcc?.profileid ? selectedBankAcc?.profileid : "Y",
      isAch: true,
      bankaba: encryptValue(routingNo),
      accttype: "ECHK",
      // profileupdate : "Y"
    };
    if (bankAccounts?.length == 0 || bankAccounts?.length == undefined) {
      delete payload?.accttype;
    }
    if (bankAccounts?.length > 0) {
      delete payload?.bankaba;
    }
    loader(true);
    ApiClient.post("v1/authorize", payload).then((response) => {
      if (response.success) {
        loader(false);
        toast?.success(
          bankingname
            ? "Bank Account added and authorized successfully"
            : "bank Account authorized successfully"
        );
        setRetRef(response?.data?.retref);
        setBankAccNo("");
        setbankingName("");
        setstep("3");
      } else {
        toast?.error(response?.message);
      }
    });
  };
  //-------------------------------------add card api integration --------------------------------------

  const handleBankDetail = (e) => {
    e.preventDefault();
    let payload = {
      mode: mode?.mode ? "live" : "sandbox",
      merchant_id: marchantId,
      account: encryptValue(token),
      expiry: encryptValue(expiryDate),
      amount: totalAmount,
      currency: encryptValue("USD"),
      name: nameonCard,
      address: SelectedShippingAddress?.address,
      city: SelectedShippingAddress?.city,
      region: SelectedShippingAddress?.state,
      country: SelectedShippingAddress?.country,
      postal: SelectedShippingAddress?.pincode,
      // profile: encryptValue(selectedCard?.profile),
      profileupdate: "Y",
      isAch: false,
      profile: cardprofileId,
    };
    loader(true);
    ApiClient.put("add-card", payload).then((response) => {
      if (response.success) {
        loader(false);
        setShowNewCard(false);
        getCustomerCardDetail(marchantId);
        toast?.success("Card added Successfully");
        // setAccountNumber("");
        // setExpiryDate("");
        // setCvv("");
        // setNameOnCard("");
      } else {
        toast?.error(response?.message);
      }
    });
  };

  //-------------------------------------add bank account api integration -------------------------------

  const handleAddBankAccount = (e) => {
    e.preventDefault();
    let payload = {
      mode: mode?.mode ? "live" : "sandbox",
      merchant_id: marchantId,
      account: encryptValue(bankAccNo),
      amount: totalAmount,
      currency: encryptValue("USD"),
      name: bankingname,
      address: SelectedShippingAddress?.address,
      city: SelectedShippingAddress?.city,
      region: SelectedShippingAddress?.state,
      country: SelectedShippingAddress?.country,
      postal: SelectedShippingAddress?.pincode,
      profile: bankProfileId,
      profileupdate: "Y",
      isAch: true,
      bankaba: encryptValue(routingNo),
      accttype: "ECHK",
    };

    loader(true);
    ApiClient.put("add-card", payload).then((response) => {
      if (response.success) {
        loader(false);
        setShowNewBankAcc(false);
        getCustomerCardDetail(marchantId);
        toast?.success("bank account added Successfully");
        setbankingName("");
        setBankAccNo("");
        setroutingNo("");
      } else {
        toast?.error(response?.message);
      }
    });
  };

  //------------------------------------function on click on edit address icon------------------------

  const HandleShippingAddressEditor = (e, item) => {
    e.preventDefault();
    setShowShippingAddress(!ShowShippingAddress);
    setShippingEditData(item);
    // const fullName = item?.fullName?.split(" ")
    setAddShippingAddress({
      address: item?.address,
      country: item?.country,
      state: item?.state,
      city: item?.city,
      pincode: item?.pincode,
      lat: item?.lat,
      lng: item?.lng,
      type: item?.type,
    });
  };

  //---------------------------------- function to change the format of card number---------------------------

  const formatToken = (token) => {
    const tokenStr = token.toString();
    const lastFour = tokenStr.slice(-4);
    const maskedPart = "XXXX-XXXX-XXXX";
    return `${maskedPart}-${lastFour}`;
  };

  //----------------------------------function to handle toggle for choose payment method-------------------

  const HandlechoosePayment = (method) => {
    setChoosePayment(method);
    getCustomerCardDetail(marchantId);
  };

  //----------------------------------- function to encrypt the data ---------------------------------------

  const encryptValue = (value) => {
    return CryptoJS.AES.encrypt(value, environment?.secretKey).toString();
  };

  //------------------------------------function to decrypt the data --------------------------------------

  function decryptData(encryptedValue) {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, environment?.secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  return (
    <>
      <Header />
      <div className="top-padding">
        <div className="checkout_wrapper comman_padding">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-9 col-lg-8 col-xl-8">
                <div className="checkout_page">
                  <div className="heading_check d-flex">
                    <h3>Checkout</h3>
                  </div>
                  <div className="acordians_steps">
                    <Accordion activeKey={`${step}`}>
                      {/* SHIPPING ADDRESS ACCORDION */}
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="heading_tab ">
                          1. {ShippingEditData ? "Update" : "Enter New"}{" "}
                          Shipping Address
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="wrapper_addrespage_addres">
                            {!ShowShippingAddress && AllAddress?.length > 0 ? (
                              <div className="saved_addres">
                                <div className="inner_listingadd">
                                  <h3>Saved Address</h3>
                                  {AllAddress.map((item, index) => (
                                    <div className="addes_save d-flex align-items-center justify-content-between">
                                      <div className="d-flex align-items-center">
                                        <input
                                          type="radio"
                                          name="shippingAddress"
                                          checked={
                                            item?._id === savedAddressId
                                          }
                                          onChange={(e) => {
                                            setSavedAddressId(item?._id);
                                            HandleShippingSelection(item)
                                          }
                                          }
                                          className="radio_custom mr-2 mt-2"
                                        />
                                        <div className="d-flex justify-content-between">
                                          <div className="text_addsave">
                                            <h4 className="text-capitalize">
                                              {item?.fullName}
                                            </h4>
                                            <p className="m-0">
                                              {item?.address} , ZipCode:{" "}
                                              {item.pincode}
                                              <br />
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div>
                                        <i
                                          onClick={(e) =>
                                            HandleShippingAddressEditor(e, item)
                                          }
                                          className="fa fa-edit  text-info"
                                        ></i>
                                        <i
                                          onClick={(e) =>
                                            ShippingAddressDelete(item?.id)
                                          }
                                          className="fa fa-trash  text-danger ml-2"
                                        ></i>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : null}
                            <form onSubmit={(e) => HandleShippingAddressAdd(e)}>
                              <div className="fill_Address">
                                <div className="row">
                                  <div className="col-12">
                                    {!ShowShippingAddress ? (
                                      <div
                                        className="head_normar"
                                        onClick={(e) => {
                                          setAddShippingAddress({
                                            type: "common",
                                            address: "",
                                            country: "",
                                            state: "",
                                            city: "",
                                            pincode: "",
                                            lat: "",
                                            lng: "",
                                          });
                                          setShowShippingAddress(true);
                                        }}
                                      >
                                        <p className="">
                                          {" "}
                                          <i className="fa fa-plus mr-2"></i>Add
                                          a new address
                                        </p>
                                      </div>
                                    ) : null}
                                  </div>
                                  {ShowShippingAddress ? (
                                    <div className="name_inputs">
                                      <div className=" row">
                                        <div className="col-lg-12">
                                          <div className="form-group">
                                            <label>
                                              Flat No, House No, Building,
                                              Company, Apartment
                                            </label>
                                            <GooglePlaceAutoComplete
                                              placeholder=""
                                              id="NewAddress"
                                              value={AddShippingAddress.address}
                                              result={addressResultShipping}
                                            />
                                            {AddShippingAddress.submitted &&
                                              !AddShippingAddress.address ? (
                                              <span className="text-danger">
                                                Please Select the Address from
                                                suggestions
                                              </span>
                                            ) : null}
                                          </div>
                                          {/* <div className='py-3'>
                                        <button className='btn btn-primary' onClick={e => HandleUserLocation(e)}>Use Current Location</button>
                                      </div> */}
                                        </div>
                                        <div className="col-lg-6">
                                          <div className="form-group">
                                            <label>ZipCode</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              value={AddShippingAddress.pincode}
                                              name="pincode"
                                              onChange={
                                                ShippingAddressChangeHandler
                                              }
                                              required
                                            />
                                          </div>
                                        </div>

                                        <div className="col-lg-6">
                                          <div className="form-group">
                                            <label>Country/Region</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              value={AddShippingAddress.country}
                                              name="country"
                                              onChange={
                                                ShippingAddressChangeHandler
                                              }
                                              required
                                            />
                                          </div>
                                        </div>

                                        <div className="col-6">
                                          <div className="form-group">
                                            <label>Town/City</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              value={AddShippingAddress.city}
                                              name="city"
                                              onChange={
                                                ShippingAddressChangeHandler
                                              }
                                              required
                                            />
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          <div className="form-group">
                                            <label>State</label>
                                            <input
                                              className="form-control"
                                              value={AddShippingAddress.state}
                                              onChange={
                                                ShippingAddressChangeHandler
                                              }
                                              name="state"
                                              required
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>

                              {ShowShippingAddress ? (
                                <div className="d-flex justify-content-end mt-3">
                                  <div className="btns_data">
                                    <button
                                      type="button"
                                      className="btn btn-secondary btn-md mr-3"
                                      onClick={(e) => {
                                        setShowShippingAddress(false);
                                        setShippingEditData(null);
                                      }}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="submit"
                                      onClick={(e) => window.scrollTo(0, 100)}
                                      className="btn btn-primary btn-md"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              ) : null}
                            </form>
                          </div>
                          <div className="col-12">
                            <div className="form-group">
                              <div className="checkbox_text d-flex">
                                <input
                                  type="checkbox"
                                  className="mr-2"
                                  onChange={(e) =>
                                    setSendBiller(e.target.checked)
                                  }
                                  checked={SendBiller}
                                />
                                <p>Use above address same for billing</p>
                              </div>
                            </div>
                          </div>

                          <div className="d-flex justify-content-end">
                            <button
                              disabled={ShowShippingAddress}
                              onClick={(e) => ShippingStep(e)}
                              className="btn btn-primary mt-1"
                              type="button"
                            >
                              Next
                            </button>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      {/*  BILLING ADDRESS ACCORDION */}
                      <Accordion.Item eventKey="1">
                        <Accordion.Header className="heading_tab" id="billing">
                          2. Enter New Billing Address
                        </Accordion.Header>
                        <Accordion.Body>
                          <form onSubmit={(e) => BillingStepSubmit(e)}>
                            <div className="wrapper_addrespage_addres">
                              <div className="fill_Address">
                                <div className="row">
                                  <div className="name_inputs">
                                    <div className=" row">
                                      <div className="col-lg-12">
                                        <div className="form-group">
                                          <label>
                                            Flat No, House No, Building,
                                            Company, Apartment
                                          </label>
                                          <GooglePlaceAutoComplete
                                            placeholder=""
                                            value={
                                              BillingAddress.billing_address
                                            }
                                            id="BillingAddress"
                                            result={(e) =>
                                              addressResult(e, "billing")
                                            }
                                          />
                                          {BillingAddress.submitted &&
                                            !BillingAddress.addressSelected ? (
                                            <span className="text-danger">
                                              Please Select the Address from
                                              suggestions
                                            </span>
                                          ) : null}
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="form-group">
                                          <label>ZipCode</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={
                                              BillingAddress.billing_pincode
                                            }
                                            name="billing_pincode"
                                            onChange={BillingAddressChanger}
                                            required
                                          />
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="form-group">
                                          <label>Country/Region</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={
                                              BillingAddress.billing_country
                                            }
                                            name="billing_country"
                                            onChange={BillingAddressChanger}
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="form-group">
                                          <label>Town/City</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={BillingAddress.billing_city}
                                            name="billing_city"
                                            onChange={BillingAddressChanger}
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="form-group">
                                          <label>State</label>
                                          <input
                                            className="form-control"
                                            value={BillingAddress.billing_state}
                                            onChange={BillingAddressChanger}
                                            name="billing_state"
                                            required
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex justify-content-end mt-3">
                                <div className="btns_data">
                                  <button
                                    className="btn btn-secondary btn-md mr-3"
                                    onClick={(e) => {
                                      setstep("0");
                                      window.scrollTo(0, 0);
                                    }}
                                  >
                                    {" "}
                                    Back
                                  </button>
                                </div>
                                <div className="btns_data">
                                  <button className="btn btn-primary btn-md">
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2" id="finalstep">
                        <Accordion.Header className="heading_tab">
                          3. Enter Bank Detail
                        </Accordion.Header>
                        <Accordion.Body>
                          <>
                            <div className="d-flex align-items-center paymentRadio">
                              <input
                                type="radio"
                                name="choosepay"
                                checked={choosePayment == "paybycard"}
                                onChange={(e) =>
                                  HandlechoosePayment("paybycard")
                                }
                                className="radio_custom "
                              />

                              <label>Pay by Card</label>
                            </div>

                            <div className="d-flex align-items-center paymentRadio">
                              <input
                                type="radio"
                                name="choosepay"
                                checked={choosePayment == "paybybank"}
                                onChange={(e) =>
                                  HandlechoosePayment("paybybank")
                                }
                                className="radio_custom "
                              />

                              <div className="d-flex justify-content-between">
                                <label>Pay by Bank Account</label>
                              </div>
                            </div>

                            {choosePayment == "paybycard" ? (
                              <>
                                <div className="wrapper_addrespage_addres mx-0">
                                  {!showNewCard && allCards?.length > 0 ? (
                                    <div className="saved_addres">
                                      <div className="inner_listingadd pb-1 p-0 border-0">
                                        <h3 className=" pb-2">Saved Cards</h3>

                                        {allCards.map((item, index) => (
                                          <div className="addes_save d-flex align-items-center justify-content-between">
                                            <div className="d-flex mb-3 align-items-center">
                                              <input
                                                type="radio"
                                                name="newcard"
                                                checked={
                                                  item.acctid ==
                                                  selectedCard.acctid
                                                }
                                                onChange={(e) =>
                                                  HandlecardSelection(item)
                                                }
                                                className="radio_custom mr-2 savedCardR"
                                              />

                                              <div className="d-flex justify-content-between payDesign">
                                                <div className="text_addsave">
                                                  <h4 className="text-capitalize">
                                                    {item?.name}
                                                  </h4>
                                                  <h4 className="text-capitalize codes">
                                                    {item?.token
                                                      ? formatToken(
                                                        decryptData(
                                                          item.token
                                                        )
                                                      )
                                                      : "--"}
                                                  </h4>
                                                  <p className="m-0">
                                                    {decryptData(item?.expiry)}

                                                    <br />
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ) : null}
                                  <form onSubmit={(e) => { showNewCard && allCards?.length == 0 || !showNewCard && allCards?.length != 0 ? handleAuthorizeCard(e) : handleBankDetail(e); }}>
                                    <div>
                                      <div className="fill_Address">
                                        <div className="row">
                                          <div className="col-12">
                                            {!showNewCard ? (
                                              <div
                                                className="head_normar"
                                                onClick={(e) => {
                                                  setAddNewCard({
                                                    nameoncard: "",
                                                    expiry: "",
                                                    cvv: "",
                                                    accountNumber: "",
                                                    amount: "",
                                                    // city: "",
                                                  });
                                                  setAccountNumber("");
                                                  setExpiryDate("");
                                                  setCvv("");
                                                  setNameOnCard("");
                                                  setShowNewCard(true);
                                                }}
                                              >
                                                <p className="">
                                                  {" "}
                                                  <i className="fa fa-plus mr-2"></i>
                                                  Add a new card
                                                </p>
                                              </div>
                                            ) : null}
                                          </div>
                                          {showNewCard ? (
                                            <div className="pprofile1">
                                              <div className="form-row">
                                                <div className="col-md-6 mb-3">
                                                  <label>
                                                    Name on Card
                                                    <span className="star">
                                                      *
                                                    </span>
                                                  </label>
                                                  <input
                                                    placeholder="Name on Card"
                                                    type="text"
                                                    className="form-control"
                                                    id="nameonCard"
                                                    name="nameonCard"
                                                    value={nameonCard}
                                                    onChange={(e) => {
                                                      setNameOnCard(
                                                        e?.target?.value
                                                      );
                                                    }}
                                                    required
                                                  />
                                                </div>
                                                <div className="col-md-6 mb-3 checkoutCradnumber">
                                                  <label>
                                                    Card Number
                                                    <span className="star">
                                                      *
                                                    </span>
                                                  </label>
                                                  {/* <input
                                                  placeholder="Card number"
                                                  type="text"
                                                  className="form-control"
                                                  id="accountNumber"
                                                  name="accountNumber"
                                                  value={accountNumber}
                                                  onChange={
                                                    handleAccountNumberChange
                                                  }
                                                  required
                                                /> */}
                                                  <TokenIframe setToken={setToken} setError={setError} />
                                                  <p className="text-danger invalidP"> {error ? error : ""}</p>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                  <label>
                                                    Cvv
                                                    <span className="star">
                                                      *
                                                    </span>
                                                  </label>
                                                  <input
                                                    placeholder="cvv"
                                                    type="text"
                                                    className="form-control"
                                                    id="cvv"
                                                    name="cvv"
                                                    onChange={(e) =>
                                                      setCvv(e.target.value)
                                                    }
                                                    maxLength={4}
                                                    required
                                                    value={cvv}
                                                  />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                  <label>
                                                    Expiry Date
                                                    <span className="star">
                                                      *
                                                    </span>
                                                  </label>
                                                  <input
                                                    type="text"
                                                    value={expiryDate}
                                                    placeholder="MM/YYYY"
                                                    onChange={
                                                      handleExpiryDateChange
                                                    }
                                                    className="form-control"
                                                    required
                                                  />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                  <label>Amount ($)</label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    id="totalAmount"
                                                    name="totalAmount"
                                                    value={totalAmount}
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                              <div className="text-right">
                                                <button
                                                  type="button"
                                                  className="btn btn-secondary discard mr-2"
                                                  onClick={(e) => {
                                                    setShowNewCard(false);
                                                  }}
                                                >
                                                  Back
                                                </button>
                                                {allCards?.length == undefined ||
                                                  allCards?.length == 0 ? (
                                                  <></>
                                                ) : (
                                                  <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    disabled={
                                                      allCards?.length ==
                                                      undefined ||
                                                      allCards?.length == 0
                                                    }

                                                  >
                                                    Save
                                                  </button>
                                                )}
                                              </div>



                                            </div>
                                          ) : null}
                                        </div>
                                      </div>

                                    </div>
                                    <div className="d-flex justify-content-end">
                                      {!showNewCard && allCards?.length != 0 && <button
                                        // disabled={
                                        //   allCards?.length == 0 ||
                                        //     allCards?.length == undefined
                                        //     ? !accountNumber ||
                                        //     !cvv ||
                                        //     !expiryDate ||
                                        //     !nameonCard
                                        //     : showNewCard
                                        // }
                                        // onClick={(e) => handleAuthorizeCard(e)}
                                        className="btn btn-primary mt-1"
                                        type="submit"
                                      >
                                        Save
                                      </button>}
                                      {showNewCard && allCards?.length == 0 && <button
                                        // disabled={
                                        //   allCards?.length == 0 ||
                                        //     allCards?.length == undefined
                                        //     ? !accountNumber ||
                                        //     !cvv ||
                                        //     !expiryDate ||
                                        //     !nameonCard
                                        //     : showNewCard
                                        // }
                                        // onClick={(e) => handleAuthorizeCard(e)}
                                        className="btn btn-primary mt-1"
                                        type="submit"
                                      >
                                        Save
                                      </button>}
                                    </div>
                                  </form>
                                </div>
                              </>
                            ) : (
                              <div className="wrapper_addrespage_addres mx-0">
                                <div className="wrapper_addrespage_addres">
                                  {!showNewBankAcc &&
                                    bankAccounts?.length > 0 ? (
                                    <div className="saved_addres">
                                      <div className="inner_listingadd pb-1 p-0 border-0">
                                        <h3 className=" pb-2">
                                          Saved Bank Accounts
                                        </h3>
                                        {bankAccounts.map((item, index) => (
                                          <div className="addes_save d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                              <input
                                                type="radio"
                                                name="newcard"
                                                checked={
                                                  item.acctid ==
                                                  selectedBankAcc.acctid
                                                }
                                                onChange={(e) =>
                                                  HandleBankAccSelection(item)
                                                }
                                                className="radio_custom mr-2 savedCardR"
                                              />
                                              <div className="d-flex justify-content-between">
                                                <div className="row">
                                                  <div className="col-md-6 mb-2">
                                                    {" "}
                                                    <div className="text_addsave paycard payDesign">
                                                      <h4 className="text-capitalize ">
                                                        {item?.name}
                                                      </h4>
                                                      <h4 className="text-capitalize codes">
                                                        {item?.token
                                                          ? formatToken(
                                                            decryptData(
                                                              item.token
                                                            )
                                                          )
                                                          : "--"}
                                                      </h4>
                                                      <p className="m-0">
                                                        {decryptData(
                                                          item?.expiry
                                                        )}

                                                        <br />
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ) : null}
                                  <form
                                    onSubmit={(e) => handleAddBankAccount(e)}
                                  >
                                    <div className="fill_Address">
                                      <div className="row">
                                        <div className="col-12">
                                          {!showNewBankAcc ? (
                                            <div
                                              className="head_normar"
                                              onClick={(e) => {
                                                setShowNewBankAcc(true);
                                              }}
                                            >
                                              <p className="">
                                                {" "}
                                                <i className="fa fa-plus mr-2"></i>
                                                Add a new bank account
                                              </p>
                                            </div>
                                          ) : null}
                                        </div>
                                        {showNewBankAcc ? (
                                          <div className="pprofile1">
                                            <div className="form-row">
                                              <div className="col-md-6 mb-3">
                                                <label>
                                                  Banking Name
                                                  <span className="star">
                                                    *
                                                  </span>
                                                </label>
                                                <input
                                                  placeholder="Banking Name"
                                                  type="text"
                                                  className="form-control"
                                                  id="bankingname"
                                                  name="bankingname"
                                                  value={bankingname}
                                                  onChange={(e) => {
                                                    setbankingName(
                                                      e?.target?.value
                                                    );
                                                  }}
                                                  required
                                                />
                                              </div>
                                              <div className="col-md-6 mb-3">
                                                <label>
                                                  Account Number
                                                  <span className="star">
                                                    *
                                                  </span>
                                                </label>
                                                <input
                                                  placeholder="Account no."
                                                  type="text"
                                                  className="form-control"
                                                  id="bankaccNo"
                                                  name="bankaccNo"
                                                  value={bankAccNo}
                                                  onChange={
                                                    handleBankAccountNumberChange
                                                  }
                                                  required
                                                />
                                              </div>
                                              <div className="col-md-6 mb-3">
                                                <label>
                                                  Routing No.
                                                  <span className="star">
                                                    *
                                                  </span>
                                                </label>
                                                <input
                                                  placeholder="Routing no."
                                                  type="text"
                                                  className="form-control"
                                                  id="routingNo"
                                                  name="routingNo"
                                                  onChange={(e) =>
                                                    setroutingNo(e.target.value)
                                                  }
                                                  maxLength={10}
                                                  required
                                                  value={routingNo}
                                                />
                                              </div>
                                              <div className="col-md-6 mb-3">
                                                <label>Amount ($)</label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="totalAmount"
                                                  name="totalAmount"
                                                  value={totalAmount}
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                            <div className="text-right">
                                              <button
                                                type="button"
                                                className="btn btn-secondary discard mr-2"
                                                onClick={(e) => {
                                                  setShowNewBankAcc(false);
                                                }}
                                              >
                                                Back
                                              </button>
                                              {bankAccounts?.length ==
                                                undefined ||
                                                bankAccounts?.length == 0 ? (
                                                <></>
                                              ) : (
                                                <button
                                                  type="submit"
                                                  className="btn btn-primary"
                                                  disabled={
                                                    bankAccounts?.length ==
                                                    undefined ||
                                                    bankAccounts?.length == 0
                                                  }
                                                >
                                                  Save
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </form>
                                </div>
                                <div className="d-flex justify-content-end">
                                  <button
                                    disabled={
                                      bankAccounts?.length == 0 ||
                                        bankAccounts?.length == undefined
                                        ? !routingNo ||
                                        !bankingname ||
                                        !bankAccNo
                                        : showNewBankAcc
                                    }
                                    onClick={(e) => handleAuthorizeBankAcc(e)}
                                    className="btn btn-primary mt-1"
                                    type="submit"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            )}
                          </>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3" id="finalstep">
                        <Accordion.Header className="heading_tab">
                          4. Review Order
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="wrapper_revieworders">
                            {cartData.map((item) => (
                              <div className="card_product">
                                <div class="cart_cl">
                                  <div class="py-3">
                                    <div class="row">
                                      <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                                        <div className="checked_cl_bx">
                                          <img
                                            src={methodModel.userImg(
                                              item?.images
                                                ? item?.images[0]
                                                : ""
                                            )}
                                            class="laptop_cl "
                                            alt="laptop"
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-sm-6 col-md-9 col-lg-9">
                                        <h6 class="detail_cl text-capitalize">
                                          {item?.name}
                                        </h6>
                                        <p class="Stock_cl ">
                                          {" "}
                                          In Stock{" "}
                                          <span class="seller_cl">
                                            Mode:
                                          </span>{" "}
                                          <span className="text-capitalize">
                                            {item?.mode}
                                          </span>
                                        </p>
                                        {/* {item?.attributes?.map((itm, index) => (
                                      <p class="size_cl"><span class="gb_cl">{itm?.attribute_name}:</span> {itm?.unit}</p>
                                    ))} */}
                                        <p class="size_cl">
                                          <span class="gb_cl">Qty:</span>{" "}
                                          {item?.qty}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}

                            <div className="row my-3">
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="address_headview">
                                  <h5>Shipping Information</h5>

                                  <div className="add_lists">
                                    <h4 className="text-capitalize">
                                      {SelectedShippingAddress?.fullName ||
                                        SelectedShippingAddress?.firstName}
                                    </h4>
                                    <p>{SelectedShippingAddress?.address}</p>
                                    {/* <p>ZipCode.{SelectedShippingAddress.pincode}</p>
                                  <div className='call_icon'>
                                    <span>Phone No: <span className='ml-1'><i className='fa fa-phone mr-1'></i>{SelectedShippingAddress.mobileNo}</span></span>
                                  </div> */}
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="address_headview">
                                  <h5>Billing Information</h5>

                                  <div className="add_lists">
                                    <h4 className="text-capitalize">
                                      {BillingAddress.billing_fullName}
                                    </h4>
                                    <p>{BillingAddress.billing_address}</p>
                                    {/* <p>ZipCode. {BillingAddress.billing_pincode}</p>
                                  <div className='call_icon'>
                                    <span>Phone No: <span className='ml-1'><i className='fa fa-phone mr-1'></i>{BillingAddress.billing_mobileNo}</span></span>
                                  </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex justify-content-end mt-3">
                              <div className="btns_data mr-3">
                                <button
                                  className="btn btn-secondary"
                                  onClick={(e) => {
                                    setstep("0");
                                    window.scrollTo(0, 0);
                                  }}
                                >
                                  <i className="fa fa-edit"></i> Edit Address
                                </button>
                              </div>
                              <div className="btns_data">
                                <button
                                  className="btn btn-primary"
                                  onClick={(e) => PaymentHandler(e)}
                                >
                                  Place Order
                                </button>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-3 col-lg-4 col-xl-4">
                <div className="sideleft_checkout">
                  <div className="select_product">
                    <div className="box-shadow">
                      <div>
                        <h3 className="cart_sel">
                          Order Summary <span className="cart_sel-desc"></span>{" "}
                        </h3>
                      </div>
                      <p className="cart_numbs mb-2">
                        {totalAmount}
                        <span className="ml-2">$</span>
                      </p>

                      <div className="d-flex justify-content-between align-items-center">
                        <p className="cart_pros">Products</p>
                        <p className="cart_pros">{cartData.length}</p>
                      </div>
                      {/* <div className="row px-2 mb-2">
                        {FreeDelivery ? (
                          <>
                            <div className="col-6">
                              <p className="cart_pros">Cargo</p>
                            </div>
                            <div className="col-6 text-right">
                              <p className="cart_pros">Free </p>
                            </div>
                          </>
                        ) : null}
                      </div> */}
                      {/* <hr className='cart_pro_hr' /> */}

                      <div className="cart_pro_footer">
                        <h5 className="mb-1">Products</h5>

                        {cartData.map((item, index) => (
                          <div className="profit_list mb-1">
                            <h5 className="text-capitalize  product_hedng newDesc">
                              {item.name}
                            </h5>

                            <div className="product_aweosme size_cl fexling_class text-capitalize m-0">
                              <p className="gb_cl cart_pros rightSide">
                                {"Product Price"}:
                              </p>
                              <p className="cart_pros leftSide">
                                {item?.qty} *{" "}
                                {parseFloat(item?.price).toFixed(2)} $
                              </p>
                            </div>

                            <div className="product_aweosme size_cl fexling_class text-capitalize m-0">
                              <p className="gb_cl cart_pros rightSide">
                                {"Tax"}:
                              </p>
                              <p className="cart_pros leftSide">
                                {item?.tax || 0} $
                              </p>
                            </div>

                            {/* <div className='product_aweosme size_cl fexling_class text-capitalize m-0'>
                            <p className='gb_cl cart_pros' >{"GST"}:</p>
                            <p className='cart_pros' >{item?.gst || 0} $</p>
                          </div> */}
                            <div className="product_aweosme size_cl fexling_class text-capitalize m-0">
                              <p className="gb_cl cart_pros rightSide">
                                {"Discount"}:
                              </p>
                              <p className="cart_pros leftSide">
                                {item?.discount || 0} $
                              </p>
                            </div>
                            {/* <div className='product_aweosme size_cl fexling_class text-capitalize m-0'>
                            <p className='gb_cl cart_pros' >{"Delivery fee"}:</p>
                            <p className='cart_pros' >{item?.delivery_fee || 0} $</p>
                          </div> */}
                            <div className="product_aweosme">
                              <p className="checkout_text_left mb-0">
                                Total Amount{" "}
                              </p>
                              <p className="checkout_text_right mb-0">
                                {parseFloat(parseFloat(item.price)).toFixed(2) *
                                  item?.qty}
                                $
                              </p>

                              {/* <p class="size_cl fexling_class text-capitalize m-0"><span class="gb_cl">{"Product Price"}:</span>{item?.qty} * {parseFloat(item?.price).toFixed(2)} $</p>
                            <p class="size_cl fexling_class text-capitalize m-0"><span class="gb_cl">{"Tax"}:</span> {item?.tax || 0} $</p>
                            <p class="size_cl fexling_class text-capitalize m-0"><span class="gb_cl">{"GST"}:</span> {item?.gst || 0} $</p>
                            <p class="size_cl fexling_class text-capitalize m-0"><span class="gb_cl">{"Discount"}:</span> {item?.discount || 0} $</p>
                            <p class="size_cl fexling_class text-capitalize m-0"><span class="gb_cl">{"Delivery fee"}:</span> {item?.delivery_fee || 0} $</p> */}
                            </div>
                          </div>
                        ))}

                        <div className=" bordertop1">
                          <div className="d-flex justify-content-between">
                            <p className="cart_pros_footer total_amt">
                              Net Amount to Pay{" "}
                            </p>
                            {/* <p className=' total_amt'>{localStorage.getItem("coupon_apply") ? parseFloat((Amount - TotalDiscount) + TotalDeliveryCharges).toFixed(2) : parseFloat((Amount) + TotalDeliveryCharges).toFixed(2)} $</p> */}
                            <p className=" total_amt">{totalAmount}$</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CheckoutSection;
