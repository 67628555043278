import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../components/global/layout";
import "./style.scss";
import SelectDropdown from "../../components/common/selectdropdown";
import { useNavigate } from "react-router-dom";
import loader from "../../components/loader/loader";
import ApiClient from "../../apimethods/apiClient";

const Dashboard = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [data, setData] = useState()
  useEffect(() => {
    if (user.loggedIn) {
      getTransactionsData()
    }
  }, []);

  const getTransactionsData = () => {
    loader(true);
    ApiClient.get(`analytics?mode=sandbox&type=cart`).then(
      (res) => {
        if (res?.success == true) {
          loader(false);
          setData(res?.data)
        }


      }
    );
  }

  const [difference, setDifference] = useState('');


  const calculateDifference = (time) => {
    const now = new Date();
    const targetTime = new Date(time);
    const diffInMilliseconds = now - targetTime;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    console.log(diffInMilliseconds, "time")

    return `${diffInDays} days, ${diffInHours % 24} hours ago`
  };



  return (
    <>
      <Layout>
        <div className="row d-flex align-items-center">
          <div className="col-md-9 head_cls">
            <h2 className="mb-1 mainHeading_cls">
              {" "}
              <span className="dsh_hedding">Hi</span>
              {user && user.fullName}
            </h2>
            <p className="paraCls">
              Here’s what’s going on with your team Lux Financial
            </p>
          </div>
          <div className="col-md-3 text-right">
            <div className="set_dropdown">
              <SelectDropdown
                id="statusDropdown"
                displayValue="name"
                placeholder="2 Jan ’21 - 8 Jan ’21"
                result={(e) => { }}
                options={[
                  { id: "1", name: "Option 1" },
                  { id: "2", name: "Option 2" },
                ]}
              />
            </div>
          </div>
          <div className="cards_sales">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
                <div className="sales_section">
                  <div
                    className="main_sales"
                    onClick={(e) => navigate(`/chart/Gross Sales`)}
                  >
                    <div className="sales_headings">
                      <div>
                        <p className="sub_heading">Gross Sales</p>
                      </div>
                      <div>
                        <img
                          src="/assets/img/img/bag.png"
                          className="wallets_img"
                        />
                      </div>
                    </div>
                    <div className="sales_icons">
                      <h3 className="Amount_sales">$ {parseFloat(data?.gross_sum).toFixed(2) || "--"}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3"
                onClick={(e) => navigate(`/chart/Net Sales`)}
              >
                <div className="sales_section">
                  <div className="main_sales">
                    <div className="sales_headings">
                      <div>
                        <p className="sub_heading">Net Sales</p>
                      </div>
                      <div>
                        <img
                          src="/assets/img/img/wallet.png"
                          className="wallets_img"
                        />
                      </div>
                    </div>
                    <div className="sales_icons">
                      <h3 className="Amount_sales">$ {parseFloat(data?.net_sum).toFixed(2) || "--"}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3"
                onClick={(e) => navigate(`/chart/Average Sales`)}
              >
                {/* <div className="sales_section">
                  <div className="main_sales">
                    <div className="sales_headings">
                      <div>
                        <p className="sub_heading">Average Sales</p>
                      </div>
                      <div>
                        <img
                          src="/assets/img/img/graphrange.png"
                          className="wallets_img"
                        />
                      </div>
                    </div>
                    <div className="sales_icons">
                      <h3 className="Amount_sales">$ 2,768.00</h3>
                    </div>
                  </div>
                </div> */}
              </div>
              <div
                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3 pointer"
                onClick={(e) => navigate(`/transaction`)}
              >
                <div className="sales_section">
                  <div className="main_sales">
                    <div className="sales_headings">
                      <div>
                        <p className="sub_heading">Transaction Volume:</p>
                      </div>
                      <div>
                        <img
                          src="/assets/img/img/bag.png"
                          className="wallets_img"
                        />
                      </div>
                    </div>
                    <div className="sales_icons">
                      <h3 className="Amount_sales">{data?.total_transaction_volume || "--"}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3 pointer"
                onClick={(e) => navigate(`/transaction`)}
              >
                <div className="sales_section">
                  <div className="main_sales">
                    <div className="sales_headings">
                      <div>
                        <p className="sub_heading">Transaction Value:</p>
                      </div>
                      <div>
                        <img
                          src="/assets/img/img/bag.png"
                          className="wallets_img"
                        />
                      </div>
                    </div>
                    <div className="sales_icons">
                      <h3 className="Amount_sales">$ {data?.totalTransactionAmount || "00"}</h3>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3 pointer"
                onClick={(e) => navigate(`/transaction`)}
              >
                {/* <div className="sales_section">
                  <div className="main_sales">
                    <div className="sales_headings">
                      <div>
                        <p className="sub_heading">Processing fees</p>
                      </div>
                      <div>
                        <img
                          src="/assets/img/img/bag.png"
                          className="wallets_img"
                        />
                      </div>
                    </div>
                    <div className="sales_icons">
                      <h3 className="Amount_sales">$ 22,880.50</h3>
                    </div>
                  </div>
                </div> */}
              </div>

              <div
                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3 pointer"
                onClick={(e) => navigate(`/transaction?transfee=12`)}
              >
                {/* <div className="sales_section">
                  <div className="main_sales">
                    <div className="sales_headings">
                      <div>
                        <p className="sub_heading">Transaction fees</p>
                      </div>
                      <div>
                        <img
                          src="/assets/img/img/bag.png"
                          className="wallets_img"
                        />
                      </div>
                    </div>
                    <div className="sales_icons">
                      <h3 className="Amount_sales">$ 22,880.50</h3>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            {/* graph Design */}

            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls">
                <div className="graph_section">
                  <div className="graph_range">
                    <div>
                      <h3 className="Amount_sales">Authorization Rate</h3>
                      <p>No. of Transactions Approved</p>
                    </div>

                    <div>
                      <p
                        className="view_graph"
                        onClick={(e) =>
                          navigate(`/chart/Authorization Rate`)
                        }
                      >
                        View full report
                        <span className="arrowss">
                          <i class="material-icons">keyboard_arrow_right</i>
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="revenue_graph">
                    <img src="/assets/img/img/Chart.png" className="graphs" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls">
                <div className="graph_section">
                  <div className="graph_range">
                    <div>
                      <h3 className="Amount_sales">Percentage of Sales</h3>
                    </div>

                    <div>
                      <p
                        className="view_graph"
                        onClick={(e) => navigate(`/chart/Sales`)}
                      >
                        View full report
                        <span className="arrowss">
                          <i class="material-icons">keyboard_arrow_right</i>
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="revenue_graph">
                    <img
                      src="/assets/img/img/Chart (1).png"
                      className="graphs"
                    />
                  </div>

                  <div className="costs">
                    <p className="costes_para">Revenue</p>
                    <p className="costes_paras">Costs</p>
                  </div>
                </div>
              </div>

              {/* 2nd cards */}
              <div
                className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-3"
                onClick={(e) => navigate(`/chart/Profit`)}
              >
                <div className="graph_section">
                  <div className="graph_range">
                    <div>
                      <h3 className="Amount_sales">Total Profit</h3>
                    </div>

                    <div>
                      <p className="view_graph">
                        View full report
                        <span className="arrowss">
                          <i class="material-icons">keyboard_arrow_right</i>
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="revenue_graph">
                    <img src="/assets/img/img/Chart.png" className="graphs" />
                  </div>
                </div>
              </div>
              {/* 3rd card */}
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-3">
                <div className="graph_section">
                  <div className="graph_range">
                    <div>
                      <h3 className="Amount_sales">Refunds</h3>
                    </div>

                    <div>
                      <p
                        className="view_graph"
                        onClick={(e) => navigate(`/chart/Refunds`)}
                      >
                        View full report
                        <span className="arrowss">
                          <i class="material-icons">keyboard_arrow_right</i>
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="revenue_graph">
                    {/* table */}

                    <div className="table-responsive table_section  add mt-0 p-0">
                      <table class="table">
                        <tbody>
                          {data?.transaction_refunds?.map((itm, index) => <tr className="dashboard">
                            <td>
                              <div className="shipment_section">
                                <div className="orders">
                                  <div className="ship_items">
                                    <img
                                      src="/assets/img/img/Item.png"
                                      className="order_ship"
                                    />
                                  </div>
                                  <div>
                                    <h3 className="item_no">{itm?.authcode}</h3>
                                    <p className="item_name">
                                      {itm?.transaction_id}
                                    </p>
                                  </div>
                                </div>

                                <div className="item_rate">
                                  <div>
                                    <h5 className="item_price">${" "}{itm?.amount}</h5>
                                    <p className="shipment_time">
                                      {calculateDifference(itm?.createdAt)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>)}
                          {/* <tr className="dashboard">
                            <td>
                              <div className="shipment_section">
                                <div className="orders">
                                  <div className="ship_items">
                                    <img
                                      src="/assets/img/img/Item.png"
                                      className="order_ship"
                                    />
                                  </div>
                                  <div>
                                    <h3 className="item_no">#SO0005</h3>
                                    <p className="item_name">
                                      Rhombus Commerce
                                    </p>
                                  </div>
                                </div>

                                <div className="item_rate">
                                  <div>
                                    <h5 className="item_price">$15.00</h5>
                                    <p className="shipment_time">
                                      Overdue 10 hours
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr className="dashboard">
                            <td>
                              <div className="shipment_section">
                                <div className="orders">
                                  <div className="ship_items">
                                    <img
                                      src="/assets/img/img/Item.png"
                                      className="order_ship"
                                    />
                                  </div>
                                  <div>
                                    <h3 className="item_no">#SO0005</h3>
                                    <p className="item_name">
                                      Rhombus Commerce
                                    </p>
                                  </div>
                                </div>

                                <div className="item_rate">
                                  <div>
                                    <h5 className="item_price">$15.00</h5>
                                    <p className="shipment_time">
                                      Overdue 10 hours
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr className="dashboard">
                            <td>
                              <div className="shipment_section">
                                <div className="orders">
                                  <div className="ship_items">
                                    <img
                                      src="/assets/img/img/Item.png"
                                      className="order_ship"
                                    />
                                  </div>
                                  <div>
                                    <h3 className="item_no">#SO0005</h3>
                                    <p className="item_name">
                                      Rhombus Commerce
                                    </p>
                                  </div>
                                </div>

                                <div className="item_rate">
                                  <div>
                                    dashboard
                                    <h5 className="item_price">$15.00</h5>
                                    <p className="shipment_time">
                                      Overdue 10 hours
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* 4th card */}
              {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-3">
              <div className="graph_section">
                <div className="graph_range">
                  <div>
                    <h3 className="Amount_sales">Revenue vs Units Sold</h3>
                  </div>
                </div>

                <div className="revenue_graph">
                  <img src="/assets/img/img/Chart (2).png" className="graphs" />
                </div>

                <div className="costst">
                  <p className="costes_para">Units Sold</p>
                  <p className="costes_paras">Total Transaction</p>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </Layout>
      <div class="onload-modal">
        {/* Your component content */}

        {/* {isModalOpen && (
        <div className="modal1">

          <div  className="bank-modal">
            <div className="position-relative">
              <span className="close-icon" onClick={() => setIsModalOpen(false)}>
                &times;
              </span>
            </div>
            {(user?.role_name != 'Customer' || 'Guest' && user?.active_accounts <= 0) && <div className="bg-white-modal">
              <img src="assets/img/logo.png" />
              <button onClick={() => {
                // open()
                handleClick()
                setIsModalOpen(false)
              }} className="btn dark-btn w-100 bank-modal-btn">Connect a Bank Account</button>
              </div>}
            
          </div>
        </div>


      )} */}
      </div>
    </>
  );
};

export default Dashboard;
