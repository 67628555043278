import React from "react"; 
import ImageUpload from "../../../components/common/imageUpload"; 
import Layout from "../../../components/global/layout";
import rolesModel from "../../../models/roles.model"; 
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import environment from "../../../environment"; 

const Html = ({
  id,
  role,
  form,
  handleSubmit,
  setform,
  roles,
  addressResult,
  submitted,
  images,
  imageResult,
  getError,
  setEyes,
  eyes,
  back,
  emailCheck,
  emailErr,
  emailLoader,
  detail,
  ChangeStatus,
  statusChange,
}) => {
  const user = useSelector((state) => state.user);
  const isAllow = (key = "") => {
    let permissions = user.role?.permissions;
    let value = permissions?.[key];
    if (user.role.id == environment.adminRoleId) value = true;
    return value;
  };

  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
         
          <div className="pprofile1">
          <div className="text-right title-head">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="hedding ">
              {" "}
              <a onClick={(e) => back()} className="cursor-pointer">
                <i
                  className="fa fa-arrow-left me-2 "
                  title="Back"
                  aria-hidden="true"
                ></i>
              </a>{" "}
              {id ? "Edit" : "Add"} {role ? rolesModel.name(role) : "Customer"}
            </h3>
          </div>
          </div>
           <div className="product-detail-page">
           <div className="form-row">
              <div className="col-md-8  mb-3 mx-auto">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="imagethumbWrapper profile-main-img mb-2 profile-user">
                    <img src="/assets/img/img/person.jpg" class="" />
                  </div>

                  <ImageUpload
                    model="customer"
                    
                    result={(e) => imageResult(e, "image")}
                    value={images?.image || form.image}
                    multiple={false}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label>
                  First Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={form.first_name}
                  onChange={(e) =>
                    setform({ ...form, first_name: e.target.value })
                  }
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label>
                  Last Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={form.last_name}
                  onChange={(e) =>
                    setform({ ...form, last_name: e.target.value })
                  }
                  required
                />
              </div>
              {/* {role ? <></> : <div className="col-md-6 mb-3">
                            <label>Role<span className="text-danger">*</span></label>
                            <SelectDropdown
                                    id="statusDropdown"
                                    displayValue="name"
                                    placeholder="Select Role"
                                    intialValue={form.mode}
                                    disabled={form.id ? true : false}
                                    result={e => { setform({ ...form, mode: e.value }) }}
                                    options={[{name:'Live',id:'live'},{name:'Sandbox',id:'sandbox'}]}
                                    required
                                />
                                 {submitted && !form.mode ? <div className="invalid-feedback d-block">Mode is Required</div> : <></>}
                        </div>} */}

              {/* <div className="col-md-6 mb-3">
                            <label>Address</label>
                            <GooglePlaceAutoComplete
                                value={form.address}
                                result={addressResult}
                                id="address"
                                placeholder=""
                            />
                        </div> */}

              <div className="col-md-6 mb-3 ">
                <label className=" d-block">
                  Mobile No<span className="star">*</span>
                </label>

                <div className="w-100">
                  <div className="reactphonecust">
                    <PhoneInput
                      value={form.dial_code + "" + form.mobile_no}
                      countryCodeEditable={false}
                      enableSearch={true}
                      placeholder=""
                      country="us"
                      onChange={(phone, country) => {
                        let phonenumber = phone.replace(country.dialCode, "");
                        // if(phonenumber!=form.mobileNo){
                        // setform({ ...form, mobileNo: phonenumber })
                        // }
                        setform({
                          ...form,
                          dial_code: country.dialCode,
                          mobile_no: phonenumber,
                        });
                      }}
                      required
                    />
                  </div>

                  {/* <input
                                    type="text"
                                    className="form-control" placeholder='+1'
                                    value={form && form.dialCode}
                                    maxLength={4}
                                    onChange={e => setform({ ...form, dialCode: e.target.value })}
                                /> */}
                  {/* <input
                                    type="text"
                                    className="form-control mobilalnumber col-md-8 " placeholder='Mobile No.'
                                    value={form && form.mobileNo}
                                    maxLength={12}
                                    style={{borderLeft:0}}
                                    onChange={e => setform({ ...form, mobileNo: methodModel.isNumber(e) })}
                                    required={form.dialCode?true:false}
                                /> */}
                </div>
                {submitted && !form.dial_code ? (
                  <div className="invalid-feedback d-block">
                    DialCode is Required
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label>
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  value={form.email}
                  onChange={(e) => {
                    setform({ ...form, email: e.target.value });
                    emailCheck(e.target.value);
                  }}
                  required
                />
                {emailErr ? (
                  <div className="invalid-feedback d-block">{emailErr}</div>
                ) : (
                  <></>
                )}
              </div>

              {/* <div className="col-md-6 mb-3">
                            <label>Buisness Name   </label>
                            <input
                                type="text"
                                className="form-control"
                                value={form.companyName}
                                onChange={e => { setform({ ...form,companyName: e.target.value });}}
                            />
                           
                        </div> */}
            </div>

            <div className="text-right">
              {/* <button type="button" className="btn btn-secondary discard mr-2" onClick={e=>back()}>Back</button> */}
              {!form.id || (form.id && isAllow("editAdmins")) ? (
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              ) : null}
            </div>
           </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default Html;
