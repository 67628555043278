import { useEffect, useState } from "react";
import Html from "./html";
import ApiClient from "../../../apimethods/apiClient";
import { useNavigate, useParams } from "react-router-dom";
import loader from "../../../components/loader/loader";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

const particularProductDetail = () => {
  const { id, slug } = useParams();
  const [StockOut, setStockOut] = useState(0);
  const [CartApplied, setCartApplied] = useState(false);
  const [DiscountPrice, setDiscountPrice] = useState(0);
  const dispatch = useDispatch();
  const history = useNavigate();
  const user = useSelector((state) => state.user);
  const searchState = useSelector((state) => state.search);
  const [productdata, setproductdata] = useState([]);
  const [rental_period, setrental_period] = useState("");
  const [submit, setsubmit] = useState(false);
  const [attributeofproduct, setattributeofproduct] = useState([]);
  const [
    indexfordynamicImagesAND_Pricing,
    setindexfordynamicImagesAND_Pricing,
  ] = useState("");
  const [isfavv, setisfavv] = useState(false);
  const [price, setprice] = useState("");
  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");
  const [ProductProce, setProductPrice] = useState(
    indexfordynamicImagesAND_Pricing?.price
  );
  const [disabledDateRanges, setdisabledDateRanges] = useState([]);
  const [rating, setrating] = useState(0);
  const [review, setreview] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [merchantID, setmerchantId] = useState('')


  useEffect(() => {
    if (user?.role_name == "Merchant") {
      history("/");
    }
  }, []);

  useEffect(() => {
    if (slug || localStorage?.getItem('merSlug')) {
      loader(true)
      ApiClient.get(`user/slug?slug=${slug || localStorage?.getItem('merSlug')}`).then(res => {
        if (res?.success) {
          loader(false)
          setmerchantId(res?.data?.id)
        } else {
          toast.error(res?.message)
        }

      })
    }
  }, [slug])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    if (id) {
      getproductdata(id);
      //   getrentalDAtes(id)
      if (user?.loggedIn) {
        // isfav()
      } else {
      }
    }
  }, []);

  const getproductdata = (ids) => {
    loader(true);
    const filters = user?._id
      ? {
        id: `${id || ids}`,
        user_id: `${user?._id}`,
      }
      : {
        id: `${id || ids}`,
      };
    ApiClient.get(`product/view`, filters).then((res) => {
      if (res?.success) {
        toast.success(res?.data);
        setproductdata(res?.data);
      }
      loader(false);
    });
  };

  // add to cart api
  const addTocart = (itm_id) => {
    setsubmit(false);
    if (user?.loggedIn) {
      let payload = {
        qty: quantity,
        product_id: itm_id,
        merchant_id: merchantID || null,
      };
      ApiClient.post(`cart/add-item`, payload).then((res) => {
        if (res?.success) {
          history("/cart");
        }
      });
    } else {
      document.getElementById("loginModal1").click();
    }
  };

  const addTofav = (prdt_id) => {
    if (prdt_id) {
      let payload = {
        type: "product",
        product_id: prdt_id,
      };
      ApiClient.post(`wishlist`, payload).then((res) => {
        if (res?.success) {
          getproductdata(prdt_id);
          // toast.success(res?.message)
        }
      });
    }
  };

  return (
    <>
      <Html
        productdata={productdata}
        isfavv={isfavv}
        addTocart={addTocart}
        quantity={quantity}
        setrental_period={setrental_period}
        rental_period={rental_period}
        submit={submit}
        setend_date={setend_date}
        setstart_date={setstart_date}
        start_date={start_date}
        end_date={end_date}
        DiscountPrice={DiscountPrice}
        attributeofproduct={attributeofproduct}
        CartApplied={CartApplied}
        setindexfordynamicImagesAND_Pricing={
          setindexfordynamicImagesAND_Pricing
        }
        indexfordynamicImagesAND_Pricing={indexfordynamicImagesAND_Pricing}
        price={price}
        disabledDateRanges={disabledDateRanges}
        stockOut={StockOut}
        rating={rating}
        review={review}
        setreview={setreview}
        setQuantity={setQuantity}
        slug={slug}
        addTofav={addTofav}
      />
    </>
  );
};

export default particularProductDetail;
