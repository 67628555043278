import { forwardRef, useEffect, useState } from "react";
import "./style.scss";
import methodModel from "../../components/validations/Validations";
import "react-phone-input-2/lib/style.css";
import Header from "../../components/global/layout/Header";
import Footer from "../../components/global/footer/Footer";

const Html = forwardRef(
  ({ Products, removeAll, checkout, subtotal, changeQuantity }, ref) => {
    const [productId, setproductId] = useState([]);
    const [cartproduct, setcartproduct] = useState([]);
    const [total, settotal] = useState("");

    useEffect(() => {
      if (cartproduct) {
        let array = cartproduct;
        let total = array?.map((itm) => {
          return itm?.qty * itm?.price;
        });
        total = total.reduce((acc, curr) => acc + curr, 0);
        settotal(total);
      }
    }, [cartproduct]); 

    useEffect(() => {
      let arr = productId;
      let arr2 = [];
      if (arr.length >= 1) {
        arr.map((item, index) => {
          return Products.map((itm) => {
            if (itm?._id == item) {
              arr2.push(itm);
            }
          });
        });
      }
      setcartproduct([...arr2]);
    }, [Products]);

    const selectall = () => {
      if (Products?.length > 0) {
        let arr = Products.map((item) => {
          return item?._id;
        });
        setproductId([...arr]);
        let product = Products;
        setcartproduct([...product]);
        // localStorage.setItem("cart_products",JSON.stringify(product))
      }
    };


    useEffect(()=>{
      selectall()
    },[Products])

    // useImperativeHandle(
    //   ref,
    //   () => ({
    //     caller: selectall,
    //   }),
    //   [selectall]
    // );

   

    return (
      <>
        <Header />
        <div className="top-padding">
          <div className="checkout_wrapper ">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-9 col-lg-8 col-xl-8">
                  <div className="checkout_page">
                    <div className="heading_check ">
                      <div className="d-flex justify-content-between align-items-center">
                        {Products?.length > 0 ? (
                          <h3>My Cart</h3>
                        ) : (
                          <h3>Your Cart is Empty</h3>
                        )}
                        {/* {Products?.length > 0 && <p className='mb-0 select_bx'><span className='pointer' onClick={e => selectall()}>Select All</span><span className='mx-2'>|</span><span className='pointer' onClick={e => DeselectAll()}>Deselect All</span></p>} */}
                      </div>
                    </div>

                    {Products.length > 0 ? (
                      Products.map((item, index) => {
                        return (
                          <div className="card_listing">
                            <div class="row align-items-center">
                              <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <div class="d-flex align-items-center overflow-hidden  space_gap ">
                                  {/* <div class="checked_cl">
                            <input class=" " type="checkbox" value="" id="cartid" checked={productId.some((ide) => item?._id == ide)} onClick={e => addproductId(item, e.target.checked)} />
                          </div> */}
                                  <img
                                    src={
                                      item?.images?.length > 0
                                        ? methodModel.noImg(item?.images[0])
                                        : ""
                                    }
                                    class="laptop_cart_new img-fluid"
                                    alt="laptop"
                                  />
                                </div>
                              </div>
                              <div class="col-12 col-sm-6 col-md-8 col-lg-8">
                                <h6 class="detail_cl text-capitalize">
                                  {item?.name}
                                </h6>
                                <p class="Stock_cl text-capitalize ">
                                  {" "}
                                  <span class="seller_cl">${item?.price}</span>
                                </p>
                                <div className="qty_btn d-flex align-items-center">
                                  <div className="rentl_queniy mb-0 padding_items">
                                    <div className="drop_qulity mr-3">
                                      <span>Qty</span>
                                      <span>
                                        <select
                                          className="qty_select"
                                          value={item?.qty}
                                          onChange={(e) =>
                                            changeQuantity(e.target.value, item)
                                          }
                                        >
                                          {[1, 2, 3, 4, 5].map((value) => (
                                            <option key={value} value={value}>
                                              {value}
                                            </option>
                                          ))}
                                          {/* {methodModel.QuantityGetter(item.qty)?.map((item, index) => (<option value={`${index + 1}`}>{index + 1}</option>))} */}
                                        </select>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="btn_all">
                                    <span
                                      onClick={(e) => removeAll(item?._id)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Delete
                                    </span>
                                    {/* <span onClick={(e) => gotoProductpage(item?.product_sub_category_id)}>See more like this</span> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <div className="row justify-content-center">
                          <div className=" col-sm-12 col-md-12 col-lg-12 px-lg-12  mb-md-0 mb-lg-12 jus">
                            <div className=" pointer " title="">
                              <div className=" p-3">
                                <div className="text-center ">
                                  <img
                                    src={
                                      "https://static.vecteezy.com/system/resources/thumbnails/005/006/007/small/no-item-in-the-shopping-cart-click-to-go-shopping-now-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-vector.jpg"
                                    }
                                    className="flash_sales_img img-fluid slider_img_height"
                                    alt="img"
                                  />
                                </div>
                                <div className="text-center mt-3">
                                  <p className="real_price text-capitalize">
                                    Your Cart is Empty{" "}
                                  </p>
                                  {/* <p className="flase_sales_heading text-capitalize mb-2">Start <Link to="/product">P</Link> And Add Products to Your cart"</p> */}
                                </div>

                                <div className="row">
                                  <div className="col-6 mx-auto"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-4 col-xl-4">
                  <div className="sideleft_checkout">
                    <div className="select_product">
                      <div className="box-shadow ">
                        <div>
                          <h3 className="cart_sel">
                            Order Summary{" "}
                            <span className="cart_sel-desc"></span>{" "}
                          </h3>
                        </div>
                        <p className="cart_numbs mb-2">
                          {subtotal}
                          <span className="ml-2">$</span>
                        </p>

                        {cartproduct.length == 0 ? null : (
                          <div className="row px-2 mb-2">
                            <div className="col-6">
                              <p className="cart_pros">Products</p>
                            </div>
                            <div className="col-6 text-right">
                              <p className="cart_pros">{cartproduct.length}</p>
                            </div>
                          </div>
                        )}
                        {/* <hr className='cart_pro_hr' /> */}

                        {cartproduct.length > 0 && (
                          <div className="cart_pro_footer">
                            {cartproduct?.length > 0 &&
                              cartproduct.map((itm) => {
                                return (
                                  <div className="profit_list border-bottom">
                                    {" "}
                                    <p className="product_hedng text-capitalize ">
                                      {itm?.name}{" "}
                                    </p>
                                    <div className="">
                                      {/* <p class="size_cl fexling_class text-capitalize m-0"><span class="gb_cl">{"Quantity"}:</span> {itm?.qty}</p> */}
                                      <div class="product_aweosme size_cl fexling_class text-capitalize m-0">
                                        <p class="gb_cl">{"Product Price"}:</p>
                                        <p>
                                          {itm?.qty} *{" "}
                                          {parseFloat(itm?.price).toFixed(2)} $
                                        </p>
                                      </div>

                                      {/* <div class=" product_aweosme size_cl fexling_class text-capitalize m-0"><p class="gb_cl">{"Tax"}:</p> <p> {itm?.tax || 0} $</p> </div>
                            <div class="product_aweosme size_cl fexling_class text-capitalize m-0"><p class="gb_cl">{"GST"}:</p> <p>{itm?.gst || 0} $</p> </div>
                            <div class="product_aweosme size_cl fexling_class text-capitalize m-0"><p class="gb_cl">{"Discount"}:</p> <p> {itm?.discount || 0} $</p></div>
                            <div class="product_aweosme size_cl fexling_class text-capitalize m-0"><p class="gb_cl">{"Delivery fee"}:</p> <p>{itm?.delivery_fee || 0} $</p> </div> */}
                                    </div>
                                  </div>
                                );
                              })}
                            <div className="profit_list bordertop1">
                              <div className="d-flex justify-content-between">
                                <p className="cart_pros_footer total_amt">
                                  Total amount{" "}
                                </p>
                                <p className="cart_pros_totl total_amt">
                                  {parseFloat(total).toFixed(2)} $
                                </p>
                              </div>
                            </div>
                            {/* } */}
                          </div>
                        )}

                        <div className="btn_checkout mt-4">
                          <button
                            type="button"
                            className={"btn btn-primary w-100"}
                            disabled={cartproduct.length == 0}
                            onClick={(e) =>
                              checkout() &
                              localStorage.setItem(
                                "cart_products",
                                JSON.stringify(cartproduct)
                              )
                            }
                          >
                            Proceed To Checkout
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
);

export default Html;
