import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import "./style.scss";
import ApiClient from "../../apimethods/apiClient";
import { useSelector } from "react-redux";
import loader from "../../components/loader/loader";
import { useNavigate } from "react-router-dom";

const Funds = () => {
  const history = useNavigate();
  const userid = localStorage?.getItem("userid");
  const mode = useSelector((state) => state.user);
  const modeType = mode?.mode ? "live" : "sandbox";
  const [funds, setFunds] = useState([]);

  const getFundings = () => {
    loader(true);
    ApiClient.get(`fundings?merchant_id=${userid}&mode=${modeType}`).then(
      (res) => {
        loader(false);
        setFunds(res?.data?.fundings || []);
      }
    );
  };

  useEffect(() => {
    getFundings();
  }, []);

  return (
    <>
      <Layout>
        <section className="">
          <div className="container">
            <div className="row">
              <div className="col-12 ">
                <div className="d-flex justify-content-between align-items-center ">
                  <h3 className="m-0 hedding">Funds</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="table-responsive table_section">
                  <table className="table">
                    <thead className="table_head">
                      <tr className="heading_row">
                        <th className="table_data">Net Sales</th>
                        <th className="table_data">Total Funding</th>
                        <th className="table_data">Fee</th>
                        <th className="table_data">Third party </th>
                        <th className="table_data">Reversal</th>
                        <th className="table_data">Inter Change Fee</th>
                        <th
                          onClick={() => {
                            history("/adjustments");
                          }}
                          className="table_data"
                        >
                          Adjustment
                        </th>
                        <th className="table_data">Service Charge</th>
                        <th className="table_data">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {funds?.length > 0 ? (
                        funds?.map((data, index) => (
                          <tr key={index}>
                            <td>{data.netsales}</td>
                            <td>{data?.totalfunding}</td>
                            <td>
                              <p className="line-break">{data.fee}</p>
                            </td>
                            <td>
                              <p className="line-break">{data.thirdparty}</p>
                            </td>
                            <td>
                              <p className="line-break">{data.reversal}</p>
                            </td>
                            <td>
                              <p className="line-break">
                                {data.interchangefee}
                              </p>
                            </td>
                            <td>
                              <p className="line-break">{data.adjustment}</p>
                            </td>
                            <td>
                              <p className="line-break">{data.servicecharge}</p>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="credential_data">
                          <td colspan="9">No Funds</td>
                        </tr>
                      )}
                      <tr></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Funds;
