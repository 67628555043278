import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../components/global/layout";
import ApiClient from "../../../apimethods/apiClient";
import loader from "../../../components/loader/loader";
import "./style.scss";
import { useSelector } from "react-redux";
import methodModel from "../../../components/validations/Validations";

const ProductDetail = (p) => {
  const history = useNavigate();
  const user = useSelector((state) => state.user);
  const { id, userId } = useParams();
  const [data, setData] = useState();
  const getDetail = (did) => {
    loader(true);
    ApiClient.get(`product`, { id: did }).then((res) => {
      if (res.success) {
        setData(res.data);
      }
      loader(false);
    });
  };

  const back = () => {
    history(-1);
  };

  const edit = (id) => {
    let url = `/users/edit/${id}`;
    if (role) url = `/users/${role}/edit/${id}`;
    history(url);
  };

  useEffect(() => {
    getDetail(userId ? userId : id);
  }, [id, userId]);

  return (
    <Layout>
      <div className="row">
        <div className="sideclass col-md-12">
          <div className="pprofile1">
            <div className="text-right  title-head">
              <div className="d-flex justify-content-between align-items-center ">
                <h3 className=" hedding  mb-0">
                  <a to="/users" onClick={back}>
                    {" "}
                    <i
                      className="fa fa-arrow-left ml-1 me-2"
                      title="Back"
                      aria-hidden="true"
                    ></i>
                  </a>
                  Product Details
                </h3>
                <div>
                  <div
                    className="btn btn-primary edit-btn d-flex align-items-center"
                    onClick={(e) => history(`/product/edit/${id}`)}
                  >
                    <i
                      style={{ cursor: "pointer" }}
                      class="material-icons edit "
                      title="Edit"
                    >
                      edit
                    </i>
                    Edit
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Name</label>
                            <div className='profiledetailscls'>{data && data.name}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Price</label>
                            <div className='profiledetailscls'>{data && data?.price}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Description</label>
                            <div className='profiledetailscls' dangerouslySetInnerHTML={{ __html: data?.description }}/>
                        </div>
            
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Mode</label>
                            <div className='profiledetailscls'>{data && data.mode}</div>
                        </div>

                        <div className="col-md-12 mb-3">
                            <label className="profileheddingcls">Image</label>
                            <div>
                                <div className="imagethumbWrapper profile-main-img d-flex">
                                    {data?.images?.map((itm)=>{ return<img src={methodModel.userImg(itm)} className="me-2" />})
                                   }
                                </div>
                            </div>
                        </div>
                    </div> */}

            <div className="product-detail-page">
              <div className="form-row">
                <div className="col-md-2 mb-3">
                  <label className="profileheddingcls">Name:</label>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="profiledetailscls">{data && data.name}</div>
                </div>
                <div className="col-md-2 mb-3">
                  <label className="profileheddingcls">Price: </label>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="profiledetailscls">{data && data?.price ? `$ ${ data?.price}` : ""}</div>
                </div>
                <div className="col-md-2 mb-3">
                  <label className="profileheddingcls">Description:</label>
                </div>
                <div className="col-md-10 mb-3">
                  <div
                    className="profiledetailscls"
                    dangerouslySetInnerHTML={{ __html: data?.description }}
                  />
                </div>

                <div className="col-md-2 mb-3">
                  <label className="profileheddingcls">Mode:</label>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="profiledetailscls">{data && data.mode}</div>
                </div>

                <div className="col-md-2 mb-3">
                  <label className="profileheddingcls">Image:</label>
                </div>
                <div className="col-md-10 mb-3">
                  <div>
                    <div className="imagethumbWrapper profile-main-img d-flex">
                      {data?.images?.map((itm) => {
                        return (
                          <img src={methodModel.userImg(itm)} className="me-2" />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductDetail;
