import environment from "../../environment";
import ApiClient from "../../apimethods/apiClient"; ;

const WebsiteDetailAction=()=>async(dispatch)=>{ 
let  result=await ApiClient.get(`setting`);
if(result.success){
    const WebsiteIcon=document.getElementById("websitefavicon");
    WebsiteIcon.href=environment.imageapi+"img/"+result?.data?.fav_icon
    return dispatch({type:"GET_DETAILS",data:result?.data})
}
else{
    return dispatch({type:"Error",data:result})
}
}

export { WebsiteDetailAction};