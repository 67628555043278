import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../components/global/layout";
import ApiClient from "../../../apimethods/apiClient";
import loader from "../../../components/loader/loader";
import "./style.scss";
import * as CryptoJS from "crypto-js";

const AccountDetail = (p) => {
  const history = useNavigate();
  const { id, userId } = useParams();
  const [data, setData] = useState();
  const secretKey = "t8HmAA8wt4JWE5WA0C8jPhK51ROJNepzwsL";

  useEffect(() => {
    getDetail(userId ? userId : id);
  }, [id, userId]);


  const getDetail = (did) => {
    loader(true);
    ApiClient.get(`account`, { id: did }).then((res) => {
      if (res.success) {
        setData(res.data);
      }
      loader(false);
    });
  };

  const decriptData = (data) => {
    if (data) {
      try {
        const bytes = CryptoJS.AES.decrypt(data, secretKey);
        const plainText = bytes.toString(CryptoJS.enc.Utf8);
        const sanitizedText = plainText.replace(/"/g, "");
        return sanitizedText;
      } catch (error) {
        console.error("Decryption error:", error);
        return null;
      }
    }
  };

  const back = () => {
    history(-1);
  };


  return (
    <Layout>
      <div className="sideclass ">
      <div className="pprofile1">
      <div className="text-right title-head">

        <div  className="normalFlex ">
        <h3 className="hedding  mb-0">
          <a className="cursor-pointer" to="/users" onClick={back}>
            {" "}
            <i
              className="fa fa-arrow-left me-2 "
              title="Back"
              aria-hidden="true"
            ></i>
          </a>
          Account Detail
          </h3>
        </div>

      </div>
      
        <div className="product-detail-page">
             <div className="form-row">
            <div className="col-md-2 mb-3">
              <label className="profileheddingcls">Name</label>
            </div>
            <div className="col-md-10 mb-3">
            <div className="profiledetailscls">{data && data.name}</div>     
              </div>
            <div className="col-md-2 mb-3">
              <label className="profileheddingcls">Account Id</label>
              
            </div>
            <div className="col-md-10 mb-3">
            <div className="profiledetailscls">
                {decriptData(data && data.account_id)}
              </div>   
              </div>
            <div className="col-md-2 mb-3">
              <label className="profileheddingcls">Account Number</label>
              
            </div>
            <div className="col-md-10 mb-3">
            <div className="profiledetailscls">
                {decriptData(data && data.realAccountNumber)}
              </div>  
              </div>

            <div className="col-md-2 mb-3">
              <label className="profileheddingcls">Type</label>
           </div>
            <div className="col-md-10 mb-3">
            <div className="profiledetailscls">{data && data.type}</div>
           
              </div>
            <div className="col-md-2 mb-3">
              <label className="profileheddingcls">Market Segment</label>
             
            </div>
            <div className="col-md-10 mb-3">
            <div className="profiledetailscls">
                {data && data.marketSegment}
              </div>
              </div>
            <div className="col-md-2 mb-3">
              <label className="profileheddingcls">Status</label>
           </div>
            <div className="col-md-10 mb-3">
              <div className="profiledetailscls">{data && data.status}</div>
            
              </div>
          </div>
        </div>
      
      </div>
      </div>
    </Layout>
  );
};

export default AccountDetail;
