import React from "react";
import "./style.scss"; 
import Header from "../../components/global/layout/Header";
import Footer from "../../components/global/footer/Footer";
import { useNavigate } from "react-router-dom";

const Html = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Header />
      <div className="top-padding">
        <div className="container-fluid my-3 px-5">
          <div className="row ">
            <div className="col-12 text-center">
              <div className="payment_section">
                <img
                  src="/assets/img/tick-g.png"
                  className="payment_img"
                  alt="..."
                />
                <div className="card-body  payment_body">
                  <h5 className="card-title">Order Successful!</h5>
                  <p className="card-text">Thanks for your order..</p>
                  <button
                    className="btn btn-primary done_btn"
                    onClick={(e) =>
                      navigate(
                        `/products/merchant/${localStorage.getItem("merSlug")}`
                      )
                    }
                  >
                    Continue Shopping
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Html;
