import React from "react";

const Html = ({ ListItemLink, user, urlAllow }) => {
  return (
    <ul className="nav flex-column" component="siderbar">
      {urlAllow("readDashboard") ? (
        <ListItemLink to="/dashboard">
          <i class="fa fa-th-large" aria-hidden="true"></i>

          <span class="side_head">Dashboard</span>
        </ListItemLink>
      ) : null}

      {urlAllow("customers") &&
      user?.role_name != "Customer" &&
      user?.role_name != "Guest" ? (
        <ListItemLink to="/AllCustomers">
          <i class="fa fa-user" aria-hidden="true"></i>

          <span class="side_head">Customers</span>
        </ListItemLink>
      ) : null}

      {urlAllow("customers") &&
      user?.role_name != "Customer" &&
      user?.role_name != "Guest" ? (
        <ListItemLink to="/addcredential">
          <i class="fa fa-id-card" aria-hidden="true"></i>
          <span class="side_head">Add Credential</span>
        </ListItemLink>
      ) : null}
      {urlAllow("customers") &&
      user?.role_name != "Customer" &&
      user?.role_name != "Guest" ? (
        <ListItemLink to="/accounts">
          <i class="fa fa-user-circle" aria-hidden="true"></i>
          <span class="side_head">Accounts</span>
        </ListItemLink>
      ) : null}
      {/* {urlAllow("customers") &&
      user?.role_name != "Customer" &&
      user?.role_name != "Guest" ? (
        <ListItemLink to="/balance">
          <i class="fa fa-credit-card-alt" aria-hidden="true"></i>
          <i class="fa fa-balance-scale" aria-hidden="true"></i>
          <i class="fa fa-solid fa-wallet"></i>

          <span class="side_head">Balance</span>                                                    
        </ListItemLink>
      ) : null} */}
      {urlAllow("customers") &&
      user?.role_name != "Customer" &&
      user?.role_name != "Guest" ? (
        <ListItemLink to="/product">
          {/* <i class="fa fa-database" aria-hidden="true"></i> */}
          <i class="fa fa-cube"></i>
          <span class="side_head">Products</span>
        </ListItemLink>
      ) : null}
       {urlAllow("customers") &&
      user?.role_name != "Customer" &&
      user?.role_name != "Guest" ? (
        <ListItemLink to="/funds">
          {/* <i class="fa fa-database" aria-hidden="true"></i> */}
          <i class="fa fa-usd"></i>
          <span class="side_head">Funds</span>
        </ListItemLink>
      ) : null}
      <ListItemLink to="/orders">
        {/* <i class="fa fa-database" aria-hidden="true"></i> */}
        <i class="fa fa-file"></i>
        <span class="side_head">Orders</span>
      </ListItemLink>

      <ListItemLink to="/transaction">
        <i class="fa fa-random" aria-hidden="true"></i>
        <span class="side_head">Transactions</span>
      </ListItemLink>
    </ul>
  );
};

export default Html;
