import { useEffect, useState } from 'react'
import Html from './html'
import ApiClient from '../../apimethods/apiClient'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import loader from '../../components/loader/loader'

const AllProducts = () => {
    const history = useNavigate()
    const { slug } = useParams()
    const { category_id, sub_category_id, brand_id, store, addedBy } = useParams()
    const params = new URLSearchParams(window.location.search);
    const Sub_id = params.get(`sub_cat`)
    const Sub_name = params.get('sub_name')
    const searchState = useSelector((state) => state.search)
    const [categories, setCategoryId] = useState('')
    const [product, setproduct] = useState([])
    const [total, settotal] = useState(0)
    const [merch, setmerch] = useState('')
    const [categoryData, setcategoryData] = useState([])
    const [subCategory, setsubCategory] = useState([])
    const [Brand, setBrand] = useState([])
    const [array, setarray] = useState([])
    const [activefilters, setactivefilters] = useState({ categoryname: '', subcategoryname: '', BrandName: [] })
    const user = useSelector(state => state.user);
    const [filters, setfilters] = useState({ page: 1, count: 12, type: "product", sortBy: 'name asc', status: "active", isDeleted: 'false', search: '', category_id: '', sub_category_id: '',user_id:user?._id })
    const [pricerange, setpricerange] = useState(1000)
    const [searchfor, setsearchfor] = useState("")

    useEffect(() => {
        if (user?.role_name == 'Merchant') {
            history("/")
        }
    }, [])

    useEffect(() => {
        if (slug) {
            localStorage?.setItem('merSlug', slug)
        }
    }, [])

    const innerSearch = (value) => {
        setfilters({ ...filters, search: value })
        getproducts({ search: value, page: 1 })
    }
    const clearsearch = () => {
        setsearchfor("")
        setfilters({ ...filters, search: '' })
        getproducts({ search: "" })
    }

    const filterss = (value) => {
        setfilters({ ...filters, sortBy: value })
        getproducts({ sortBy: value })
    }

    useEffect(() => {
        if (searchState.data) {
            getproductsearch({ search: searchState.data })
        }
    }, [searchState.data])

    const getproducts = (p = {}) => {
        let payload = {
            ...filters, page: 1, type: "product", sortBy: 'name asc', status: "active", isDeleted: 'false', user_id: user?.id, merchant_id: merch, ...p || ""
        }
        if (!user.id) {
            delete payload.user_id
        }
        if (searchState.data) {
            return
        }
        loader(true)
        ApiClient.get(`product/all`, payload).then(res => {
            if (res?.success) {
                settotal(res?.total)
                setproduct(res?.data ? res?.data : [])
            } else {
                toast.error(res?.message)
            }
            loader(false)
        })
    }

    useEffect(() => {
        if (slug || localStorage?.getItem('merSlug')) {
            loader(true)
            ApiClient.get(`user/slug?slug=${slug || localStorage?.getItem('merSlug')}`).then(res => {
                if (res?.success) {
                    loader(false)
                    setmerch(res?.data?.id)
                } else {
                    toast.error(res?.message)
                }
                
            })
        }
    }, [slug])

    useEffect(() => {
        if (!slug || !localStorage.getItem('merSlug')) {
            loader(true)
            if(user?.id){
            ApiClient.get(`product/all?user_id=${user?.id}`).then(res => {
                if (res?.success) {
                    settotal(res?.total)
                    setproduct(res?.data ? res?.data : [])
                } else {
                    toast.error(res?.message)
                }
                loader(false)
            })
        }
        }
    }, [])

    useEffect(() => {
        loader(true)
        if(merch){
        ApiClient.get(`product/all?merchant_id=${merch}`).then(res => {
            if (res?.success) {
                settotal(res?.total)
                setproduct(res?.data ? res?.data : [])
            } else {
                toast.error(res?.message)
            }
            loader(false)
        })
    }
    }, [merch])

    const getproductsearch = (p = {}) => {
        let payload = {
            ...p, user_id: user.id || ""
        }
        if (!user.id) {
            delete payload.user_id
        }
        loader(true)
        ApiClient.get(`product/all`, payload).then(res => {
            if (res?.success) {
                settotal(res?.data?.total)
                setproduct(res?.data ? res?.data : [])
            } else {
                toast.error(res?.message)
            }
            loader(false)
        })
    }

    useEffect(() => {
        ApiClient.get(`categories/listing`, { status: 'active' }).then(res => {
            if (res?.success) {
                const productCategory = res?.data?.filter((item) => item?.catType == 'products')
                setcategoryData(productCategory ? productCategory : [])
            } else {
                toast.error(res?.message)
            }
        })
    }, [])


    const productpage = (id) => {
        if (slug || localStorage?.getItem('merSlug')) {
            history(`/products/${slug || localStorage.getItem('merSlug')}/` + id)
        } else {
            history("/products/" + id)
        }
    }

    const pageChange = (e) => {
        setfilters({ ...filters, page: e })
        getproducts({ page: e })
    }

    const getactivefilter = (id) => {
        if (id) {
            setCategoryId(id)
            setfilters({ ...filters, category_id: id })
            getproducts({ category_id: id })
        } else {
            setfilters({ ...filters, category_id: '' })
        }
    }

 


    return (
        <>
            <Html
                product={product}
                productpage={productpage}
                total={total}
                filters={filters}
                pageChange={pageChange}
                categoryData={categoryData}
                subCategory={subCategory}
                getproducts={getproducts}
                setfilters={setfilters}
                BrandArray={array}
                pricerange={pricerange}
                setpricerange={setpricerange}
                category_id={category_id}
                Brand={Brand}
                activefilters={activefilters}
                setactivefilters={setactivefilters}
                searchfor={searchfor}
                setsearchfor={setsearchfor}
                innerSearch={innerSearch}
                clearsearch={clearsearch}
                filterss={filterss}
                setproduct={setproduct}
                getactivefilter={getactivefilter}
                categories={categories}
                setCategoryId={setCategoryId}
                slug={slug}
            />
        </>
    )
}

export default AllProducts
