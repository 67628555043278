import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../components/global/layout";
import ApiClient from "../../../apimethods/apiClient";
import loader from "../../../components/loader/loader";
import "./style.scss";
import { useSelector } from "react-redux";
import methodModel from "../../../components/validations/Validations";

const CustomerDetail = (p) => {
  const userId = localStorage?.getItem("userid");
  // const [form,setform]=useState({resume:"",id:"",resumeDocument:""})
  // const [Comission,setComission]=useState("")
  // const [RejectionReason,setRejectionReason]=useState("");
  // const [Resume,setResume]=useState("")
  const history = useNavigate();
  const mode = useSelector((state) => state.user);
  const { ccpid, custid } = useParams();
  const [data, setData] = useState({});
  const [cardsDetails, setCardDetails] = useState([]);
  const getCustomerCardDetail = (did) => {
    loader(true);
    ApiClient.get(
      `cp-profile-detail?merchant_id=${userId}&mode=${
        mode?.mode ? "live" : "sandbox"
      }&profile=${ccpid}&user_id=${custid}`
    ).then((res) => {
      if (res.success) {
        setData(res.data ? res?.data?.profile : "");
        setCardDetails(res?.data ? res?.data?.cards : []);
      }
      loader(false);
    });
  };

  const back = () => {
    history(-1);
  };

  const edit = (id) => {
    let url = `/users/edit/${id}`;
    if (role) url = `/users/${role}/edit/${id}`;
    history(url);
  };

  useEffect(() => {
    if (custid) {
      getCustomerCardDetail();
    }
  }, []);

  return (
    <Layout>
      <div className="">
        <div className="sideclass ">
          <div className="pprofile1">
            <div className="text-right title-head">
              <div className="normalFlex ">
                <h3 className="hedding  mb-0">
                  <a to="/users" onClick={back} className="cursor-pointer">
                    {" "}
                    <i
                      className="fa fa-arrow-left me-2 "
                      title="Back"
                      aria-hidden="true"
                    ></i>
                  </a>{" "}
                  User Detail
                </h3> 
              </div>
            </div>
            <div className="product-detail-page">
              <div className="form-row">
                <div className="col-md-2 mb-3 ">
                  <label className="profileheddingcls">Image:</label>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="imagethumbWrapper profile-main-img">
                    <img
                      src={methodModel.userImg(data && data.image)}
                      className="uploadimage"
                    />
                  </div>
                </div>
                <div className="col-md-2 mb-3">
                  <label className="profileheddingcls">Name:</label>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="profiledetailscls">
                    {data && data.fullName}
                  </div>
                </div>
                <div className="col-md-2 mb-3">
                  <label className="profileheddingcls">Mobile No:</label>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="profiledetailscls">
                    {data?.mobile_no ? data?.mobile_no : "N/A"}
                  </div>
                </div>
                <div className="col-md-2 mb-3">
                  <label className="profileheddingcls">Email:</label>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="profiledetailscls">{data && data.email}</div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>  
    </Layout>
  );
};

export default CustomerDetail;
