import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ApiClient from "../../apimethods/apiClient";
import loader from "../../components/loader/loader";
import "./profile.scss";
import { useDispatch, useSelector } from "react-redux";
import methodModel from "../../components/validations/Validations";
import { login_success } from "../../redux/actions/user";
import Header from "../../components/global/layout/Header";
import Footer from "../../components/global/footer/Footer";
import { FaCopy } from 'react-icons/fa';

const Profile = () => {
  const dispatch = useDispatch();
  let user = useSelector((state) => state.user);
  const [data, setData] = useState("");
  const [copySuccess, setCopySuccess] = useState('');

  const gallaryData = () => {
    loader(true);
    ApiClient.get(`user/profile`, { id: user._id }).then((res) => {
      if (res.success) {
        setData(res.data);
        const data = res.data;
        const newdata = { ...user, ...data };
        dispatch(login_success(newdata));
      }
      loader(false);
    });
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(`https://lux.jcsoftwaresolution.in/products/merchant/${data?.slug}`);
      setCopySuccess('Copied!');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  useEffect(() => {
    if (user && user._id) {
      gallaryData();
    }
  }, [user._id]);

  return (
    <>
      <Header />

     <div className="top-padding">
     <div className="container ">
        <div className="main_profile_page">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="right_profile mt-3">
               <div className="title-head">
               <div className="d-flex justify-content-between align-items-center ">
                  <h3 className="hedding mb-0">Basic Information</h3>
                  <Link
                    to="/profile/edit"
                    className="btn btn-primary "
                    title="Edit Profile"
                  >
                    Edit Profile
                    {/* <i className="fa fa-edit" title='Edit Profile' /> */}
                  </Link>
                </div>
               </div>
                <div className="product-detail-page">
                <div className="row">
                  <div className="col-md-12  inputflex">
                    <div className="profile_img_side inputflex pt-0">
                      <label className="label_profile">Image:</label>
                      <img
                        src={methodModel.userImg(data && data.image)}
                        className="profileImagemain"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 inputflex mt-3">
                   
                      <label className="label_profile">Name:</label>
                      <div className="view-profile-bg">
                        <p className="profile_data mb-0">{data && data.fullName}</p>
                      </div>
                   
                  </div>
                  <div className="col-md-12 inputflex mt-3">
                    <label className="label_profile">Email:</label>
                    <div className="view-profile-bg">
                      <p className="profile_data">{data && data.email}</p>
                    </div>
                  </div>
                  <div className="col-md-12 inputflex mt-3">
                    <label className="label_profile">Role:</label>
                    <div className="view-profile-bg">
                      <p className="profile_data">{data && data.role.name}</p>
                    </div>
                  </div>
                  {/* <div className="col-md-12 inputflex mt-3">
                    <label className="label_profile">TimeZone</label>
                    <div>
                      <p className="profile_data">{data && data.timeZone&&data.timeZone.label}</p>
                    </div>
                  </div> */}
                  <div className="col-md-12 inputflex mt-3">
                    <label className="label_profile">Address:</label>
                    <div className="view-profile-bg">
                      <p className="profile_data">{data && data.address}</p>
                    </div>
                  </div>
                 {data.companyName && <div className="col-md-12 inputflex mt-3">
                    <label className="label_profile">Business Name: </label>
                    <div className="view-profile-bg">
                      <p className="profile_data">{data && data.companyName}</p>
                    </div>
                  </div>}
                  <div className="col-md-12 inputflex mt-3">
                    <label className="label_profile">Mobile No:</label>
                    <div className="view-profile-bg">
                      <p className="profile_data">
                        {data && data.dialCode != ""
                          ? `(${data.dialCode || ""})`
                          : null}
                        {(data && data.mobileNo) || ""}
                      </p>
                    </div>
                  </div>
                    { user?.role_name == 'Merchant' && <div className="col-md-12 inputflex mt-3">
                        <label className="label_profile">Merchant Link</label>
                        <div className='profiledetailscls'>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <a href={`https://lux.jcsoftwaresolution.in/products/merchant/${data?.slug}`}>
                              {`https://lux.jcsoftwaresolution.in/products/merchant/${data?.slug}`}
                            </a>
                            <button onClick={copyToClipboard} style={{ marginLeft: '5px', border: 'none', background: 'none', cursor: 'pointer' }}>
                              <FaCopy />
                            </button>
                          </div>
                          {copySuccess && <span style={{ marginLeft: '5px', color: 'green' }}>{copySuccess}</span>}
                        </div>
                      </div>}
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
