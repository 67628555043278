import { useEffect, useState } from "react";
import "./style.scss"; 
import { useSelector } from "react-redux";
import Header from "../../components/global/layout/Header";
import Footer from "../../components/global/footer/Footer";
import ApiClient from "../../apimethods/apiClient";
import methodModel from "../../components/validations/Validations";
import loader from "../../components/loader/loader";
import { useNavigate } from "react-router-dom";

const Whislist = ({}) => {
  const user = useSelector((state) => state.user);
  const [filters, setfilters] = useState({
    page: 1,
    count: 12,
    search: "",
    user_id: user?._id,
  });
  const [data, setData] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    if (user?.id) {
      allData();
    }
  }, []);

  const allData = () => {
    loader(true);
    let payload = {
      ...filters,
      page: 1,
      user_id: user?.id,
    };
    ApiClient.get(`wishlist`, payload).then((res) => {
      loader(false);
      if (res?.success) {
        setData(res?.data);
      }
    });
  };

  const addTocart = (itm_id) => {
    if (user?.loggedIn) {
      let payload = {
        product_id: itm_id,
      };
      ApiClient.post(`cart/add-item`, payload).then((res) => {
        if (res?.success) {
          history("/cart");
        }
      });
    }
  };

  return (
    <>
      <Header />
      <div className="top-padding">
        <div className="container">
          <div className="wishlistcard">
            <h3 className="wishheader">
              <i class="fa fa-heart mr-2 text-danger" aria-hidden="true"></i>My
              Wishlist
            </h3>
            <div className="table-responsive wishtable ">
              {" "}
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Product Name</th>
                    <th scope="col">Unit Price</th> 
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((data) => (
                    <tr>
                      <td>
                        <img
                          src={methodModel.noImg(data?.product_images?.[0])}
                          className="wistimg"
                        />{" "}
                        <span className="wishdata">{data?.product_name}</span>
                      </td>
                      <td>
                        {" "}
                        <span className="wishdata">${data?.product_price}</span>
                      </td> 
                      <td>
                        <button
                          class="btn dark-btn wishbtn"
                          onClick={() => addTocart(data?.product_id)}
                        >
                          Add to Cart
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Whislist;
