import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login_success } from '../../redux/actions/user';
import ApiClient from '../../apimethods/apiClient';
import loader from '../../components/loader/loader';
import { Link } from 'react-router-dom';
import './style.scss';
import methodModel from '../../components/validations/Validations';
import { toast } from 'react-toastify';
import environment from '../../environment';
import { useLocation, useParams,useNavigate } from 'react-router-dom';

const Login = () => {
  const { slug } = useParams()
  const history = useNavigate();
  const user = useSelector((state) => state.user);
  const Websitedetails = useSelector((state) => state.website);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get('order_id'); 

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history("/dashboard");
    }
  }, []);

  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [remember, setRemember] = useState(false);
  const [password, setPassword] = useState("");

  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });

  useEffect(() => {
    loader(true);
    setTimeout(() => {
      loader(false);
    }, 500);

    let r = localStorage.getItem("remember");
    if (r) {
      let data = JSON.parse(r);
      setUsername(data.email);
      setPassword(data.password);
      setRemember(true);
    }

    let message = methodModel.getPrams("message");
  }, []);

  const hendleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: username,
      password,
    };

    loader(true);

    ApiClient.post("user/login", data).then((res) => {
      loader(false);
      if (res.success) {
        if (remember) {
          localStorage.setItem("remember", JSON.stringify(data));
        } else {
          localStorage.removeItem("remember");
        }
        if(slug){
          localStorage.setItem("merSlug",slug); 
        }
        toast.success(res.message);
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("userid",res?.data?._id)
        dispatch(login_success(res.data));
        const newdata = res.data;
        if(orderId){
          history(`/payment?order_id=${orderId}`)
        }else{
          history("/dashboard")
        }
        ;
      }
    });
  };

  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");
  useEffect(() => {
    if (id) {
      loader(true);
      localStorage.clear();
       ApiClient.post('auto/login',{id:id}).then(response => {
    
        if(response.success){  
          dispatch(login_success(response.data))  
          localStorage.setItem('token', response.data.access_token)
          toast.success(response.message);
          const newdata = response.data;
          history("/profile");
        }
        loader(false);
      });
    }
  }, []);

  return (
    <>
      <div className="bg_img main_signup">
      <Link className="right_home" to="/">
          <span class="material-icons arrow_right">arrow_back</span> Home
        </Link>
      <div className=" center-img">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="right_side">
              <Link to="/">
                <div className="logo_image">
                  <img
                    src={environment.imageapi + "img/" + Websitedetails.logo}
                    className="logo_name mb-2"
                  />
                </div>
              </Link>
              <div className="text-center mb-2">
                <h3 className="text-cenetr lgtext">Sign in</h3>
              </div>
              {/* <p className="accopunt text-center">
                Don’t have an account?{" "}
                <Link className="sign_up" to="/signup">
                  {" "}
                  Sign Up
                </Link>
              </p> */}

              <form className="centerLogin mt-3" onSubmit={hendleSubmit}>
                <div className="mb-3">
                  <label className="ml-1">
                    {" "}
                    Email <span className="red">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control mb-0 bginput changes"
                    placeholder="Email address"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </div>

                <label className="ml-1">
                  {" "}
                  Password <span className="red">*</span>
                </label>
                <div className="mb-0">
                  <div className="inputWrapper">
                    <input
                      type={eyes.password ? "text" : "password"}
                      className="form-control mb-0 bginput changes"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      required
                    />
                    <i
                      className={
                        eyes.password ? "fa fa-eye" : "fa fa-eye-slash"
                      }
                      onClick={() =>
                        setEyes({ ...eyes, password: !eyes.password })
                      }
                    ></i>
                  </div>
                </div>

                <div className="forget text-right mt-1">
                  <Link to="/forgotpassword" className="">
                    Forgot Password?
                  </Link>
                </div>
                <div className="mt-0">
                  <button
                    type="submit"
                    className="btn btn-primary loginclass mt-2"
                  >
                    Sign in
                  </button>
                </div>
                {/* <div className='borderCls mt-5'><span className='or'>or</span></div>
                <div className='text-center d-flex justify-content-center mt-5'>
                <a className='btn btn-outline google_id mr-3 '>
                  <img src='/assets/img/gogle.png' />
                  <span className='google_heading'></span>
                </a>
                <a className='btn btn-outline google_id'>
                  <img src='/assets/img/facebooklogo.png' />
                  <span className='google_heading'></span>
                </a>
                </div> */}
              </form>
            </div>
          </div>
        </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Login;
