import EChartsReact from "echarts-for-react";
import React from "react"; 
import WebsiteHeader from "../../global/websiteheader/WebsiteHeader";
import { useParams } from "react-router-dom";

export default function Chart() {
  const { slug } = useParams();

  const Myoption = {
    color: ["#80FFA5", "#00DDFF", "#37A2FF", "#FF0087", "#FFBF00"],
    title: {
      text: `Data of ${slug} `,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      data: [`${slug} 1`, `${slug} 2`, `${slug} 3`, `${slug} 4`, `${slug} 5`],
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        name: `${slug} 1`,
        type: "line",
        stack: "Total",
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data: [140, 232, 101, 264, 90, 340, 250],
      },
      {
        name: `${slug} 2`,
        type: "line",
        stack: "Total",
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data: [120, 282, 111, 234, 220, 340, 310],
      },
      {
        name: `${slug} 3`,
        type: "line",
        stack: "Total",
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data: [320, 132, 201, 334, 190, 130, 220],
      },
      {
        name: `${slug} 4`,
        type: "line",
        stack: "Total",
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data: [220, 402, 231, 134, 190, 230, 120],
      },
      {
        name: `${slug} 5`,
        type: "line",
        stack: "Total",
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data: [220, 302, 181, 234, 210, 290, 150],
      },
    ],
  };

  const BarGraphOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        name: `${slug}`,
        type: "bar",
        barWidth: "60%",
        data: [10, 52, 200, 334, 390, 330, 220],
      },
    ],
  };

  const PieChartOption = {
    title: {
      text: `Referer of ${slug}`,
      subtext: "Data",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: "50%",
        data: [
          { value: 1048, name: `${slug} A` },
          { value: 735, name: `${slug} B` },
          { value: 580, name: `${slug} C` },
          { value: 484, name: `${slug} D` },
          { value: 300, name: `${slug} E` },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  const NextLevelChart = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    grid: {
      right: "20%",
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    legend: {
      data: [`${slug} A`, `${slug} B`, `${slug} C`],
    },
    xAxis: [
      {
        type: "category",
        axisTick: {
          alignWithLabel: true,
        },
        data: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    ],
    yAxis: [
      {
        type: "value",
        name: `${slug} A`,
        position: "right",
        alignTicks: true,
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: "{value} ml",
        },
      },
      {
        type: "value",
        name: `${slug} B`,
        position: "right",
        alignTicks: true,
        offset: 80,
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: "{value} ml",
        },
      },
      {
        type: "value",
        name: `${slug}`,
        position: "left",
        alignTicks: true,
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: "{value} °C",
        },
      },
    ],
    series: [
      {
        name: `${slug} B`,
        type: "bar",
        data: [
          2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3,
        ],
      },
      {
        name: `${slug} A`,
        type: "bar",
        yAxisIndex: 1,
        data: [
          2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3,
        ],
      },
      {
        name: `${slug} C`,
        type: "line",
        yAxisIndex: 2,
        data: [
          2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2,
        ],
      },
    ],
  };
  return (
    <div>
      <WebsiteHeader />
      <div className="mt-5">
        <EChartsReact
          option={Myoption}
          style={{ width: "100%", height: "400px" }}
        />
      </div>
      <div className="mt-5">
        <h3 className="text-center">Data According to Week</h3>
        <EChartsReact
          option={BarGraphOption}
          style={{ width: "100%", height: "400px" }}
        />
      </div>
      <div className="mt-5">
        <EChartsReact
          option={PieChartOption}
          style={{ width: "100%", height: "400px" }}
        />
      </div>
      <div className="mt-5">
        <EChartsReact
          option={NextLevelChart}
          style={{ width: "100%", height: "400px" }}
        />
      </div>
    </div>
  );
}
