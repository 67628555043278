import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../components/global/layout";
import ApiClient from "../../../apimethods/apiClient";
import loader from "../../../components/loader/loader";
import "./style.scss";
import { useSelector } from "react-redux";
import methodModel from "../../../components/validations/Validations";

const OrderDetail = (p) => {
  const history = useNavigate();
  const user = useSelector((state) => state.user);
  const { id, userId } = useParams();
  const [data, setData] = useState();
  const getDetail = (did) => {
    loader(true);
    // let url = user?.role_name != "Customer" || 'Guest' ? "order/sub" : "order";
    ApiClient.get('order', { id: did }).then((res) => {
      if (res.success) {
        setData(res.data);
      }
      loader(false);
    });
  };

  const back = () => {
    history(-1);
  };

  useEffect(() => {
    if (id) {
      getDetail(userId ? userId : id);
    }
  }, [id, userId, user?.role_name]);

  const flatItems = data?.suborders
    ?.flat()
    ?.reduce((acc, curr) => acc.concat(curr.items), []);

  return (
    <Layout>
      <div className=" mt-3">
        <div className="container-fluid">
          <a className="back_arrow" to="/users" onClick={back}>
            {" "}
            <i
              className="fa fa-arrow-left left_arrow me-2  "
              title="Back"
              aria-hidden="true"
            ></i>{" "}
            Order Detail
          </a>

          <div className="row px-2 pt-3">
            <div className="col-lg-8">
              <div className="white_bx">
                <div className="row">
                  {/* {user?.role_name == "Customer" || 'Guest' ? ( */}
                    {/* <div className="border_bx">
                      <div className="col-md-12 mb-3">
                        <label className="order_prices">Products</label>
                        <div className="border-bottom pb-3">
                          
                            {flatItems &&
                              flatItems?.map((item) => {
                                return (
                                  <>
                                  <div className="row  ">
                                    <div className="col-md-3">
                                     <div className="mb-3">
                                     <img
                                        src={methodModel.noImg(
                                          item &&
                                            item?.product_data?.images?.[0],
                                          "users"
                                        )}
                                        className="order_images"
                                      />
                                     </div>
                                    </div>

                                    <div className="col-md-9">
                                    <div className="mb-3">
                                    <div className="Product_order">
                                        {item && item?.product_data?.name}
                                      </div>
                                      <div className="Product_order1">
                                        Qty:{item && item?.product_data?.qty}
                                      </div>
                                      <div className="Product_order1">
                                        {item && item?.product_data?.price} $
                                      </div>
                                    </div>
                                    </div>
                                    </div>
                                  </>
                                );
                              })}
                         
                        </div>
                      </div>
                    </div> */}
                  {/* ) : ( */}
                    <div className="col-md-12 mb-3">
                      <label className="order_prices">Products</label>
                      <div className="border p-3 rounded">
                        
                          {data &&
                            data?.items?.map((item) => {
                              return (
                                <>
                                <div className="row mb-2 ">
                                  <div className="col-md-3">
                                  <div className="mb-3">
                                  <img
                                      src={methodModel.noImg(
                                        item && item?.product_data?.images?.[0],
                                        "users"
                                      )}
                                      className="order_images"
                                    />
                                  </div>
                                  </div>

                                  <div className="col-md-9">
                                    <div className="mb-3">
                                    <div className="profiledetailscls">
                                      Qty:{item && item?.product_data?.qty}
                                    </div>
                                    <div className="profiledetailscls">
                                      {item && item?.product_data?.name}
                                    </div>
                                    <div className="profiledetailscls">
                                      {item && item?.product_data?.price} $
                                    </div>
                                    </div>
                                  </div>
                                  </div>
                                </>
                              );
                            })}
                      
                      </div>
                    </div>
                  {/* )} */}
                </div>

                <div className="mt-4"></div>
                <div className="container-fluid">
                  <div className="row ">
                    {data?.shipping_address && (
                      <div className="col-md-6 mb-3">
                        <label className="order_prices">Shipping Address</label>

                        <div className="profiledetailscls ">
                          {data && data?.shipping_address}
                        </div>
                        <div className="profiledetailscls">
                          {data && data?.shipping_city}
                        </div>
                        <div className="profiledetailscls">
                          {data && data?.shipping_pincode}
                        </div>
                      </div>
                    )}
                    {data?.billing_address && (
                      <div className="col-md-6 mb-3">
                        <label className="order_prices">Billing Address</label>

                        <div className="profiledetailscls ">
                          {data && data?.billing_address}
                        </div>
                        <div className="profiledetailscls">
                          {data && data?.billing_city}
                        </div>
                        <div className="profiledetailscls">
                          {data && data?.billing_pincode}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
            <div className="white_bx">
            <h3 className="order_prices">Order Summarry</h3>
             
                <div className="d-flex mb-2 justify-content-between ">
                  <label className="profileheddingcls">Tax</label>
                  <div className="profiledetailscls">{data && data?.tax}</div>
                </div>
                <div className="d-flex mb-2 justify-content-between ">
                  <label className="profileheddingcls">Discount</label>
                  <div className="profiledetailscls">
                    {data && data?.discount}
                  </div>
                </div>
                {/* <div className="d-flex mb-2 justify-content-between ">
                  <label className="profileheddingcls">GST</label>
                  <div className="profiledetailscls">{data && data?.gst}</div>
                </div> */}
                <div className="d-flex mb-2 justify-content-between ">
              <label className="profileheddingcls">Price</label>
              <div className="profiledetailscls">{data && data?.sum_total}</div>
            </div>
                {/* <div className="d-flex  mb-3 justify-content-between ">
                  <label className="profileheddingcls">Delivery Fee</label>
                  <div className="profiledetailscls">
                    {data && data?.delivery_fee}
                  </div>
                </div> */}
                {data?.grand_total && (
                  <div className="d-flex py-3 justify-content-between total_bx">
                    <label className="total_price fw-bold mb-0">
                      Grand Total($)
                    </label>
                    <div className="total_price fw-bold mb-0">
                      {data && data?.grand_total}
                    </div>
                  </div>
                )}
            
            </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-md-12">
        <div className=" d-flex align-items-cente justify-content-between">
          <label className="profileheddingcls">Grand Total($)</label>
          <div className="profiledetailscls">{data && data?.grand_total}</div>
        </div>
      </div> */}
    </Layout>
  );
};

export default OrderDetail;
