import React, { useEffect, useRef, useState } from "react";

const TokenIframe = ({ setToken, setError }) => {
  const iframeRef = useRef(null);

  // useEffect(() => {
  //   const handleMessage = (event) => {
  //     // window.parent.postMessage('Hello Parent', '*'); // Not recommended for production
  //     // Verify the origin of the message
  //     if (event.origin == "https://fts-uat.cardconnect.com") {
  //       console.log(event,'evntttttt');
        
  //       if (event?.data?.message) {
  //         let data = JSON.parse(event?.data)?.message;
  //         setToken(data);
  //         console.log("Received token:", data);
  //       } else if (event?.data?.validationError) {
  //         let error = JSON.parse(event?.data)?.validationError;
  //         setError(error);
  //         console?.log(error, "erororororo");
  //       }

  //       // Handle the token as needed
  //     }
  //   };

  //   // Add event listener for messages
  //   window.addEventListener("message", handleMessage);

  //   // Cleanup event listener on component unmount
  //   return () => {
  //     window.removeEventListener("message", handleMessage);
  //   };
  // }, []);

  // useEffect(() => {
  //   const iframe = iframeRef.current;

  //   const injectCSS = () => {
  //     if (iframe && iframe.contentDocument) {
  //       const style = iframe.contentDocument.createElement('style');
  //       style.textContent = `
  //         input[type="text"] {
  //           border: 2px solid #4CAF50;
  //           padding: 10px;
  //           border-radius: 5px;
  //           font-size: 16px;
  //         }
  //       `;
  //       iframe.contentDocument.head.appendChild(style);
  //     }
  //   };

  //   iframe.addEventListener('load', injectCSS);

  //   return () => {
  //     iframe.removeEventListener('load', injectCSS);
  //   };
  // }, []);

  useEffect(() => {
    const handleMessage = (event) => {
        // Verify the origin of the message 
        if (event.origin === 'https://isv-uat.cardconnect.com') {
            try {
                const data = JSON.parse(event.data);
                if (data.message) {
                    setToken(data.message);
                    setError("") 
                } else if (data.validationError) {
                    setError(data.validationError);
                    console.error('Validation error:', data.validationError);
                } else { 
                    console.warn('Unknown message received:', data);
                }
            } catch (error) { 
                console.error('Failed to parse message:', error);
            }
        } else {
            console.warn('Message received from unknown origin:', event.origin);
        }
    }; 
    window.addEventListener('message', handleMessage); 
    return () => {
        window.removeEventListener('message', handleMessage);
    };
}, [setToken, setError]);
  return (
    <div>
    <form>
    <iframe
        id="tokenIframe"
        src="https://isv-uat.cardconnect.com/itoke/ajax-tokenizer.html?sendcssloadedevent=true&cardinputmaxlength=16&cardnumbernumericonly=true&invalidinputevent=true&enhancedresponse."
        title="Token Iframe"
        height="35"
        width="500"
        autoFocus="false"
      />

    </form>
    </div>
  );
};

export default TokenIframe;
