import React, { useState, useEffect } from "react";
import ApiClient from "../../../apimethods/apiClient";
import loader from "../../../components/loader/loader";
import methodModel from "../../../components/validations/Validations"; 
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Html from "./html";
import CryptoJS from 'crypto-js';
import environment from "../../../environment";
 

const AddEditCreds = () => {
  const { role, id } = useParams(); 
  const [images, setImages] = useState([]); 
  const [form, setform] = useState({ 
    mode: "", 
    site : "",
    merchid: "",
    currency: "USD", 
    authuname: "",
    authpass: "",
    isAch : false

  });
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
  const [submitted, setSubmitted] = useState(false);
  const history = useNavigate();
  const [emailLoader, setEmailLoader] = useState(false);
  const [emailErr, setEmailErr] = useState("");
  const [detail, setDetail] = useState(); 
 
  const getError = (key) => {
    // return methodModel.getError(key, form, formValidation)
  }; 

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    let method = "post";
    let url = 'creds';
    let value = {}
  
    const fieldsToEncrypt = ['site', 'merchid', 'currency', 'authuname', 'authpass'];
    value = prepareEncryptedData(form, fieldsToEncrypt);
    if (id) {
      method = "put";
      url = "creds";
      const fieldsToEncrypt = ['site', 'merchid', 'currency', 'authuname', 'authpass'];
      value = prepareEncryptedData(form, fieldsToEncrypt);

    } else {
      delete value.id;
    }

    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        toast.success(res.message);
        let url = "/addcredential";
        // if(role) url="/users/"+role
        history(url);
      }
      loader(false);
    });
  };

  const imageResult = (e, key) => {
    setImages(e?.value);
  };

  const addressResult = (e) => {
    setform((state) => {
      return { ...state, address: e.value };
    });
  };

  const back = () => {
    history(-1);
  };
  function prepareEncryptedData(form, fieldsToEncrypt) {
    const encryptedData = { ...form,
      id : id }; 
    fieldsToEncrypt.forEach(field => {
      if (encryptedData[field] !== undefined) {
        encryptedData[field] = encryptValue(encryptedData[field]);
      }
    });
  
    return encryptedData;
  }

  function encryptValue(value) {
    return CryptoJS.AES.encrypt(value, environment?.secretKey).toString();
  }
 

  const emailCheck = (email) => {
    let isValid = methodModel.emailvalidation(email);
    if (isValid) {
      setEmailLoader(true);
      ApiClient.get("check/Email", { email: email }).then((res) => {
        if (!res.success) {
          if (detail?.email != email) {
            setEmailErr(res.error.message);
          }
        } else {
          setEmailErr("");
        }
        setEmailLoader(false);
      });
    }
  };

  useEffect(() => {
    setSubmitted(false);
    if (id) {
      loader(true);
      ApiClient.get(`creds/${id}`).then((res) => {
        if (res.success) { 
          setDetail(res?.data);
          setform({  
            mode: res?.data?.mode || "",
            site: res?.data?.site ? decrypt(res?.data?.site) : "",
            merchid:res?.data?.merchid ?  decrypt(res?.data?.merchid) :  "",
            authuname: res?.data?.authuname?  decrypt(res?.data?.authuname) : "", 
            isAch : res?.data?.isAch || false,
            currency: res?.data?.currency ?  decrypt(res?.data?.currency ) : "",
            authpass: res?.data?.authpass ? decrypt(res?.data?.authpass) : ""
          });
         
        }
        loader(false);
      });
    } 
  }, [id]);

  function decrypt(encryptedValue) {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, environment?.secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }


 

  return (
    <>
      <Html
        form={form}
        detail={detail}
        emailCheck={emailCheck}
        emailLoader={emailLoader}
        emailErr={emailErr}
        back={back}
        setEyes={setEyes}
        eyes={eyes}
        role={role}
        setform={setform}
        submitted={submitted}
        images={images}
        addressResult={addressResult}
        handleSubmit={handleSubmit}
        imageResult={imageResult}
        getError={getError}
        id={id}
        history={history}
        decrypt={decrypt}
      />
    </>
  );
};

export default AddEditCreds;
