import React, { useState, useEffect } from "react";
import ApiClient from "../../../apimethods/apiClient";
import loader from "../../../components/loader/loader";
import { useSelector } from "react-redux";
import methodModel from "../../../components/validations/Validations";
import { userType } from "../../../models/type.model";
import Html from "./Html";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AddEditProduct = () => {
  const { role, id } = useParams();
  const user = useSelector((state) => state.user);
  const Modes = useSelector((state) => state.user);
  const [images, setImages] = useState([]);
  console.log(images.length, "length ")

  const defaultvalue = userType;
  const [form, setform] = useState({
    category_id: "",
    merchant_id: user?._id,
    name: "",
    description: "",
    price: "",
  });
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
  const [submitted, setSubmitted] = useState(false);
  const history = useNavigate();
  const [category, setCategory] = useState([]);
  const [emailLoader, setEmailLoader] = useState(false);
  const [emailErr, setEmailErr] = useState("");
  const [ChangeStatus, setChangeStatus] = useState("");
  const [detail, setDetail] = useState();
  const modeType = Modes?.mode ? "live" : "sandbox";
  const getError = (key) => {
    // return methodModel.getError(key, form, formValidation)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!form?.category_id || !form?.description || images.length === 0) {
      return
    }
    let method = "post";
    let url = "product";
    let value = {
      ...form,
      images: images,
      mode: modeType,
    };
    if (value.id) {
      method = "put";
      url = "product";
    } else {
      delete value.id;
    }

    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        toast.success(res.message);
        let url = "/product";
        // if(role) url="/users/"+role
        history(url);
      }
      loader(false);
    });
  };

  const imageResult = (e, key) => {
    setform({ ...form })
    setImages(e?.value);
  };

  const addressResult = (e) => {
    setform((state) => {
      return { ...state, address: e.value };
    });
  };

  const back = () => {
    history(-1);
  };

  const getCategory = () => {
    ApiClient.get("categories/listing", { status: "active" }).then((res) => {
      if (res.success) {
        const productCategory = res?.data?.filter(
          (item) => item?.catType == "products"
        );
        setCategory(productCategory);
      }
    });
  };

  const emailCheck = (email) => {
    let isValid = methodModel.emailvalidation(email);
    if (isValid) {
      setEmailLoader(true);
      ApiClient.get("check/Email", { email: email }).then((res) => {
        if (!res.success) {
          if (detail?.email != email) {
            setEmailErr(res.error.message);
          }
        } else {
          setEmailErr("");
        }
        setEmailLoader(false);
      });
    }
  };

  useEffect(() => {
    setSubmitted(false);
    if (id) {
      loader(true);
      ApiClient.get("product", { id }).then((res) => {
        if (res.success) {
          let value = res.data;
          setDetail(value);
          setform({
            id: value?.id,
            category_id: value?.category_id,
            mode: modeType,
            merchant_id: value?.merchant_id,
            name: value?.name,
            description: value?.description,
            price: value?.price,
          });
          setImages(value?.images);
        }
        loader(false);
      });
    }
    getCategory();
  }, [id]);

  return (
    <>
      <Html
        form={form}
        detail={detail}
        emailCheck={emailCheck}
        emailLoader={emailLoader}
        emailErr={emailErr}
        ChangeStatu={ChangeStatus}
        back={back}
        setEyes={setEyes}
        eyes={eyes}
        role={role}
        setform={setform}
        category={category}
        submitted={submitted}
        images={images}
        addressResult={addressResult}
        handleSubmit={handleSubmit}
        imageResult={imageResult}
        getError={getError}
        id={id}
        history={history}
      />
    </>
  );
};

export default AddEditProduct;
