import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiClient from "../../apimethods/apiClient";
import loader from "../../components/loader/loader";
import parse from "html-react-parser";
import Header from "../../components/global/layout/Header";
import Footer from "../../components/global/footer/Footer";

export default function CommonContentpage() {
  const { slug } = useParams();
  const [PageData, setPageData] = useState({});

  const GetData = () => {
    loader(true);
    ApiClient.get(`cms/detail?slug=${slug}`).then((res) => {
      if (res.success) {
        setPageData(res.data);
      }
      loader(false);
    });
  };

  useEffect(() => {
    GetData();
  }, []);

  return (
    <>
      <Header  />
      <div className="text-dark mt-md-5 ">
        {slug != "overview" &&
          slug != "cms" &&
          slug != "solutions" &&
          slug != "about-us" &&
          slug != "careers" && (
            <>
              <h3>{PageData?.title}</h3>
              <div className=" border p-5 text-dark">
                {parse(`${PageData?.description}`)}
              </div>
            </>
          )}

        {slug == "careers" && (
          <>
            <div className="main_div">
              <div className="career_hero ">
                <img src="/assets/img/career.png" className="career_img" />
                <div className="container d-flex justify-content-center align-items-center h-100 ">
                  <div className="career_parent">
                    {" "}
                    <h3 className="career_heading">CAREERS</h3>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="overview_div">
                  <h4 className="detaild_heading">Our Opportunity Programs</h4>
                  <p className="overview_p">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                  <p className="overview_p mt-md-4">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                </div>
                <div className="overview_div2">
                <h4 className="detaild_heading">Join Us!</h4>

                <div className="row">
                  <div className="col-md-4 mb-5">
                    <div>
                    <img src="/assets/img/join1.png" className="img-fluid" />
                    <p className="join_h">Team Manager</p>
                    <p className="join_p">03 Positions</p>
                    <button className="btn dark-btn">Apply Now</button>
                    </div>
                  </div>
                  <div className="col-md-4 mb-5">
                    <div>
                    <img src="/assets/img/join2.png" className="img-fluid" />
                    <p className="join_h">Business Analyst</p>
                    <p className="join_p">03 Positions</p>
                    <button className="btn dark-btn">Apply Now</button>
                    </div>
                  </div>
                  <div className="col-md-4 mb-5">
                    <div>
                    <img src="/assets/img/join3.png" className="img-fluid" />
                    <p className="join_h">Finance Manager</p>
                    <p className="join_p">03 Positions</p>
                    <button className="btn dark-btn">Apply Now</button>
                    </div>
                  </div>
                </div>
                </div>
                <div className="overview_div2">
                <h4 className="detaild_heading">More Brief</h4>
                <p className="overview_p">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                  <p className="overview_p mt-md-4">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                  </div>
              </div>
            </div>
          </>
        )}

        {slug == "solutions" && (
          <>
            <div className="main_div">
              <div className="solution_hero ">
                <img src="/assets/img/solution.png" className="solution_img" />
                <div className="container d-flex justify-content-center align-items-center h-100 ">
                  <div className="solution_parent">
                    {" "}
                    <h3 className="solution_heading">SOLUTIONS</h3>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="overview_div2">
                  <p className="overview_p">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                  <p className="overview_p mt-md-5">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                  <p className="overview_p mt-md-5">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                </div>
                <div className="overview_div2">
                  <div className="row reverseit">
                    <div className="col-md-6">
                      <div>
                        <img
                          src="/assets/img/info.png"
                          className=" over1_img"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h4 className="detaild_heading">Detailed Information</h4>
                      <p className="detail_desc">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                      <p className="detail_desc mt-md-4">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                      <p className="detail_desc mt-md-4">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                      <p className="detail_desc mt-md-4">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {slug == "about-us" && (
          <>
            <div className="main_div">
              <div className="about_hero ">
                <img src="/assets/img/about.png" className="about_img" />
                <div className="container d-flex justify-content-center align-items-center h-100 ">
                  <div className="about_parent">
                    {" "}
                    <h3 className="about_heading">ABOUT US</h3>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="overview_div">
                  <h4 className="detaild_heading">What We Do ?</h4>
                  <p className="overview_p">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                  <p className="overview_p mt-md-5">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                  <p className="overview_p mt-md-5">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                </div>
                <div className="overview_div">
                  <h4 className="detaild_heading mb-4">OUR TEAM</h4>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 mb-4">
                      <div className="team_card">
                        <img src="/assets/img/c1.png" className="tcard_img" />
                        <div className="team_div">
                          <div className="d-flex  align-items-center card_flex">
                            <div>
                              <p className="team_h">Bunny Colby</p>
                              <span className="teamh_desc">
                                CEO Lux Financials
                              </span>
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="14"
                              viewBox="0 0 21 14"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 6.72234C0 6.52427 0.0786828 6.33432 0.218739 6.19426C0.358795 6.0542 0.548753 5.97552 0.746822 5.97552H18.3614L13.6609 1.27652C13.5206 1.13628 13.4419 0.946085 13.4419 0.747765C13.4419 0.549446 13.5206 0.359248 13.6609 0.219015C13.8011 0.078782 13.9913 4.67257e-09 14.1896 0C14.3879 -4.67257e-09 14.5781 0.078782 14.7184 0.219015L20.6929 6.19359C20.7625 6.26297 20.8177 6.34538 20.8553 6.43611C20.893 6.52684 20.9124 6.62411 20.9124 6.72234C20.9124 6.82057 20.893 6.91784 20.8553 7.00857C20.8177 7.09931 20.7625 7.18172 20.6929 7.25109L14.7184 13.2257C14.5781 13.3659 14.3879 13.4447 14.1896 13.4447C13.9913 13.4447 13.8011 13.3659 13.6609 13.2257C13.5206 13.0854 13.4419 12.8952 13.4419 12.6969C13.4419 12.4986 13.5206 12.3084 13.6609 12.1682L18.3614 7.46916H0.746822C0.548753 7.46916 0.358795 7.39048 0.218739 7.25043C0.0786828 7.11037 0 6.92041 0 6.72234Z"
                                fill="black"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-4">
                      <div className="team_card">
                        <img src="/assets/img/c2.png" className="tcard_img" />
                        <div className="team_div">
                          <div className="d-flex  align-items-center card_flex">
                            <div>
                              <p className="team_h">Jenna Tashalo</p>
                              <span className="teamh_desc">
                                Business Analyst
                              </span>
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="14"
                              viewBox="0 0 21 14"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 6.72234C0 6.52427 0.0786828 6.33432 0.218739 6.19426C0.358795 6.0542 0.548753 5.97552 0.746822 5.97552H18.3614L13.6609 1.27652C13.5206 1.13628 13.4419 0.946085 13.4419 0.747765C13.4419 0.549446 13.5206 0.359248 13.6609 0.219015C13.8011 0.078782 13.9913 4.67257e-09 14.1896 0C14.3879 -4.67257e-09 14.5781 0.078782 14.7184 0.219015L20.6929 6.19359C20.7625 6.26297 20.8177 6.34538 20.8553 6.43611C20.893 6.52684 20.9124 6.62411 20.9124 6.72234C20.9124 6.82057 20.893 6.91784 20.8553 7.00857C20.8177 7.09931 20.7625 7.18172 20.6929 7.25109L14.7184 13.2257C14.5781 13.3659 14.3879 13.4447 14.1896 13.4447C13.9913 13.4447 13.8011 13.3659 13.6609 13.2257C13.5206 13.0854 13.4419 12.8952 13.4419 12.6969C13.4419 12.4986 13.5206 12.3084 13.6609 12.1682L18.3614 7.46916H0.746822C0.548753 7.46916 0.358795 7.39048 0.218739 7.25043C0.0786828 7.11037 0 6.92041 0 6.72234Z"
                                fill="black"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-4">
                      <div className="team_card">
                        <img src="/assets/img/c3.png" className="tcard_img" />
                        <div className="team_div">
                          <div className="d-flex align-items-center card_flex">
                            <div>
                              <p className="team_h">Aksara Joshi</p>
                              <span className="teamh_desc">Team Leader</span>
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="14"
                              viewBox="0 0 21 14"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 6.72234C0 6.52427 0.0786828 6.33432 0.218739 6.19426C0.358795 6.0542 0.548753 5.97552 0.746822 5.97552H18.3614L13.6609 1.27652C13.5206 1.13628 13.4419 0.946085 13.4419 0.747765C13.4419 0.549446 13.5206 0.359248 13.6609 0.219015C13.8011 0.078782 13.9913 4.67257e-09 14.1896 0C14.3879 -4.67257e-09 14.5781 0.078782 14.7184 0.219015L20.6929 6.19359C20.7625 6.26297 20.8177 6.34538 20.8553 6.43611C20.893 6.52684 20.9124 6.62411 20.9124 6.72234C20.9124 6.82057 20.893 6.91784 20.8553 7.00857C20.8177 7.09931 20.7625 7.18172 20.6929 7.25109L14.7184 13.2257C14.5781 13.3659 14.3879 13.4447 14.1896 13.4447C13.9913 13.4447 13.8011 13.3659 13.6609 13.2257C13.5206 13.0854 13.4419 12.8952 13.4419 12.6969C13.4419 12.4986 13.5206 12.3084 13.6609 12.1682L18.3614 7.46916H0.746822C0.548753 7.46916 0.358795 7.39048 0.218739 7.25043C0.0786828 7.11037 0 6.92041 0 6.72234Z"
                                fill="black"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-4">
                      <div className="team_card">
                        <img src="/assets/img/c4.png" className="tcard_img" />
                        <div className="team_div">
                          <div className="d-flex  align-items-center card_flex">
                            <div>
                              <p className="team_h">Steve John</p>
                              <span className="teamh_desc">Marketing</span>
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="14"
                              viewBox="0 0 21 14"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 6.72234C0 6.52427 0.0786828 6.33432 0.218739 6.19426C0.358795 6.0542 0.548753 5.97552 0.746822 5.97552H18.3614L13.6609 1.27652C13.5206 1.13628 13.4419 0.946085 13.4419 0.747765C13.4419 0.549446 13.5206 0.359248 13.6609 0.219015C13.8011 0.078782 13.9913 4.67257e-09 14.1896 0C14.3879 -4.67257e-09 14.5781 0.078782 14.7184 0.219015L20.6929 6.19359C20.7625 6.26297 20.8177 6.34538 20.8553 6.43611C20.893 6.52684 20.9124 6.62411 20.9124 6.72234C20.9124 6.82057 20.893 6.91784 20.8553 7.00857C20.8177 7.09931 20.7625 7.18172 20.6929 7.25109L14.7184 13.2257C14.5781 13.3659 14.3879 13.4447 14.1896 13.4447C13.9913 13.4447 13.8011 13.3659 13.6609 13.2257C13.5206 13.0854 13.4419 12.8952 13.4419 12.6969C13.4419 12.4986 13.5206 12.3084 13.6609 12.1682L18.3614 7.46916H0.746822C0.548753 7.46916 0.358795 7.39048 0.218739 7.25043C0.0786828 7.11037 0 6.92041 0 6.72234Z"
                                fill="black"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="overview_div2">
                  <h4 className="detaild_heading mb-md-4">OUR TEAM</h4>
                  <p className="overview_p">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                  <p className="overview_p mt-md-4">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                </div>

                <div className="overview_div">
                  <h3 className="anywhere mb-md-5">
                    Sell Anywhere easily, <br></br>
                    quickly & seamlessly.
                  </h3>
                  <div className="row">
                    <div className="col-md-4 mb-4">
                      <div>
                        <img src="/assets/img/any1.png" className="img-fluid" />
                      </div>
                    </div>
                    <div className="col-md-4 mb-4">
                      <div>
                        <img src="/assets/img/any2.png" className="img-fluid" />
                      </div>
                    </div>
                    <div className="col-md-4 mb-4">
                      <div>
                        <img src="/assets/img/any3.png" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {slug == "cms" && (
          <>
            <div className="main_div">
              <div className="features_hero ">
                <img src="/assets/img/feature.png" className="features_img" />
                <div className="container d-flex justify-content-center align-items-center h-100 ">
                  <div className="feature_parent">
                    {" "}
                    <h3 className="feature_heading">FEATURES</h3>
                  </div>
                </div>
              </div>

              <div className="overview_div">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="text-right">
                        {" "}
                        <h4 className="pre_heading">Our Potential</h4>
                        <p className="overview_p">
                          It is a long established fact that a reader will be
                          distracted by the readable content of a page when
                          looking at its layout. The point of using Lorem Ipsum
                          is that it has a more-or-less normal distribution of
                          letters, as opposed to using 'Content here, content
                          here', making it look like readable English. Many
                          desktop publishing packages and web page editors now
                          use Lorem Ipsum as their default model text, and a
                          searcIt is a long established fact that a reader will
                          be distracted by the readable content of a page when
                          looking at its layout.
                        </p>
                        <button className="btn dark-btn mt-2">
                          Get Started
                        </button>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-right">
                        <img
                          src="/assets/img/pre1.png"
                          className=" over1_img"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="overview_div2">
                    <p className="overview_p">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here, content here', making it
                      look like readable English. Many desktop publishing
                      packages and web page editors now use Lorem Ipsum as their
                      default model text, and a search for 'lorem ipsum' will
                      uncover many web sites still in their infancy. Various
                      versions have evolved over the years, sometimes by
                      accident, sometimes on purpose (injected humour and the
                      like).
                    </p>
                    <p className="overview_p mt-md-5">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here, content here', making it
                      look like readable English. Many desktop publishing
                      packages and web page editors now use Lorem Ipsum as their
                      default model text, and a search for 'lorem ipsum' will
                      uncover many web sites still in their infancy. Various
                      versions have evolved over the years, sometimes by
                      accident, sometimes on purpose (injected humour and the
                      like).
                    </p>
                  </div>

                  <div className="overview_div2">
                    <div className="row reverseit">
                      <div className="col-md-6">
                        <div>
                          <img
                            src="/assets/img/secure.png"
                            className=" over1_img"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-md-5">
                        <h4 className="pre_heading">
                          100% Secure Transactions
                        </h4>
                        <p className="overview_p">
                          It is a long established fact that a reader will be
                          distracted by the readable content of a page when
                          looking at its layout. The point of using Lorem Ipsum
                          is that it has a more-or-less normal distribution of
                          letters, as opposed to using 'Content here, content
                          here', making it look like readable English. Many
                          desktop publishing packages and web page editors now
                          use Lorem Ipsum as their default model text, and a
                          searcIt is a long established fact that a reader will
                          be distracted by the readable content of a page when
                          looking at its layout.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {slug == "overview" && (
          <>
            <div className="main_div">
              <div className="overview_hero relative">
                <img src="/assets/img/overview.png" className="overview_img" />
                <div className="container d-flex justify-content-center align-items-center h-100 ">
                  <div className="over_parent">
                    {" "}
                    <h3 className="overview_heading">OVERVIEW</h3>
                  </div>
                </div>
              </div>
              <div className="overview_div">
                <div className="container">
                  <p className="overview_p">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                  <p className="overview_p mt-md-5">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                </div>
              </div>

              <div className="overview_div2">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="text-right">
                        {" "}
                        <h4 className="desc_heading">Our Potential</h4>
                        <p className="overview_p">
                          It is a long established fact that a reader will be
                          distracted by the readable content of a page when
                          looking at its layout. The point of using Lorem Ipsum
                          is that it has a more-or-less normal distribution of
                          letters, as opposed to using 'Content here, content
                          here', making it look like readable English. Many
                          desktop publishing packages and web page editors now
                          use Lorem Ipsum as their default model text, and a
                          search for 'lorem ipsum' will uncover many web sites
                          still in like).
                        </p>
                      </div>{" "}
                    </div>
                    <div className="col-md-6">
                      <div className="text-right">
                        <img
                          src="/assets/img/over1.png"
                          className=" over1_img"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row overview_div2 reverseit">
                    <div className="col-md-6">
                      <div>
                        <img
                          src="/assets/img/over2.png"
                          className=" over1_img"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-left">
                        {" "}
                        <h4 className="desc_heading">Our Range</h4>
                        <p className="overview_p">
                          It is a long established fact that a reader will be
                          distracted by the readable content of a page when
                          looking at its layout. The point of using Lorem Ipsum
                          is that it has a more-or-less normal distribution of
                          letters, as opposed to using 'Content here, content
                          here', making it look like readable English. Many
                          desktop publishing packages and web page editors now
                          use Lorem Ipsum as their default model text, and a
                          search for 'lorem ipsum' will uncover many web sites
                          still in like).
                        </p>
                      </div>{" "}
                    </div>
                  </div>

                  <div className="overview_div2">
                    <p className="overview_p">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here, content here', making it
                      look like readable English. Many desktop publishing
                      packages and web page editors now use Lorem Ipsum as their
                      default model text, and a search for 'lorem ipsum' will
                      uncover many web sites still in their infancy. Various
                      versions have evolved over the years, sometimes by
                      accident, sometimes on purpose (injected humour and the
                      like).
                    </p>
                    <p className="overview_p mt-md-5">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here, content here', making it
                      look like readable English. Many desktop publishing
                      packages and web page editors now use Lorem Ipsum as their
                      default model text, and a search for 'lorem ipsum' will
                      uncover many web sites still in their infancy. Various
                      versions have evolved over the years, sometimes by
                      accident, sometimes on purpose (injected humour and the
                      like).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}
