import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import Slider from "react-slick";
import methodModel from "../../../components/validations/Validations";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/global/layout/Header";
import Footer from "../../../components/global/footer/Footer";
import ApiClient from "../../../apimethods/apiClient";
import { toast } from "react-toastify";
import loader from "../../../components/loader/loader";
import { login_success } from "../../../redux/actions/user";

const Html = ({
  productdata,
  addTofav,
  slug,
  addTocart,
  setQuantity,
  quantity,
  CartApplied,
}) => {
  const [ImageCurrentIndex, setImageCurrentIndex] = useState(0);
  const [ModalImg, setModalImg] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [username, setUsername] = useState("");
  const [remember, setRemember] = useState(false);
  const [password, setPassword] = useState("");
  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });
  const navigate = useNavigate();

  const hendleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: username,
      password,
    };

    loader(true);

    ApiClient.post("user/login", data).then((res) => {
      loader(false);
      if (res.success) {
        if (remember) {
          localStorage.setItem("remember", JSON.stringify(data));
        } else {
          localStorage.removeItem("remember");
        }
        if(slug){
          localStorage.setItem("merSlug",slug); 
        }
        toast.success(res.message);
        localStorage.setItem("token", res.data.access_token);
        dispatch(login_success(res.data));
        localStorage.setItem("userid",res?.data?._id)
        const newdata = res.data;
        document.getElementById("closemodal").click()
        navigate("/dashboard");
      }
    });
  };

  useEffect(() => {
    setModalImg(productdata?.images?.[0] || "");
  }, [productdata?.images]);

  // slider product
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [index1, setIndex] = useState("");
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const options = [];
  for (let i = 1; i <= quantity.max; i++) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  const PreviousNexthandler = (type = "next") => {
    let Index = ImageCurrentIndex;
    if (type == "next") {
      Index = Index + 1;
      if (Index == productdata?.images?.length) {
        setImageCurrentIndex(0);
        setModalImg(productdata?.images[0]);
      } else {
        setImageCurrentIndex(Index);
        setModalImg(productdata?.images[Index]);
      }
    } else {
      Index = Index - 1;
      if (Index < 0) {
        setImageCurrentIndex(productdata?.images?.length - 1);
        setModalImg(productdata?.images[productdata?.images?.length - 1]);
      } else {
        setImageCurrentIndex(Index);
        setModalImg(productdata?.images[Index]);
      }
    }
  };

  const navigateRoute = !slug || !localStorage.getItem('merSlug') ? `/signup/guest` : `/signup/guest/${slug || localStorage.getItem('merSlug')}`

  return (
    <>
      <Header />
      <div className="top-padding">
        <div className="container comman_padding">
          <div className="row">
            <div className="col-12 col-md-5 col-lg-5 col-xl-5 ">
              <div className="sticky_product_img">
                <div>
                  {productdata?.images?.length > 0 ? (
                    <Slider
                      asNavFor={nav2}
                      ref={slider1}
                      autoplay={true}
                      speed={2000}
                      autoplaySpeed={2000}
                      cssEase={"fade"}
                      arrows={false}
                      infinite={false}
                    >
                      {productdata?.images?.map((imageUrl, index) => (
                        <div className="prod_ct" key={index}>
                          <img
                            src={methodModel.noImg(imageUrl)}
                            onClick={(e) => setModalImg(imageUrl)}
                            data-toggle="modal"
                            data-target="#exampleModalCenter1"
                            className="product_slider"
                            alt={`Product ${index + 1}`}
                          />
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <img
                      src="/assets/img/no-image.png"
                      data-toggle="modal"
                      data-target="#exampleModalCenter1"
                      className="product_slider"
                    />
                  )}

                  <Slider
                    asNavFor={nav1}
                    ref={slider2}
                    slidesToShow={4}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    arrows={false}
                    infinite={false}
                  >
                    {productdata?.images?.map((imageUrl, index) => (
                      <div key={index}>
                        <img
                          src={methodModel.noImg(imageUrl)}
                          className={
                            index1 == index
                              ? "thumbail_data thumbail_active"
                              : "thumbail_data thumbail_active "
                          }
                          alt={`Product ${index + 1}`}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-7 col-lg-7 col-xl-7">
              <div className="d-flex flex-column text-left">
                <div className="">
                  <h3 className="product_name text-capitalize mb-0">
                    {productdata?.name}
                  </h3>
                  {localStorage.getItem('token') && <>{!productdata?.inWishlist ? <div className=''>
                    <i className='fa fa-heart' onClick={e => addTofav(productdata?._id)}></i>
                  </div> : <div className='heart_icon'>
                    <i className='fa fa-heart' onClick={e => addTofav(productdata?._id)}></i>
                  </div>}</>}
                </div>

                <div className="stars_wrapper my-2">
                  <div className="d-flex align-items-center gap-2">
                    <div className="star_icons d-flex gap-2">
                      <i className="fa fa-star yellowstar"></i>
                      <i className="fa fa-star yellowstar"></i>
                      <i className="fa fa-star yellowstar"></i>
                      <i class="fa fa-star yellowstar" aria-hidden="true"></i>
                      <i class="fa fa-star yellowstar" aria-hidden="true"></i>
                    </div>
                    <span className="product_reviews">(15 Reviews)</span>
                  </div>
                </div>

                <div className="mt-2 ">
                  <p className="stor_name mb-0">
                    Merchant: &nbsp;
                    {methodModel.capitalizeFirstLetter(
                      productdata?.merchant_id?.fullName
                    )}
                  </p>
                  <p className="stor_name mb-0 d-flex">
                    Rating : 
             <div className="">
             <span className="dropbtn dropdown_one pointer">
                      <ul className="dropdown-content p-0">
                        {productdata?.get_badges_details ? (
                          <div className="itemsdrop_badge">
                            <div className="d-flex align-items-center">
                              <img
                                src={methodModel.userImg(
                                  productdata?.get_badges_details?.image
                                )}
                                className="badge_img"
                              />
                              <div className="ml-2">
                                <p className="ites_head ">
                                  {" "}
                                  {productdata?.get_badges_details?.name}
                                </p>
                                <p className="ites_head">
                                  {" "}
                                  {productdata?.get_badges_details?.score}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center">
                            {" "}
                            <span>No Rating</span>
                          </div>
                        )}
                      </ul>
                    </span>{" "}
                    <i className="fa fa-star"></i> 4.3{" "}
             </div>
                  </p>
                </div>

                <div className="mt-2 d-flex align-items-center gap-2">
                  <label htmlFor="quantity" className="mb-0">Quantity: </label>
                  <div className="drop_qulity mr-3 qty-width">
                    <span>
                      <select
                        id="quantity"
                        className=" qty_select"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      >
                        {[1, 2, 3, 4, 5].map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </span>
                  </div>
                </div>

                <div className="price_sect padding_items mr-2">
                  <div className="d-flex align-items-center ">
                    <h4 className="product_price mb-0">
                      $ {productdata?.price}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="addtocard_wrppaer padding_items">
                <div className="row align-items-center">
                  <div className="col-12">
                    {productdata?.merchant_id?._id != user._id ? (
                      <div className="d-flex align-items-center buttons_all_w150">
                        <button
                          className="btn btn-primary btnblack br50 mr-3"
                          onClick={(e) => addTocart(productdata?._id)}             
                        >
                          {" "}
                          <i className="fa fa-shopping-cart"></i>{" "}
                          {CartApplied ? "Added" : "Add"} to Cart
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="comman_padding pt-0">
            <div className="container">
              
              <hr className="product_hr"></hr>

              <div className="products">

              <ul class="nav nav-pills" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link active" id="home-tab" data-toggle="tab" data-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Description</button>
                    </li>
              </ul>


               
                <hr className="product_hr"></hr>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                  <div className="fetaurse_specfition">
                      <div className="d-flex align-items-center">
                        {/* <div className="bar_div"> </div> */}
                        {/* <h5 className="offers_heading ml-4"> */}
                          {" "}
                          <p
                                className="tabs-desc-dtls text-capitalize img_for_desc"
                                dangerouslySetInnerHTML={{
                                  __html: productdata?.description
                                    ? productdata?.description
                                    : "Description....",
                                }}
                              ></p>
                        {/* </h5> */}
                      </div>
                    </div>
  </div>
  
                </div>
              </div>
            </div>
            <hr className="product_hr"></hr>
          </section>

          <button
            type="button"
            id="loginModal1"
            className="btn btn-primary d-none"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            modal
          </button>

          <div
            className="modal fade login-modal"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered  ">
              <div className="modal-content rounded-4">
                <div className="logn_modal">
                  <div className="row">
                    <div className="col-12">
                      <div className="text-center modal_bx">
                        <h6>
                          {" "}
                      <img src="/assets/img/img/logo.png" className="alert_img" />
                          <button
                            type="button"
                            className="btn-close p-0"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closemodal"
                          ></button>
                        </h6>
                        <p className="countp">You must Login or Sign Up first to continue</p>
                        <div className="row mx-4 signmod">
                          <div className="col-12 text-left mb-3">
                          <label className="mb-1 ml-3"> Email <span class="red">*</span></label>
                          <input type="email" 
                          class="form-control modinput" 
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          required="" ></input>
                          </div>
                          <div className="col-12 text-left mb-3">
                          <label className="mb-1 ml-3"> Password <span class="red">*</span></label>
                          <div className="inputWrapper">
                          <input 
                           type={eyes.password ? "text" : "password"}
                          class="form-control modinput" 
                           value={password}
                           onChange={(e) => setPassword(e.target.value)}
                          required="" ></input>
                            <i
                              className={
                                eyes.password ? "fa fa-eye" : "fa fa-eye-slash"
                              }
                              onClick={() =>
                                setEyes({ ...eyes, password: !eyes.password })
                              }
                            ></i>
                            </div>
                          </div>
                          <div className="col-12 mt-2">
                          <button class="btn btn-primary w-100" onClick={hendleSubmit}> Login</button>
                          </div>
                          <div className="col-12 mt-3">
                          <p className="signp">Don’t have an account? <span className="signspan pl-1" onClick={(e) => {document.getElementById("closemodal").click(); navigate(`/signup/customer/${slug || localStorage.getItem('merSlug')}`)}}>Sign Up </span>
                          <p className="m-0"> Or </p>
                          <p className="signspan pl-1" onClick={(e) =>{document.getElementById("closemodal").click(); navigate(navigateRoute)}}>Continue as a Guest</p>
                          </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  IMAGE NAVIGATION SLIDER CUSTOM MADE */}

          <div
            className="modal fade"
            id="exampleModalCenter1"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content modal-lg">
                <button
                  type="button"
                  style={{ float: "right" }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span
                    className="mt-2 mr-2"
                    style={{ float: "right" }}
                    aria-hidden="true"
                  >
                    &times;
                  </span>
                </button>
                <div className="modal-body">
                  <div className="">
                    <div className="row">
                      <div className="col-md-1  mt-5">
                        <button
                          className="border-0 bg-transparent ml-2"
                          style={{ marginTop: "54px" }}
                          onClick={(e) => PreviousNexthandler("previous")}
                        >
                          <i className="fa fa-arrow-left"></i>
                        </button>
                      </div>
                      <div className="col-md-10">
                        <img
                          className="provtdels_img"
                          src={methodModel.noImg(ModalImg)}
                        />
                        <p className="text-center">
                          {ImageCurrentIndex + 1} of{" "}
                          {productdata?.images?.length}
                        </p>
                      </div>
                      <div className="col-md-1 mt-5">
                        <button
                          style={{ marginTop: "54px", marginLeft: "-17px" }}
                          className="border-0 bg-transparent mr-5"
                          onClick={(e) => PreviousNexthandler("next")}
                        >
                          {" "}
                          <i className="fa fa-arrow-right"></i>
                        </button>
                      </div>
                    </div>
                    <div>
                      {productdata?.images?.map((item, index) => (
                        <img
                          src={methodModel.noImg(item)}
                          style={{ border: "2px solid white" }}
                          className={` ml-2 ${
                            ModalImg == item ? "border-primary" : ""
                          }`}
                          width="70px"
                          height="60px"
                          onClick={(e) => {
                            setModalImg(item);
                            setImageCurrentIndex(index);
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                {/* <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <Footer />

        <div
          className="modal fade drawer right-align"
          id="exampleModalRight"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <span className="mr-2">
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20 9V11H11V16C11 17.6569 9.65685 19 8 19C6.34315 19 5 17.6569 5 16V15H7V16C7 16.5523 7.44772 17 8 17C8.55228 17 9 16.5523 9 16V11H0V9C0 4.02944 4.02944 0 9 0H11C15.9706 0 20 4.02944 20 9ZM7.92953 6.02624C7.64656 7.06378 7.43746 8.11346 7.28885 9H12.7111C12.5625 8.11346 12.3534 7.06378 12.0705 6.02624C11.7151 4.72303 11.2784 3.57343 10.7814 2.7925C10.537 2.4084 10.3275 2.19325 10.1806 2.08547C10.0426 1.98431 9.957 1.98459 9.81945 2.08547C9.67247 2.19325 9.463 2.4084 9.21857 2.7925C8.72161 3.57343 8.28495 4.72303 7.92953 6.02624ZM5.2624 9C5.56629 7.08723 6.16774 4.20828 7.23596 2.22416C4.22394 3.00611 2 5.74334 2 9H5.2624ZM12.764 2.22416C13.8323 4.20828 14.4337 7.08723 14.7376 9H18C18 5.74334 15.7761 3.00611 12.764 2.22416Z"
                        fill="#FE1251"
                      ></path>
                    </svg>
                  </span>
                  Lentify Care damage coverage
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <h5 className="dtext">
                    Enjoy your tech worry-free by adding Lentify Care damage
                    coverage to your rental. Lentify Care covers up to 90% of
                    damages for all rental categories except drones and
                    e-mobility, which are covered up to 50%. Normal signs of use
                    and device errors are completely covered.
                  </h5>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Html;
