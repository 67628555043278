import React, { useEffect, useState } from "react";
import "./style.scss";
//import Header from "../../components/global/header"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../apimethods/apiClient";
import WebsiteHeader from "../../components/global/websiteheader/WebsiteHeader";

const ContactUs = () => {
  const websitedetails = useSelector(state => state.website);
  const [cms, setcms] = useState()
  const history = useNavigate()

  useEffect(() => {
    getCMS()
  }, [])

  const getCMS = () => {
    ApiClient.get(`cms/listing`).then(res => {
      if (res.success) {
        setcms(res.data)
      }
    })
  }

  return (
    <>

      <WebsiteHeader />
      <section className="contact-us-page">
      <div className="features_hero ">
                <img src="/assets/img/contact-us.webp" className="features_img" />
                <div className="container d-flex justify-content-center align-items-center h-100 ">
                  <div className="feature_parent flex-column w-25 text-center border-0">
                    {" "}
                    <h3 className="feature_heading">Contact US</h3>
                    <p className="text-white">Please fill the details</p>
                  </div>
                </div>
              </div>
        {/* <div className="contact-us">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="contact-us-bg">
                  <h2>Contact US</h2>
                  <p>Please fill the details</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="address-fields">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 mb-3">
                <div className="address-inner">
                  <div className="address-icon">
                    <img src="/assets/img/phone.svg" />
                  </div>
                  <div>
                    <h3> Call Us</h3>
                    <p>0172 5087346</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-3">
                <div className="address-inner">
                  <div className="address-icon">
                    <img src="/assets/img/loc.svg" />
                  </div>
                  <div>
                    <h3> Address</h3>
                    <p>Lorem ipsum</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-3">
                <div className="address-inner">
                  <div className="address-icon">
                    <img src="/assets/img/mail.svg" />
                  </div>
                  <div>
                    <h3> Email</h3>
                    <p>abc@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-us-title">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="contact-us-title-inner">
                  <h4>HAVE QUESTION? WRITE A MESSAGE</h4>
                  <p>We will catch you as early as we receive the message</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-us-inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <form action="#" method="post">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label for="name">Name:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label for="email">Email:</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label for="number">Phone No:</label>
                        <input
                          type="number"
                          id="number"
                          name="number"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label for="text">Subject</label>
                        <input
                          type="text"
                          id="text"
                          name="text"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group">
                        <label for="message">Message:</label>
                        <textarea
                          id="message"
                          name="message"
                          rows="4"
                          className="form-control"
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <button type="submit" className="btn dark-btn">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="footer-section">
        <div className="footer-upper">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="footer-title">
                  <h3>Contact information</h3>
                  <p>
                    Rhoncus quam dolor enim egestas cras. Senectusquis lectus
                    pharetra diam sociis etiam magna
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="footer-address">
                  <ul>
                    <li>
                      <a href="#">
                        <img src="/assets/img/mail.svg" alt="mail" />
                        <p>{websitedetails?.website_email}</p>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="/assets/img/phone.svg" alt="phone" />
                        <p>{websitedetails?.company_dial_code}{websitedetails?.company_mobile_no}</p>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="/assets/img/loc.svg" alt="location" />
                        <p>{websitedetails?.company_address}</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="footer-feature">
                  <h5>Product</h5>
                  <ul>
                    {cms && cms.map(item => {
                      if (item?.categoryDetail?.name == 'Product' && item?.categoryDetail?.catType == 'page') {
                        return <li className="pointer" onClick={e => { history(`/cms/${item?.slug}`) }} >{item?.title}</li>
                      }
                    })}
                  </ul>
                </div>
              </div>
              <div className="col">
                <div className="footer-feature">
                  <h5>Company</h5>
                  <ul>
                    {cms && cms.map(item => {
                      if (item?.categoryDetail?.name == 'Company' && item?.categoryDetail?.catType == 'page') {
                        return <li className="pointer" onClick={e => { history(`/cms/${item?.slug}`) }} >{item?.title}</li>
                      }
                    })}
                  </ul>
                </div>
              </div>
              <div className="col">
                <div className="footer-feature">
                  <h5>Resources</h5>
                  <ul>
                    {cms && cms.map(item => {
                      if (item?.categoryDetail?.name == 'Resources' && item?.categoryDetail?.catType == 'page') {
                        return <li className="pointer" onClick={e => { history(`/cms/${item?.slug}`) }} >{item?.title}</li>
                      }
                    })}
                  </ul>
                </div>
              </div>
              <div className="col">
                <div className="footer-feature">
                  <h5>Social</h5>
                  <ul>
                    <li className="pointer" onClick={e => { window.open(`https://twitter.com/i/flow/login?input_flow_data=%7B%22requested_variant%22%3A%22eyJsYW5nIjoiZW4ifQ%3D%3D%22%7D`) }}>Twitter</li>
                    <li className="pointer" onClick={e => { window.open(`https://www.linkedin.com/login?fromSignIn=true&trk=guest_homepage-basic_nav-header-signin`) }}>LinkedIn</li>
                    <li className="pointer" onClick={e => { window.open(`https://www.facebook.com/`) }}>Facebook</li>
                    <li className="pointer" onClick={e => { window.open(`https://github.com/login`) }}>GitHub</li>
                  </ul>
                </div>
              </div>
              <div className="col">
                <div className="footer-feature">
                  <h5>Legal</h5>
                  <ul>
                    {cms && cms.map(item => {
                      if (item?.categoryDetail?.name == 'Legal' && item?.categoryDetail?.catType == 'page') {
                        return <li className="pointer" onClick={e => { history(`/cms/${item?.slug}`) }}>{item?.title}</li>
                      }
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p className="mb-0 text-center pt-3 pb-2" dangerouslySetInnerHTML={{ __html: websitedetails?.footer_info }}></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ContactUs;
