import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import methodModel from "../../components/validations/Validations";
import datepipeModel from "../../models/datepipemodel";
import { useSelector } from "react-redux";
import ApiClient from "../../apimethods/apiClient";
import { toast } from "react-toastify";
import loader from "../../components/loader/loader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Html = ({
  edit,
  tab,
  statusChange,
  pageChange,
  ChangeStatus,
  filters,
  loaging,
  data,
  history,
  sorting,
  handleDownload,
  total = { total },
}) => {
  const user = useSelector((state) => state.user);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [filter1, setFilter] = useState({
    merchant_id: user?._id,
    page: 1,
    count: 20,
    search: "",
    isDeleted: false,
  });
  const [data1, setData1] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [Customer, setCustomerData] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [custData, setCustData] = useState();
  const [customerType, setCustomerType] = useState("existing"); // 'existing' or 'new'
  const [newCustomerData, setNewCustomerData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    dialCode: "+1",
    mobileNo: "",
  });
  const [errors, setErrors] = useState({});

  const handleCustomerTypeChange = (type) => {
    setCustomerType(type);
  };

  const handleCustomerDataChange = (e) => {
    const { name, value } = e.target;

    const newErrors = { ...errors };
    if (name === "email") {
      if (!validateEmail(value)) {
        newErrors.email = "Please enter a valid email address";
      } else {
        delete newErrors.email;
      }
    } else if (name === "mobileNo") {
      if (!validatePhoneNumber(value)) {
        newErrors.mobileNo = "Please enter a valid phone number";
      } else {
        delete newErrors.mobileNo;
      }
    }
    setErrors(newErrors);

    setNewCustomerData({ ...newCustomerData, [name]: value });
  };

  const handleAddItem = () => {
    if (customerType == "existing") {
      if (!selectedCustomerId) {
        toast?.error("Please select the customer ");
      } else if (!selectedItemId) {
        toast?.error("Please select the product ");
      } else {
        const selectedItem = data1.find((item) => item.id === selectedItemId);
        const selectedCustomer = Customer.find(
          (customer) => customer._id === selectedCustomerId
        );
        if ((selectedItem && selectedCustomer) || newCustomerData) {
          const customerData = {
            email: selectedCustomer?.email || newCustomerData?.email,
            firstName:
              selectedCustomer?.fullName.split(" ")[0] ||
              newCustomerData?.firstName,
            lastName:
              selectedCustomer?.fullName.split(" ")[1] ||
              newCustomerData?.lastName,
            dialCode: selectedCustomer?.dialCode || newCustomerData?.dialCode,
            mobileNo: selectedCustomer?.mobileNo || newCustomerData?.mobileNo,
          };
          if (!validateEmail(customerData.email)) {
            toast?.error("Email id is invalid");
          } else if (customerData?.mobileNo) {
            if (!validatePhoneNumber(customerData.mobileNo)) {
              toast?.error("Mobile number is invalid");
            }
          } else {
            setCustData(customerData);

            const newItem = {
              customer_data: customerData,
              product_id: selectedItemId,
              name: selectedItem.name,
              qty: quantity,
            };
            setSelectedItems([...selectedItems, newItem]);
            setSelectedItemId("");
            setQuantity(1);
          }
        }
      }
    } else {
      if (
        !newCustomerData?.email ||
        !newCustomerData?.firstName ||
        !newCustomerData?.lastName ||
        !newCustomerData?.dialCode ||
        !newCustomerData?.mobileNo
      ) {
        toast?.error("Customer name,email and phone no are required");
      } else if (!selectedItemId) {
        toast?.error("Please select the product");
      } else {
        const selectedItem = data1.find((item) => item.id === selectedItemId);
        const selectedCustomer = Customer.find(
          (customer) => customer._id === selectedCustomerId
        );
        if ((selectedItem && selectedCustomer) || newCustomerData) {
          const customerData = {
            email: selectedCustomer?.email || newCustomerData?.email,
            firstName:
              selectedCustomer?.fullName.split(" ")[0] ||
              newCustomerData?.firstName,
            lastName:
              selectedCustomer?.fullName.split(" ")[1] ||
              newCustomerData?.lastName,
            dialCode: selectedCustomer?.dialCode || newCustomerData?.dialCode,
            mobileNo: selectedCustomer?.mobileNo || newCustomerData?.mobileNo,
          };
          if (!validateEmail(customerData.email)) {
            toast?.error("Email id is invalid");
          } else if (!validatePhoneNumber(customerData.mobileNo)) {
            toast?.error("Mobile number is invalid");
          } else {
            setCustData(customerData);
            const newItem = {
              customer_data: customerData,
              product_id: selectedItemId,
              name: selectedItem.name,
              qty: quantity,
            };
            setSelectedItems([...selectedItems, newItem]);
            setSelectedItemId("");
            setQuantity(1);
          }
        }
      }
    }
  };
  const getCustomerData = (p = {}) => {
    let filter = { ...filter1, ...p, merchant_id: user?._id };
    let url = "merchant/transaction/customers";
    ApiClient.get(url, filter).then((res) => {
      if (res.success) {
        const data = res.data;
        setCustomerData(data);
      }
    });
  };

  const toggleConfirmPasswordModal = () => {
    setShowOrderModal(!showOrderModal);
  };

  const getData = (p = {}) => {
    let filter = { ...filter1, ...p, merchant_id: user?._id };
    let url = "product/all";
    if (filter.role) url = "product/all";
    ApiClient.get(url, filter).then((res) => {
      if (res.success) {
        const data = res.data;
        setData1(data);
      }
    });
  };

  useEffect(() => {
    if (user?.id) {
      getData();
      getCustomerData();
    }
  }, []);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhoneNumber = (phone) => {
    const regex = /^\d{10}$/;
    return regex.test(phone);
  };

  const handleSubmit = () => {
    if (selectedItems && selectedItems?.length == 0) {
      toast?.error("Please add the item before place the order");
    } else {
      const itemsData = selectedItems.map((item) => ({
        product_id: item.product_id,
        qty: item.qty,
      }));

      const payload = {
        customer_data: custData,
        items: itemsData,
      };
      loader(true);
      ApiClient.post("/order/merchant-for-cust", payload).then((res) => {
        if (res?.success) {
          toast.success(res?.message);
          setShowOrderModal(!showOrderModal);
          setSelectedCustomerId("");
          setNewCustomerData({});
          setCustomerType("existing");
        }
        loader(false);
      });
      setSelectedItems([]);
    }
  };

  const removeSelectedItem = (indexToRemove) => {
    setSelectedItems((prevItems) => {
      return prevItems.filter((item, index) => index !== indexToRemove);
    });
  };

  return (
    <Layout>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="hedding">Orders</h3>
        <div className="dropdown addDropdown">
          <button
            className="btn btn-primary dropdown-toggle removeBg"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {!filters.status
              ? "All Status"
              : filters.status == "awaiting_payment"
              ? "Awaiting Payment"
              : filters.status == "pending"
              ? "Pending"
              : filters.status == "payment_failed"
              ? "Failed"
              : "Delivered"}
          </button>
          <div
            className="dropdown-menu shadow bg_hover"
            aria-labelledby="dropdownMenuButton"
          >
            <a
              className={
                filters.status == "" ? "dropdown-item active" : "dropdown-item"
              }
              onClick={() => ChangeStatus("")}
            >
              All Status
            </a>
            <a
              className={
                filters.status == "awaiting_payment"
                  ? "dropdown-item active"
                  : "dropdown-item"
              }
              onClick={() => ChangeStatus("awaiting_payment")}
            >
              Awaiting Payment
            </a>
            <a
              className={
                filters.status == "pending"
                  ? "dropdown-item active"
                  : "dropdown-item"
              }
              onClick={() => ChangeStatus("pending")}
            >
              Pending
            </a>
            <a
              className={
                filters.status == "delivered"
                  ? "dropdown-item active"
                  : "dropdown-item"
              }
              onClick={() => ChangeStatus("delivered")}
            >
              Delivered
            </a>
            <a
              className={
                filters.status == "payment_failed"
                  ? "dropdown-item active"
                  : "dropdown-item"
              }
              onClick={() => ChangeStatus("payment_failed")}
            >
              Failed
            </a>
          </div>
          {/* {user?.role_name == "Merchant" && (
            <button
              className="btn btn-primary ml-3"
              type="button"
              onClick={() => {
                setShowOrderModal(!showOrderModal);
                getData();
                getCustomerData();
              }}
            >
              Place Order
            </button>
          )} */}
        </div>
      </div>

      {tab == "grid" ? (
        <>
          <div className="cardList">
            <div className="row">
              {!loaging &&
                data &&
                data.map((itm, i) => {
                  return (
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                      <div className="new_cards">
                        <div className="user_card">
                          <div
                            className="user_detail"
                            onClick={(e) => edit(itm.id)}
                          >
                            <img
                              src={methodModel.userImg(itm.image)}
                              className="user_imgs"
                            />

                            <div className="user_name">
                              <h4 className="user ">{itm.fullName}</h4>
                              <p className="user_info">{itm.email}</p>
                            </div>
                          </div>

                          <div
                            className={`user_hours ${itm.status}`}
                            onClick={() => statusChange(itm)}
                          >
                            <span className="contract px-0">{itm.status}</span>
                          </div>
                        </div>

                        <div className="user_proff user_proff1">
                          <div className="id_name">
                            <ul className="user_list">
                              <li className="list_name">
                                <a className="id">Role</a>
                              </li>
                              <li className="list_name">
                                <a className="id">Phone number</a>
                              </li>
                            </ul>
                          </div>
                          <div className="detail_list">
                            <ul className="user_list">
                              <li className="list_names">
                                <a
                                  className="id_name"
                                  onClick={(e) => edit(itm.id)}
                                >
                                  {itm.subRole?.name}
                                </a>
                              </li>
                              <li className="list_names">
                                <a
                                  className="id_name"
                                  onClick={(e) => edit(itm.id)}
                                >
                                  <span className="call_icon"></span>
                                  {itm.mobileNo ? (
                                    <>
                                      <i
                                        className="fa fa-phone"
                                        aria-hidden="true"
                                      ></i>
                                      {itm.dialCode} {itm.mobileNo}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="table table-responsive table_section tableHeaderFix">
            <table className="table">
              <thead className="table_head">
                <tr className="heading_row">
                  <th
                    scope="col"
                    className="table_data"
                    onClick={(e) =>
                      filters?.sortBy == "name asc"
                        ? sorting("name desc", "desc")
                        : sorting("name asc", "asc")
                    }
                  >
                    Product {filters?.sortBy === "name asc" ? "↑" : "↓"}
                  </th>
                  {user?.role_name != "Customer" &&
                    user?.role_name != "Guest" && (
                      <th
                        scope="col"
                        className="table_data"
                        onClick={(e) =>
                          filters?.sortBy == "name asc"
                            ? sorting("user_name desc", "desc")
                            : sorting("user_name asc", "asc")
                        }
                      >
                        Customer
                      </th>
                    )}
                  <th scope="col" className="table_data">
                    Status
                  </th>
                  <th scope="col" className="table_data">
                    Order Date
                  </th>
                  <th scope="col" className="table_data">
                    Download Invoice
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loaging &&
                  data &&
                  data.map((itm, i) => {
                    return (
                      <tr className="data_row">
                        <td
                          className="table_dats"
                          onClick={(e) =>
                            history(`/orderDetail/${itm?.id}`)
                          }
                        >
                          <div className="user_detail">
                            <img
                              src={methodModel.userImg(
                                itm?.items?.[0]?.product_data?.images[0]
                              )}
                              className="user_imgs"
                            />
                            <div className="user_name">
                              <h4 className="user tableElipsis">
                                {itm?.items?.[0]?.product_data?.name}
                              </h4>
                            </div>
                          </div>
                        </td>
                        {user?.role_name != "Customer" &&
                          user?.role_name != "Guest" && (
                            <td className="table_dats">{itm?.user_name}</td>
                          )}
                        {itm?.status == "pending" ? (
                          <td
                            className="table_dats"
                            onClick={
                              user?.role_name != "Customer" &&
                              user?.role_name != "Guest"
                                ? () => statusChange(itm)
                                : ""
                            }
                          >
                            <div className={`user_hours ${itm.status}`}>
                              <span className="contract">{itm.status}</span>
                            </div>
                          </td>
                        ) : (
                          <td className="table_dats">
                            <div className={`user_hours ${itm.status}`}>
                              <span className="contract">{itm.status}</span>
                            </div>
                          </td>
                        )}
                        <td className="table_dats">
                          {datepipeModel.date(itm.createdAt)}
                        </td>
                        <td className="table_dats">
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/download.png"
                            alt="download"
                            onClick={() => handleDownload(itm?.invoice_id)}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {!loaging && total == 0 ? (
              <div className="py-3 text-center">No Data</div>
            ) : (
              <></>
            )}
          </div>
        </>
      )}

      {!loaging && total > filters.count ? (
        <div className="paginationWrapper">
          <span>
            Show {filters.count} from {total} Orders
          </span>
          <Pagination
            currentPage={filters.page}
            totalSize={total}
            sizePerPage={filters.count}
            changeCurrentPage={pageChange}
          />
        </div>
      ) : (
        <></>
      )}

      {loaging ? (
        <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div>
      ) : (
        <></>
      )}

      {showOrderModal && (
        <div className="onload-modal-small">
          <div className="modal1 ">
            <div className="bank-modal">
              <div className="position-relative mx-4 mb-2">
                <span
                  className="close-icon"
                  onClick={toggleConfirmPasswordModal}
                >
                  &times;
                </span>
                <span className="titiled ">Place Order</span>
              </div>

              <div className="borderit"></div>
              <div className="row mt-3 mx-3">
                <div className="col-6 mb-2">
                  {/* <p className="selectp">Existing Customer</p> */}
                  <div className="d-flex align-items-center gap-2">
                    <span>
                      {" "}
                      <input
                        type="radio"
                        id="existingCustomer"
                        name="customerType"
                        value="existing"
                        checked={customerType === "existing"}
                        onChange={() => handleCustomerTypeChange("existing")}
                      />
                    </span>
                    <label htmlFor="existingCustomer" className="fontsp">
                      Existing Customer
                    </label>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <span>
                      <input
                        type="radio"
                        id="newCustomer"
                        name="customerType"
                        value="new"
                        checked={customerType === "new"}
                        onChange={() => handleCustomerTypeChange("new")}
                      />
                    </span>
                    <label htmlFor="newCustomer" className="fontsp">
                      New Customer
                    </label>
                  </div>
                </div>

                {customerType === "new" && (
                  <div className="col-12">
                    {" "}
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <p className="selectp">First Name</p>
                        <input
                          type="text"
                          name="firstName"
                          value={newCustomerData.firstName}
                          onChange={handleCustomerDataChange}
                        />
                      </div>
                      <div className="col-md-6 mb-2">
                        <p className="selectp">Last Name</p>
                        <input
                          type="text"
                          name="lastName"
                          value={newCustomerData.lastName}
                          onChange={handleCustomerDataChange}
                        />
                      </div>
                      <div className="col-md-12 mb-2">
                        {/* <p className="selectp">New Customer Details</p> */}
                        <p className="selectp">Email</p>
                        <input
                          type="email"
                          name="email"
                          value={newCustomerData.email}
                          onChange={handleCustomerDataChange}
                        />
                        {errors.email && (
                          <p className="error">{errors.email}</p>
                        )}
                      </div>
                      <div className="col-md-12 mb-2">
                        <p className="selectp">Phone Number</p>
                        <div className="divphone">
                          {" "}
                          <PhoneInput
                            international
                            country={"us"}
                            inputProps={{
                              disabled: true,
                            }}
                            value={newCustomerData?.dialCode}
                            className="input_number bg_none disable_white mobin"
                            onChange={(phone) =>
                              setNewCustomerData({
                                ...newCustomerData,
                                dialCode: phone,
                              })
                            }
                            readOnly={true}
                            placeholder="+1"
                            enableSearch
                          />
                          <input
                            type="tel"
                            name="mobileNo"
                            className="typepoh"
                            value={newCustomerData.mobileNo}
                            onChange={handleCustomerDataChange}
                          />
                          {errors.mobileNo && (
                            <p className="error">{errors.mobileNo}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="borderit"></div>
              <div className="row mt-3 mx-3">
                {customerType === "existing" && (
                  <div className="col-6 mb-2">
                    <p className="selectp">Select Customer</p>{" "}
                    <select
                      className=""
                      value={selectedCustomerId}
                      onChange={(e) => setSelectedCustomerId(e.target.value)}
                    >
                      <option value="">Select Customer</option>
                      {Customer.map((customer) => (
                        <option key={customer._id} value={customer._id}>
                          {customer.fullName}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                <div className="col-6 mb-2">
                  <p className="selectp">Select Products</p>
                  <select
                    value={selectedItemId}
                    onChange={(e) => setSelectedItemId(e.target.value)}
                  >
                    <option value="">Select Item</option>
                    {data1.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6 mb-2">
                  <p className="selectp">Quantity</p>
                  <input
                    type="number"
                    value={quantity}
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      if (!isNaN(value) && value > 0) {
                        setQuantity(value);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="mx-4 px-1">
                {" "}
                <button
                  className="btit btn btn-primary mt-2"
                  onClick={handleAddItem}
                >
                  Add Item
                </button>
                {selectedItems.length > 0 && (
                  <div>
                    <p className="selectp mt-2">Selected Items</p>

                    <div className="limitqty">
                      {" "}
                      {selectedItems.map((item, index) => (
                        <>
                          <span className="borderspan">
                            <span className="lspan" key={index}>
                              {item.name} - Qty: {item.qty}
                            </span>
                            <button
                              className="removeButton"
                              onClick={() => removeSelectedItem(index)}
                            >
                              X
                            </button>
                          </span>
                        </>
                      ))}
                    </div>
                  </div>
                )}
                <div className="text-right mt-3">
                  <button
                    type="button"
                    className="btn btn-primary btit"
                    onClick={handleSubmit}
                  >
                    place Order
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Html;
