import React, { useEffect, useState } from "react";
import WebsiteHeader from "../../components/global/websiteheader/WebsiteHeader";
import "./style.scss";
import { useParams } from "react-router-dom";
import ApiClient from "../../apimethods/apiClient";
import loader from "../../components/loader/loader";
import parse from 'html-react-parser'
const InternalPages = () => {
  const { slug } = useParams();
  const [PageData, setPageData] = useState({});

  const GetData = () => {
    loader(true);
    ApiClient.get(`cms/detail?slug=${slug}`).then(res => {
      if (res.success) {
        setPageData(res.data);
      }
      loader(false);
    })
  }

  useEffect(() => {
    GetData()
  }, [slug])
  return (
    <>
      <div className="other-header">
        {/* Header Section */}
        <WebsiteHeader />
      </div>
      <section className="banner-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="internal-pages-content">
                <h2>{PageData.title}</h2>
                {/* <div className="img-set"> */}
                {/* <img src="/assets/img/chargeback.png" /> */}
                {/* </div> */}
                <div className="interest-pages-content">
                  <div className="mb-4"> 
               {parse(`${PageData.description}`)}
                  </div>

                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InternalPages;
