import React from "react";
import Layout from "../../components/global/layout";
import "./style.scss";

const Html = ({ data, total = { total } }) => {
  return (
    <Layout>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="hedding">Balance</h3>
      </div>
      <div className="form-row">
        <div className="col-md-6 mb-3">
          <label className="profileheddingcls">Amount On Hold</label>
          <div className="profiledetailscls">{data && data.on_hold}</div>
        </div>
        <div className="col-md-6 mb-3">
          <label className="profileheddingcls">Amount On Pending</label>
          <div className="profiledetailscls">{data && data.way_to_account}</div>
        </div>
        <div className="col-md-6 mb-3">
          <label className="profileheddingcls">Mode</label>
          <div className="profiledetailscls">{data && data.mode}</div>
        </div>
      </div>
    </Layout>
  );
};

export default Html;
