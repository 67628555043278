import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApiClient from '../../apimethods/apiClient';
import './style.scss';
import loader from '../../components/loader/loader';
import userTableModel from '../../models/table.model';
import Html from './html';
import { userType } from '../../models/type.model';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import environment from '../../environment';
import { toast } from 'react-toastify';

const Customers = (p) => {
    const user = useSelector(state => state.user)
    const [DataLength,setDataLength]=useState(0);
    const [ShowDeleteModal,setShowDeleteModal]=useState("none");
    const [ShowActiveModal,setShowActiveModal]=useState("none")
    const {role} =useParams()
    const searchState = useSelector((state) => state.search);
    const [filters, setFilter] = useState({ page: 1, count: 20, search: '', role:role||'', isDeleted: false })
    const [data, setData] = useState([])
    const [tab, setTab] = useState('list')
    const [total, setTotal] = useState(0)
    const [loaging, setLoader] = useState(true)
    const [tableCols, setTableCols] = useState([])
    const [form, setform] = useState(userType)
    const [roles, setRoles] = useState([])
    const navigate =useNavigate()

    // useEffect(() => {
    //     getRoles()
    // }, [])

    useEffect(() => {
        // if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data ,role})
            getData({ search: searchState.data,role, page: 1 })
        // }
    }, [])


    const uTableCols = () => {
        let exp = []
        if (tableCols) exp = tableCols
        let value = []
        userTableModel.list.map(itm => {
            if (itm != exp.find(it => it.key == itm.key)) {
                value.push(itm)
            }
        })
        return value
    }

    const addCol = (itm) => {
        setTableCols([...tableCols, itm])
    }


    const removeCol = (index) => {
        let exp = tableCols
        exp.splice(index, 1);
        setTableCols([...exp])
    }

    const getData = (p = {}) => {
        setLoader(true)
        let filter = { ...filters, ...p }
        let url='merchant/customer/all'
        if(filter.role)url='merchant/customer/all'
        ApiClient.get(url, filter).then(res => {
            if (res.success) {
                const data=res.data;
                setData(data);
                }
                setLoader(false)
            })
        }


    const clear = () => {
        setFilter({ ...filters, search: '' })
        getData({ search: '' })
    } 

    const deleteItem = (id) => {
            loader(true)
            ApiClient.delete(`merchant/customer?id=${id}`).then(res => {
                if (res.success) {
                    toast.success(res.message)
                    setShowDeleteModal("none")
                    if(DataLength==0){
                        setFilter({ ...filters, search: '',page:1})
                        getData({ search: '',page:1 })
                    }else{
                        clear()
                    }
                }
                loader(false)
            })
    }

    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    } 
    const openModal = (itm) => {
        let extra=new Date().getTime()
        setform({...itm,extra,role})
        document.getElementById("openuserModal").click()
    }

    const ChangeRole = (e) => {
        setFilter({ ...filters, role: e, page: 1 })
        getData({ role: e, page: 1 })
    }
    const ChangeStatus = (e) => {
        setFilter({ ...filters, status: e, page: 1 })
        getData({ status: e, page: 1 })
    }
    const  ChangeDocumentStatus = (e) => {
        setFilter({ ...filters, isVerifiedDocument: e, page: 1 })
        getData({ isVerifiedDocument: e, page: 1 })
    }
    const exportCsv = () => {
        loader(true)
        ApiClient.get('user/csv').then(res => {
            if (res.success) {
                let url = res.path
                let downloadAnchor = document.getElementById("downloadJS")
                downloadAnchor.href = url
                downloadAnchor.click()
            }
            loader(false)
        })
    }

    const colClick = (col, itm) => {
        if (col.key == 'healthClinicId') {
        }
    }

    const filter = (p = {}) => {
        setFilter({ ...filters, ...p })
        getData({ ...p, page: filters?.page })
    }

    const sorting = (key, i) => {
        filter({ sortBy: key, sorder: i })
    }

    const statusChange=(itm)=>{
        let modal='users'
        let status='active'
        if(itm.status=='active') status='deactive'

        // if(window.confirm(`Do you want to ${status=='active'?'Activate':'Deactivate'} this user`)){
            loader(true)
            ApiClient.put(`user/status/change`,{status,id:itm.id}).then(res=>{
                if(res.success){
                    getData()
                    toast.success(` User ${status=='active'?'Activated':'Deactivated'} Successfully`)
                    setShowActiveModal("none")
                }
                loader(false)
            })
        // }
    }

    const blockunblock=(itm)=>{
        if(window.confirm(`Do you want to ${!itm.isBlock?'Block':'Un-block'} this user`)){
            loader(true)
            ApiClient.put(`edit-profile`,{id:itm.id,isBlock:itm.isBlock?false:true}).then(res=>{
                if(res.success){
                    getData()
                }
                loader(false)
            })
        }
    }

    const view=(id)=>{
        navigate("/userDetail/"+id)
    }

    const edit=(id)=>{
        let url=`/users/edit/${id}`
        // if(role) url=`/users/${role}/edit/${id}`
        navigate(url)
    }

    const add=()=>{
        let url=`/users/add`
        if(role) url=`/users/${role}/add`
        navigate(url)
    }

    const tabChange=(tab)=>{
        setTab(tab)
    }

    const exportfun=async()=>{
        const token=await localStorage.getItem("token");
        const req = await axios({
            method: "get",
            url: `${environment.api}api/export/user`,
            responseType: "blob",
            body:{token:token}
          });
          var blob = new Blob([req.data], {
            type: req.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `Users.xlsx`;
          link.click();
    }

    const reset=()=>{
        let filter={
            status: '',
            role:'',
             page: 1,
        }
        setFilter({ ...filters,...filter })
        getData({ ...filter })
        // dispatch(search_success(''))
    }

    const isAllow=(key='')=>{
        // let permissions=user.role?.permissions
        // let value=permissions?.[key]
        // if(user?.role?.id==environment.adminRoleId) value=true

        return true
    }

    return <><Html
        user={user}
        colClick={colClick}
        exportfun={exportfun}
        isAllow={isAllow}
        tabChange={tabChange}
        tab={tab}
        reset={reset}
        add={add}
        roles={roles}
        view={view}
        edit={edit}
        role={role}
        ChangeRole={ChangeRole}
        ChangeStatus={ChangeStatus}
        openModal={openModal}
        pageChange={pageChange}
        addCol={addCol}
        deleteItem={deleteItem}
        exportCsv={exportCsv}
        uTableCols={uTableCols}
        removeCol={removeCol}
        filters={filters}
        ChangeDocumentStatus={ChangeDocumentStatus}
        setShowActiveModal={setShowActiveModal}
        ShowActiveModal={ShowActiveModal}
        tableCols={tableCols}
        loaging={loaging}
        data={data}
        ShowDeleteModal={ShowDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        total={total}
        statusChange={statusChange}
        blockunblock={blockunblock}
        sorting={sorting}
    />
    </>;
};

export default Customers;
