import React from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import datepipeModel from "../../models/datepipemodel";

const Html = ({
  pageChange,
  filters,
  loaging,
  data,
  view,
  total = { total },
}) => {
  return (
    <Layout>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="hedding">Accounts</h3>
      </div>
      <div className="table-responsive table_section">
        <table className="table">
          <thead className="table_head">
            <tr className="heading_row">
              <th scope="col" className="table_data">
                Account Number
              </th>
              <th scope="col" className="table_data">
                Type
              </th>
              <th scope="col" className="table_data">
                Account Status
              </th>
              <th scope="col" className="table_data">
                Created Date
              </th>
            </tr>
          </thead>
          <tbody>
            {!loaging &&
              data &&
              data.map((itm, i) => {
                return (
                  <tr className="data_row">
                    <td className="table_dats" onClick={(e) => view(itm.id)}>
                      <div className="user_detail">
                        <div className="user_name">
                          <h4 className="user">{itm?.number}</h4>
                        </div>
                      </div>
                    </td>
                    <td className="table_dats">{itm?.type}</td>
                    <td className="table_dats">
                      <div className={`user_hours ${itm.status}`}>
                        {itm?.status}
                      </div>
                    </td>
                    <td className="table_dats">
                      {datepipeModel.date(itm.createdAt)}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        {!loaging && total == 0 ? (
          <div className="py-3 text-center">No Data</div>
        ) : (
          <></>
        )}
      </div>

      {!loaging && total > filters.count ? (
        <div className="paginationWrapper">
          <span>
            Show {filters.count} from {total} Sub Admin’s
          </span>
          <Pagination
            currentPage={filters.page}
            totalSize={total}
            sizePerPage={filters.count}
            changeCurrentPage={pageChange}
          />
        </div>
      ) : (
        <></>
      )}

      {loaging ? (
        <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default Html;
