import React from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import datepipeModel from "../../models/datepipemodel";
import RefundModal from "../../models/refund.modal";
import SelectDropdown from "../../components/common/selectdropdown";

const Html = ({
  pageChange,
  filters,
  loaging,
  data,
  history,
  changestatus,
  setRefundid,
  ShowRefundModel,
  setShowRefundModal,
  refund,
  user,
  setRetRef,
  decryptData,
  total = { total },
  statuses,
  clear,
}) => {
  return (
    <Layout>
      <RefundModal
        show={ShowRefundModel}
        setShow={setShowRefundModal}
        confirm={refund}
      />
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="hedding">Transactions</h3>
        <div className="flex gap-2 ml-auto">
          <SelectDropdown
            id="statusDropdown"
            displayValue="name"
            placeholder="All Status"
            intialValue={filters.status}
            result={(e) => {
              changestatus(e.value);
            }}
            options={statuses}
          />
          {filters.status ? (
            <>
              <button
                className="bg-primary leading-10 h-10 inline-block shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg"
                onClick={() => clear()}
              >
                Reset
              </button>
            </>
          ) : (
            <></>
          )}
        </div>

        {/* <article className="d-flex filterFlex phView">
          <div className="dropdown addDropdown chnagesg">
            <button
              className="btn btn-primary dropdown-toggle removeBg"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {!filters.status
                ? "All Status"
                : filters.status == "completed"
                ? "Completed"
                : filters.status == "processing"
                ? "Processing"
                : "failed"}
            </button>
            <div
              className="dropdown-menu shadow bg_hover"
              aria-labelledby="dropdownMenuButton"
            >
              <a
                className={
                  filters.status == ""
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("")}
              >
                All Status
              </a>
              <a
                className={
                  filters.status == "failed"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("failed")}
              >
                Failed
              </a>
              <a
                className={
                  filters.status == "completed"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("completed")}
              >
                Completed
              </a>
              <a
                className={
                  filters.status == "processing"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("processing")}
              >
                Processing
              </a>
            </div>
          </div>
        </article> */}
      </div>

      <>
        <div className="table-responsive table_section">
          <table className="table">
            <thead className="table_head">
              <tr className="heading_row">
                <th scope="col" className="table_data">
                  Transaction Id
                </th>
                {user.role_name == "Customer" ? (
                  <th scope="col" className="table_data">
                    Merchant Name
                  </th>
                ) : (
                  <th scope="col" className="table_data">
                    Customer Name
                  </th>
                )}
                <th scope="col" className="table_data">
                  Amount($)
                </th>
                <th scope="col" className="table_data">
                  Payment Status
                </th>
                <th scope="col" className="table_data">
                  Order Date
                </th>
                {user.role_name != "Customer" ? (
                  <th scope="col" className="table_data">
                    {" "}
                    Refund
                  </th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {!loaging &&
                data &&
                data.map((itm, i) => {
                  return (
                    <tr className="data_row">
                      <td
                        onClick={(e) =>
                          history(`/transactionDetail/${itm?.id}`)
                        }
                        className="table_dats"
                      >
                        {itm?.transaction_id}
                      </td>
                      <td className="table_dats">{user.role_name == "Customer" ? itm?.merchant_name : itm?.customer_name}</td>
                      <td className="table_dats">
                        {itm?.amount
                          ? (decryptData(itm.amount) * 100).toFixed(2)
                          : "--"}
                      </td>
                      <td className="table_dats">
                        <div className={`user_hours ${itm.status}`}>
                          <span className="contract">{itm.status}</span>
                        </div>
                      </td>
                      <td className="table_dats">
                        {datepipeModel.date(itm.createdAt)}
                      </td>

                      {user.role_name === "Customer" ? null : <td className="table_dats">
                        <button
                          disabled={itm?.isRefund == true && user.role_name != "Customer"}
                          onClick={() => {
                            setRefundid(itm.id);
                            setShowRefundModal("block");
                            setRetRef(itm?.retref);
                          }}
                          className="btn btn-primary  removeBg"
                        >
                          Refund
                        </button>
                      </td>}
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {!loaging && total == 0 ? (
            <div className="py-3 text-center">No Data</div>
          ) : (
            <></>
          )}
        </div>
      </>

      {!loaging && total > filters.count ? (
        <div className="paginationWrapper">
          <span>
            Show {filters.count} from {total} transactions
          </span>
          <Pagination
            currentPage={filters.page}
            totalSize={total}
            sizePerPage={filters.count}
            changeCurrentPage={pageChange}
          />
        </div>
      ) : (
        <></>
      )}

      {loaging ? (
        <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default Html;
