import React from 'react'
import Html from './html.js'

const SuccessPage = () => {
  return (
    <div>
      <Html/>
    </div>
  )
}

export default SuccessPage
