import React, { useEffect, useState } from "react";
import Layout from '../../components/global/layout';
import "./style.scss"; 
import ApiClient from "../../apimethods/apiClient";
import { useSelector } from "react-redux"; 
import loader from "../../components/loader/loader";
import { toast } from "react-toastify";

const Credential = () => { 
    const [token, setToken] = useState('')
    const [generatedToken, setGeneratedToken] = useState(null)
    const mode = useSelector((state) => state.user);
    const [hiddenRows, setHiddenRows] = useState([]);
    const [password, setPassword] = useState('')
    const [showConfirmPasswordModal, setShowConfirmPasswordModal] = useState(false);
    const [pass , setpassData] = useState(false)

    const toggleConfirmPasswordModal = () => {
        setShowConfirmPasswordModal(!showConfirmPasswordModal);
    }

    const ConfirmPassword = () => {
        ApiClient.post('user/confirm-password', { "password": password }).then((res) => {
            if (res?.success) {
                setpassData(true)
                setPassword('')
                toggleVisibility()
                toast.success(res.message)
            }
        })
    }

    const toggleVisibility = (index) => {
        setHiddenRows((prevHiddenRows) => {
            if (prevHiddenRows?.includes(index)) {
                return prevHiddenRows.filter((i) => i !== index);
            } else {
                    toggleConfirmPasswordModal()
                    return [...prevHiddenRows, index]
            }
        });
    };

    const modeType = mode?.mode ? 'live' : 'sandbox'

    const filteredData = generatedToken?.filter(item => item.mode === modeType);

    const generateToken = () => {
        ApiClient.post('merchant/creds', { mode: modeType }).then((res) => {
            toast.success(res.message)
            setToken(res?.data)
            generatedTokenData()
        })
    }



    const generatedTokenData = () => {
        loader(true)
        ApiClient.get('merchant/creds', { mode: modeType }).then((res) => {
            loader(false)
            setGeneratedToken(res?.data)
        })
    }

    useEffect(() => {
        generatedTokenData()
    }, [])

    const deleteCredential = (index) => {
        ApiClient.delete(`merchant/creds?mode=${index}`).then((res) => {
            setGeneratedToken(res?.data)
            generatedTokenData()
        })
    }


    return (<>
        <Layout>
        <section className="">
            <div className="container">
                <div className="row">
                    <div className="col-12 ">
                        <div className="d-flex justify-content-between align-items-center ">
                            <h3 className="m-0 hedding">Generate Credentials</h3>
                            <button className="btn btn-primary" onClick={() => generateToken()}><i className="fa fa-plus mr-1"></i> Generate Token</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="table-responsive table_section">
                            <table className="table">
                                <thead className="table_head">
                                    <tr className="heading_row">
                                        <th className="table_data">Name</th>
                                        <th className="table_data">Secret Key</th>
                                        {/* <th>Last Used</th> */}
                                        <th className="table_data">Client Id</th>
                                        <th className="table_data">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData?.length > 0 ? filteredData?.map((data, index) => (
                                        <tr key={index}>
                                            <td>{data.mode}</td>
                                            <td>
                                                {hiddenRows?.includes(index) && pass ? (
                                                    <p className="line-break">{data.secret_key}  <i
                                                        className={`fa ${!hiddenRows?.includes(index)  ? 'fa-eye-slash' : 'fa-eye'}`}
                                                        onClick={() => toggleVisibility(index)}
                                                    ></i></p>
                                                ) : (
                                                    <p className="line-break"><span className="blured mr-2 ">dbfuhvbdsuhfbsdfjdsnfjnuur8943y878rhgewhr7y34rtyfjhdsvhsvhb </span><i
                                                        className={`fa ${!hiddenRows?.includes(index) ? 'fa-eye-slash' : 'fa-eye'}`}
                                                        onClick={() => toggleVisibility(index)}
                                                    ></i></p>
                                                )}

                                            </td>
                                            <td><p className="line-break">{data.client_id}</p></td>
                                            <td><span className="delete-icon" onClick={() => deleteCredential(modeType)}><i className="fa fa-trash mr-1"></i></span></td>
                                        </tr>
                                    )) :
                                        <tr className="credential_data"><td  colspan="4" >No credentials</td></tr>
                                    }
                                    <tr>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>

            {showConfirmPasswordModal && (
                <div className="onload-modal-small">
                    <div className="modal1 ">

                        <div className="bank-modal">
                            <div className="position-relative">
                                <span className="close-icon" onClick={toggleConfirmPasswordModal}>
                                    &times;
                                </span>
                            </div>
                            <h2 className="titiled">Confirm Password</h2>
                            <input
                                type="password"
                                placeholder="Enter your password"
                                value={password}
                                className="form-control"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className="text-right mt-3">
                                <button className="btn btn-primary"
                                    onClick={ConfirmPassword}
                                >Confirm</button>
                            </div>

                        </div>
                    </div>
                </div>

            )}
        </Layout>
    </>
    );
};

export default Credential;
