let host = document.location.host;
// let apiUrl ='https://luxapi.jcsoftwaresolution.in/api/'
let apiUrl = "https://luxapi.jcsoftwaresolution.in/api/"
// if(host.includes('localhost')){
//   apiUrl='http://portal.jcsoftwaresolution.in:6052/api/'
// }else if(host.includes('https')){
//   apiUrl='https://luxapi.jcsoftwaresolution.in/'
// }

const environment = {
    api: apiUrl,
    imageapi:"https://luxapi.jcsoftwaresolution.in/",
    withoutapi:"https://luxapi.jcsoftwaresolution.in/",
    adminRoleId:'6540cfa35a7513892be61413',
    userRoleId:'64b15102b14de6c28838f7d2',
    // map_api_key:'AIzaSyAbD0kk0SRu76yogIQKhY2r-oKdAZIbNIs',
    map_api_key : "AIzaSyDqvi9Hk4fHNqCoHlSVp8r7-J6Wk7IhMOw",
    productTypeId:'64a7d198fa039f179c0320ca',
    // These are the Zoom Marketplace Secrets thats we need during Zoom Integration
    ZoomClientId:"vaTvhtM1RbewD14ccaBnGw",
    ZoomClientSecret:"t4VRlCiDKCZk03xds38MrMYu3xmYfk1v",
    secretKey : 'jCu31UkI100ooYo230sc',
  };
  export default environment;
