import { useState } from "react";
import methodModel from "../../components/validations/Validations";
import Pagination from "react-pagination-js";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../components/global/layout/Header";
import Footer from "../../components/global/footer/Footer";
import ApiClient from "../../apimethods/apiClient";
const Html = ({
  product,
  productpage,
  total,
  filters,
  pageChange,
  categoryData,
  getproducts,
  getactivefilter,
  activefilters,
  setactivefilters,
  searchfor,
  setsearchfor,
  innerSearch,
  clearsearch,
  filterss,
  categories,
  setCategoryId,
  slug,
}) => {
  const user = useSelector((state) => state.user);
  const history = useNavigate();

  const addTofav = (prdt_id) => {
    let payload = {
      type: "product",
      product_id: prdt_id,
    };
    ApiClient.post(`wishlist`, payload).then((res) => {
      if (res?.success) {
        getproducts();
        // toast.success(res?.message)
      }
    });
  };

  return (
    <>
      <Header />
      <div className="top-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <section>
                <h6 className="fw-medium mb-0 text-dark">Category</h6>
                <div className="check-box-btn p_list_height mt-3 pl-1">
                  <ul className="accordian_ul mb-0">
                    <li>
                      <div className="form-check">
                        <input
                          checked={!categories}
                          className="form-check-input "
                          onClick={() => {
                            getproducts({ category_id: "" });
                            setCategoryId("");
                          }}
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          <a
                            className="text-capitalize ss mt-1"
                            onClick={() => {
                              getproducts({ category_id: "" });
                              setCategoryId("");
                            }}
                            data-bs-toggle="collapse"
                            href={`#collapseExample`}
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          >
                            All
                          </a>
                        </label>
                      </div>
                    </li>
                  </ul>
                  {categoryData && categoryData?.length ? (
                    categoryData?.map((itm, index) => {
                      return (
                        <>
                          <ul className="accordian_ul mb-0">
                            <li>
                              <div className="form-check">
                                <input
                                  checked={itm?._id == categories}
                                  onChange={() => getactivefilter(itm?.id)}
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadio"
                                />

                                <a
                                  className="text-capitalize ss mt-1"
                                  onClick={() => getactivefilter(itm?.id)}
                                  data-bs-toggle="collapse"
                                  href={`#collapseExample${index}`}
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="collapseExample"
                                >
                                  {itm?.name}
                                </a>
                              </div>
                            </li>
                          </ul>
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </section>
            </div>
            <div className="col-md-9 ">
              <div className="productsection mb-3">
                <div className="row">
                  <div className="col-6 d-flex align-items-center mb-2">
                    <div className="position-relative search_input_product">
                      <input
                        className="form-control me-2"
                        type="text"
                        placeholder="Search for anything..."
                        aria-label="Search"
                        value={searchfor}
                        onChange={(e) => setsearchfor(e.target.value)}
                      />

                      <i className="" onClick={(e) => innerSearch(searchfor)}>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="search_product"
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                        >
                          <path
                            d="M9.2927 16.0232C12.7933 16.0232 15.6311 13.1854 15.6311 9.68481C15.6311 6.18422 12.7933 3.34644 9.2927 3.34644C5.79211 3.34644 2.95432 6.18422 2.95432 9.68481C2.95432 13.1854 5.79211 16.0232 9.2927 16.0232Z"
                            stroke="#191C1F"
                            stroke-width="1.44877"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M13.7748 14.167L17.442 17.8342"
                            stroke="#191C1F"
                            stroke-width="1.44877"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </i>
                    </div>
                    {filters?.search && (
                      <span
                        className="pills_bar"
                        onClick={(e) => clearsearch()}
                      >
                        <i className="fa fa-times mr-1" aria-hidden="true"></i>
                        Clear
                      </span>
                    )}
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center gap-2">
                      <div className="">
                        <h6 className="sort"> Sort by:</h6>
                      </div>
                      <div className="w-100">
                        <select
                          className="form-select selection_option "
                          onChange={(e) => filterss(e.target.value)}
                        >
                          <option value="name asc">Name A-Z</option>
                          <option value="name desc">Name Z-A</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="active_section">
                <div className="d-flex justify-content-between ">
                  <div className="d-flex align-items-center gap-2">
                    <span className="p_active_filter ">Active Filters:</span>
                    {activefilters?.categoryname && (
                      <span className=" deactive_cagory text-capitalize">
                        {activefilters?.categoryname}
                      </span>
                    )}
                    {activefilters?.subcategoryname && (
                      <span className="text-capitalize deactive_cagory">
                        {activefilters?.subcategoryname}
                      </span>
                    )}
                    {activefilters?.BrandName.length
                      ? activefilters?.BrandName.map((itm) => {
                          return (
                            <span className="text-capitalize deactive_cagory">
                              {itm}
                            </span>
                          );
                        })
                      : ""}
                    {activefilters?.categoryname ||
                    activefilters?.subcategoryname ||
                    activefilters?.BrandName?.length > 0 ? (
                      <i
                        className="fa fa-times gaping_12"
                        onClick={(e) =>
                          setactivefilters({
                            categoryname: "",
                            subcategoryname: "",
                            BrandName: [],
                          }) &
                          history("/products/all") &
                          getproducts("")
                        }
                        area-label="false"
                        title="clear all filters"
                      ></i>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <span className="p_res_num total_no  ">{total}</span>{" "}
                    <span className="p_res_text result"> Results found.</span>
                  </div>
                </div>
              </div>

              <section className="products_our_section_two mt-2 ">
                <div className="row ">
                  {product && product.length ? (
                    product.map((itm, i) => {
                      return (
                        <div className=" col-lg-3 col-md-6 col-12   mb-3">
                          <div className="flash_sales_div pointer " title="">
                            {localStorage.getItem("token") && (
                              <>
                                {!itm?.inWishlist ? (
                                  <div className="">
                                    <i
                                      className="fa fa-heart"
                                      onClick={(e) => addTofav(itm?.id)}
                                    ></i>
                                  </div>
                                ) : (
                                  <div className="heart_icon">
                                    <i
                                      className="fa fa-heart"
                                      onClick={(e) => addTofav(itm?.id)}
                                    ></i>
                                  </div>
                                )}
                              </>
                            )}
                            <div
                              className=" p-3"
                              onClick={(e) =>
                                productpage(itm?.id ? itm?.id : itm?._id)
                              }
                            >
                              <div className="text-center ">
                                <img
                                  src={
                                    itm?.images
                                      ? methodModel.noImg(
                                          itm?.images[0] ? itm?.images[0] : ""
                                        )
                                      : "./assets/img/no-image.png"
                                  }
                                  className="flash_sales_img img-fluid slider_img_height"
                                  alt="img"
                                />
                              </div>
                              <p className="real_price text-capitalize">
                                {itm?.brand_name
                                  ? itm?.brand_name
                                  : itm?.category_name}
                              </p>
                              <p className="flase_sales_heading text-capitalize">
                                {itm?.name}
                              </p>
                              {/* <span className="real_price">{ForGettingHigherprice(itm?.attributes)} $</span> */}
                              <span className="old_price">${" "}{itm?.price}</span>
                              <div className="flash_ratings d-flex gap-1">
                                <img
                                  src="/assets/img/rating.svg"
                                  className="ratings-img img-fluid"
                                  alt="ratings"
                                />
                                <img
                                  src="/assets/img/rating.svg"
                                  className="ratings-img img-fluid"
                                  alt="ratings"
                                />
                                <img
                                  src="/assets/img/rating.svg"
                                  className="ratings-img img-fluid"
                                  alt="ratings"
                                />
                                <img
                                  src="/assets/img/rating.svg"
                                  className="ratings-img img-fluid"
                                  alt="ratings"
                                />
                                <img
                                  src="/assets/img/rating.svg"
                                  className="ratings-img img-fluid"
                                  alt="ratings"
                                />{" "}
                                {/* <span className="rating_num ml-1">(88)</span>{' '} */}
                              </div>
                              <div className="stor_name_add">
                                <h4 className="order_store text-capitalize">
                                  {/* <p className='bold_grey bold_grey_store'>Mode:{itm?.mode} </p> */}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <div className="text-center">
                        <h6 className="mb-0 py-2">NO RESULT FOUND</h6>
                      </div>
                      <div className="row justify-content-center">
                        <div className=" col-sm-12 col-md-12 col-lg-12 px-lg-12  mb-md-0 mb-lg-12 jus">
                          <div className=" pointer " title="">
                            <div className=" p-3">
                              <div className="text-center ">
                                <img
                                  src={"/assets/img/no-products-found.png"}
                                  className="flash_sales_img img-fluid slider_img_height"
                                  alt="img"
                                />
                              </div>
                              <div className="text-center mt-3">
                                <p className="real_price text-capitalize">
                                  Not found what you are looking for ?
                                </p>
                                <p className="flase_sales_heading text-capitalize mb-2">
                                  You can recommend us .Just type what you are
                                  looking at
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </section>
              {total > filters.count ? (
                <div className="paginationWrapper pl-1">
                  <span>
                    Show {filters.count} from {total} Categories
                  </span>
                  <Pagination
                    currentPage={filters.page}
                    totalSize={total}
                    sizePerPage={filters.count}
                    changeCurrentPage={pageChange}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Html;
