import { Formik } from "formik";
import { useEffect, useState } from "react";
import loader from "../../components/loader/loader";
import ApiClient from "../../apimethods/apiClient";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "./style.scss";
import methodModel from "../../components/validations/Validations";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom"; 
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { login_success } from "../../redux/actions/user";
import { useNavigate } from "react-router-dom";

export default function SignUpGuest() {
  const navigate = useNavigate();
  const {slug} = useParams()
  const dispatch = useDispatch()
  const params = new URLSearchParams(window.location.search);
  const websiteDetails = useSelector((state) => state.website);
  const [EmailError, setEmailError] = useState(false);
  const [email, setemail] = useState("");
  const ReferalEmail = params.get("email");
  const [Submitted, setSubmitted] = useState(false);

  const HandleCLickhere = (e) => {
    e.preventDefault();
    loader(true);
    ApiClient.post(`resend/verification`, { email: email }).then((response) => {
      if (response.success) {
        toast.success(response.message);
      }
      loader(false);
    });
  };

  return (
    <div className="bg_img main_signup signup-page">
      <div className="center_bx">
        <div className="container">
          <div className="row ">
            <div className=" col-lg-12 mx-auto">
              <div className="right_side ">
                <div className="scroll_bx">

                  <Link to="/">
                    <div className="logo_image mb-3">
                      <img
                        src={methodModel.userImg(websiteDetails.logo)}
                        className="logo_name"
                      />
                    </div>
                  </Link>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      email: ReferalEmail ? ReferalEmail : "",
                      firstname: "",
                      lastname: "",
                      mobileNo:'',
                      dialCode:'+1',
                      check: false,
                    }}
                    onSubmit={(values, { resetForm }) => {
                      setSubmitted(true)
                      const payload = {
                        email: values.email,
                        firstName: `${values.firstname}`,
                        lastName: `${values.lastname}`,
                        mobileNo:`${values.mobileNo}`,
                        dialCode:`${values.dialCode}`,
                      };

                      // if (values.check != true) {
                      //   toast.error(
                      //     "Please accept the terms of use and privacy policy"
                      //   );
                      //   return;
                      // }

                      loader(true);
                      ApiClient.post(`guest/login`, payload).then((res) => {
                        if (res.message) {
                          setemail(payload.email);
                          navigate(`/products/merchant/${slug}`)
                          localStorage.setItem("merSlug", slug);
                          localStorage.setItem("token", res.data.access_token);
                          localStorage?.setItem("userid",res?.data?._id)
                          dispatch(login_success(res.data));
                          resetForm();
                        }
                        loader(false);
                      });
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      resetForm,
                    }) => (
                      <form className="centerLogin" onSubmit={handleSubmit}>
                        <div className="text-center mb-0">
                          <h3 className="text-center lgtext">
                            Create your account.
                          </h3>
                          <p className="accopunt text-center">
                            Already have an account?{" "}
                            <Link className="sign_up" to="/login">
                              {" "}
                              Sign In
                            </Link>
                          </p>
                        </div>

                        <div className="tab-content mt-3" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <div className="row ">
                              <div className="col-md-6">
                                <label className="form-label ml-2 mt-3">
                                  First Name
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  value={values.firstname}
                                  required
                                  name="firstname"
                                  placeholder="First Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="form-control bginput chnages"
                                />
                              </div>
                              <div className="col-md-6">
                                <label className="form-label ml-2 mt-3">
                                  Last Name
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  value={values.lastname}
                                  required
                                  name="lastname"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Last Name"
                                  className="form-control bginput chnages"
                                />
                              </div>
                            </div>
                            <div className="mt-0  ">

                              <div className="row">
                                <div className="col-md-12">
                                  <label className="form-label ml-2 mt-3">
                                    Email<span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="email"
                                    value={values.email}
                                    required
                                    name="email"
                                    pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                                    onChange={(e) => {
                                      handleChange(e);
                                      setEmailError(
                                        methodModel.handleerrorcahnge(e.target.value)
                                      );
                                    }}
                                    onBlur={handleBlur}
                                    placeholder="Email Address"
                                    className="form-control bginput chnages"
                                  />
                                  {EmailError ? (
                                    <p className="text-center text-danger">
                                      Please enter a valid Email
                                    </p>
                                  ) : null}
                                </div>

                                <div className="col-md-12">
                                  <label className="form-label ml-2 mt-3">
                                    Phone Number<span className="text-danger">*</span>
                                  </label>
                                  <div className="phoneInput_cls d-flex form-control p-0 signinp phone_text">
                                    <PhoneInput
                                      country={'us'}
                                      value={values.dialCode}
                                      countryCodeEditable={false}
                                      enableSearch={true}
                                      placeholder=""
                                      onChange={(value) => {
                                        handleChange({ target: { name: 'dialCode', value } });
                                      }}
                                      required
                                    />

                                    <input
                                      type="text"
                                      className="form-control phph"
                                      placeholder="Mobile No."
                                      value={values.mobileNo}
                                      maxLength={12}
                                      onChange={(e) => {
                                        const inputNumber = e.target.value.replace(/\D/g, '');
                                        handleChange({ target: { name: 'mobileNo', value: inputNumber } });
                                      }}
                                      required
                                    />
                                  </div>
                                </div></div>

                              {/* <div className="mt-4 d-flex align-items-baseline">
                                <input
                                  type="checkbox"
                                  className="checkBox"
                                  name="check"
                                  onChange={handleChange}
                                />
                                <label className="clickBox ml-2 mb-0">
                                  By clicking create account, I agree that I have
                                  read and accepted the terms of use and privacy
                                  policy.
                                </label>
                              </div> */}
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary loginclass mt-3"
                                >
                                  Sign Up
                                </button>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="profile"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                          >
                            <div className="">
                              <label className="form-label ml-2">
                                Email<span className="text-danger">*</span>
                              </label>
                              <input
                                type="email"
                                value={values.email}
                                required
                                disabled={ReferalEmail ? true : false}
                                pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                                name="email"
                                onChange={(e) => {
                                  handleChange(e);
                                  setEmailError(
                                    methodModel.handleerrorcahnge(e.target.value)
                                  );
                                }}
                                onBlur={handleBlur}
                                placeholder="Email Address"
                                className="form-control bginput chnages"
                              />
                              <div style={{ height: "10px" }}>
                                {EmailError ? (
                                  <p
                                    className="text-center text-danger"
                                    style={{ fontSize: "10px" }}
                                  >
                                    Please enter a valid Email
                                  </p>
                                ) : null}
                              </div>
                              <div className="row mt-3">
                                <div className="col-md-6">
                                  <label className="form-label ml-2">
                                    First Name
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={values.firstname}
                                    required
                                    name="firstname"
                                    placeholder="First Name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="form-control bginput chnages"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label className="form-label ml-2">
                                    Last Name
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={values.lastname}
                                    required
                                    name="lastname"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Last Name"
                                    className="form-control bginput chnages"
                                  />
                                </div>
                              </div>

                              <br />
                              <button
                                onClick={resetForm}
                                type="button"
                                id="resetForm"
                                className="d-none"
                              >
                                {" "}
                              </button>

                              <div></div>

                              <div>
                                <div className="mt-1 d-flex align-items-baseline">
                                  <input
                                    type="checkbox"
                                    className="checkBox"
                                    name="check"
                                    value={values.check}
                                    onChange={handleChange}
                                  />
                                  <label className="clickBox ml-2 mb-0">
                                    By clicking create account, I agree that I
                                    have read and accepted the terms of use and
                                    privacy policy.
                                  </label>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-primary loginclass mt-3"
                                >
                                  Sign Up
                                </button>
                              </div>

                              <div className="borderCls mt-3">
                                <span className="or">or</span>
                              </div>
                              <div className="text-center d-flex justify-content-center mt-3 mb-3">
                                <a className="btn btn-outline google_id mr-3 ">
                                  <img src="/assets/img/gogle.png" />
                                  <span className="google_heading"></span>
                                </a>
                                <a className="btn btn-outline google_id">
                                  <img src="/assets/img/facebooklogo.png" />
                                  <span className="google_heading"></span>
                                </a>
                              </div>
                              {/* <div className="text-center mt-2"><p className="text-primary sign_users" onClick={e=>document.getElementById("home-tab").click()}>Sign up as a Employer</p></div> */}
                            </div>
                          </div>
                        </div>

                        {/* end tab */}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <button
              data-toggle="modal"
              className="d-none"
              data-target="#exampleModal"
              id="openSigupmodal"
            ></button>
            <div
              className="modal fade confirmation-modal"
              id="exampleModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="text-center p-3">
                      <img
                        src="https://freepngimg.com/save/18343-success-png-image/1200x1200"
                        alt="success"
                        className="iconSignup"
                      />
                      <div className="modal-body pb-3">
                        <h2 className="glad text-dark">
                          Glad you'll be joining us!
                        </h2>
                        <h2 className="glad mb-4 text-dark">
                          You're almost there,
                        </h2>
                        <h4 className="glads ">
                          <b>Next Step:</b> Verify Your Email. We’ve sent you an
                          email. Click the link in the email to continue setting
                          up your account.
                        </h4>
                      </div>
                      <button
                        type="button"
                        className="btn dark-btn"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        onClick={(e) => navigate("/home")}
                      >
                        OK
                      </button>
                      <p className="mt-2">
                        Didn’t receive an email?{" "}
                        <a
                          className="text-primary"
                          onClick={(e) => HandleCLickhere(e)}
                        >
                          Click Here.
                        </a>
                      </p>
                      <p className="mt-1 mb-0">
                        Be sure to check your spam and promotions folders if you
                        don’t see your verification email shortly.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
