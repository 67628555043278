import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiClient from "../../apimethods/apiClient";
import "./style.scss"; 
import loader from "../../components/loader/loader";
import userTableModel from "../../models/table.model";
import Html from "./html";
import { userType } from "../../models/type.model";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Accounts = (p) => {
  const user = useSelector((state) => state.user);
  const searchState = useSelector((state) => state.search);
  const [filters, setFilter] = useState({
    page: 1,
    count: 50,
    search: "",
    isDeleted: false,
  });
  const [data, setData] = useState([]);
  const [tab, setTab] = useState("list");
  const [total, setTotal] = useState(0);
  const [loaging, setLoader] = useState(true);
  const [tableCols, setTableCols] = useState([]);
  const [form, setform] = useState(userType);
  const [roles, setRoles] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    if (user && user.loggedIn) {
      setFilter({ ...filters, search: searchState.data });
      getData({ search: searchState.data, page: 1 });
    }
  }, [searchState]);

  const uTableCols = () => {
    let exp = [];
    if (tableCols) exp = tableCols;
    let value = [];
    userTableModel.list.map((itm) => {
      if (itm != exp.find((it) => it.key == itm.key)) {
        value.push(itm);
      }
    });
    return value;
  };

  const addCol = (itm) => {
    setTableCols([...tableCols, itm]);
  };

  const removeCol = (index) => {
    let exp = tableCols;
    exp.splice(index, 1);
    setTableCols([...exp]);
  };

  const getData = (p = {}) => {
    setLoader(true);
    let filter = { ...filters, ...p, user_id: user._id };
    let url = "accounts";
    ApiClient.get(url, filter).then((res) => {
      if (res.success) {
        setData(res.data);
        setTotal(res.total);
      }
      setLoader(false);
    });
  };

  const clear = () => {
    setFilter({ ...filters, search: "", page: 1 });
    getData({ search: "", page: 1 });
  };

  const deleteItem = (id) => {
    if (window.confirm("Do you want to delete this")) {
      loader(true);
      ApiClient.delete("api/user/delete", { id: id }).then((res) => {
        if (res.success) {
          toast?.success(res.message);
          clear();
        }
        loader(false);
      });
    }
  };

  const pageChange = (e) => {
    setFilter({ ...filters, page: e });
    getData({ page: e });
  };

  const colClick = (col, itm) => {
    if (col.key == "healthClinicId") {
    }
  }; 

  const view = (id) => {
    history("/accountDetail/" + id);
  };

  const edit = (id) => {
    let url = `/users/edit/${id}`;
    history(url);
  };

  const tabChange = (tab) => {
    setTab(tab);
  };
  
  const reset = () => {
    let filter = {
      status: "",
      subRole: "",
      page: 1,
    };
    setFilter({ ...filters, ...filter });
    getData({ ...filter });
    // dispatch(search_success(''))
  };

  return (
    <>
      <Html
        colClick={colClick}
        tabChange={tabChange}
        tab={tab}
        reset={reset}
        roles={roles}
        view={view}
        edit={edit}
        pageChange={pageChange}
        addCol={addCol}
        deleteItem={deleteItem} 
        uTableCols={uTableCols}
        removeCol={removeCol}
        filters={filters}
        tableCols={tableCols}
        loaging={loaging}
        data={data}
        total={total} 
      />
    </>
  );
};

export default Accounts;
