import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiClient from "../../../apimethods/apiClient";
import loader from "../../../components/loader/loader";
import methodModel from "../../../components/validations/Validations";
import { login_success } from "../../../redux/actions/user";
import "./style.scss";
import Html from "./Html";
import { useNavigate } from "react-router-dom";
import { Layout } from "antd";
import { toast } from "react-toastify";

const EditProfile = (p) => {
  const user = useSelector((state) => state.user);
  const [addressSellected, setAddressSellected] = useState(false);
  const [ProtofolioError, setProtofolioError] = useState(false);
  const [images, setimages] = useState([]);
  const [data, setData] = useState("");
  const [form, setForm] = useState({
    id: "",
    company: "",
    description: "",
    address: "",
    dialCode: "",
    location: "",
    country: "",
    title: "",
    hourlyRate: "",
    startDate: "",
    endDateDate: "",
    description: "",
    portfolioUrl: "",
    timeZone: "",
    companyName: "",
  });

  const dispatch = useDispatch();
  const history = useNavigate();
  const [submitted, setSubmitted] = useState(false);

  const formValidation = [
  ];

  const gallaryData = () => {
    loader(true);
    ApiClient.get(`user/profile`, { id: user._id }).then((res) => {
      if (res.success) {
        setForm({ form, ...res.data, role: res?.data?.role?.name });
        setData(res.data);
      }
      loader(false);
    });
  };

  const getError = (key) => {
    return methodModel.getError(key, form, formValidation);
  };

  const handleSubmit = (e) => {
    setProtofolioError(false);
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (invalid) return;
    if (form.address == "") return;
    let value = {
      fullName: form.fullName,
      dialCode: form.dialCode,
      mobileNo: form.mobileNo,
      image: form.image,
      id: form.id,
      companyName: form.companyName,
      address: form.address,
    };
    loader(true);
    ApiClient.put("user/profile", value).then((res) => {
      if (res.success) {
        let uUser = { ...user, ...value };
        dispatch(login_success(uUser));
        history("/profile");
        toast.success(res.message);
      }
      loader(false);
    });
  };

  const uploadImage = (e) => {
    console.log("calleddddddddddd");
    console.log(e?.target?.files.item(0),'pathhhhhhhhhhhhh');  
    setForm({ ...form, baseImg: e.target.value });
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("upload/image", { file: file }).then((res) => {
      console.log(file,'??????');
      if (res.fileName) {
        let image = res.fileName;
        setForm({ ...form, image: image, baseImg: "" });
      } else {
        setForm({ ...form, baseImg: "" });
      }
      loader(false);
    });
  };

  useEffect(
    () => {
      if (user && user.loggedIn) {
        gallaryData();
      }
    },
    []
  );

  return (
    <>
      <Layout>
        <Html
          handleSubmit={handleSubmit}
          setForm={setForm}
          form={form}
          uploadImage={uploadImage}
          getError={getError}
          ProtofolioError={ProtofolioError}
          setProtofolioError={setProtofolioError}
          addressSellected={addressSellected}
          setAddressSellected={setAddressSellected}
          images={images}
          setimages={setimages}
          submitted={submitted}
        />
      </Layout>
    </>
  );
};

export default EditProfile;
