const website=(state={},action)=>{
    switch(action.type){
        case "GET_DETAILS":
            state=action.data
            return action.data;
            break;
            case "Error":
                return state;
                break;
            default:
return state;
    }
}
export default website;