import React, { useState, useEffect } from "react";
import ApiClient from "../../../apimethods/apiClient";
import loader from "../../../components/loader/loader";
import { useSelector } from "react-redux";
import methodModel from "../../../components/validations/Validations";
import { userType } from "../../../models/type.model";
import Html from "./Html";
import { useNavigate, useParams } from "react-router-dom";
import environment from "../../../environment";
import { toast } from "react-toastify";

const AddEditCustomer = () => {
  const { role, id } = useParams();
  const Modes = useSelector((state) => state.user);
  const [images, setImages] = useState({ image: "", logo: "" });
  const defaultvalue = userType;
  const [form, setform] = useState({
    mode: Modes?.mode,
    dial_code: "+1",
    mobile_no: "",
    first_name: "",
    last_name: "",
    email: "",
  }); 
  const [set, setState] = useState();
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
  const [submitted, setSubmitted] = useState(false);
  const history = useNavigate();
  const [roles, setRoles] = useState([]);
  const [emailLoader, setEmailLoader] = useState(false);
  const [emailErr, setEmailErr] = useState("");
  const [ChangeStatus, setChangeStatus] = useState("");
  const [detail, setDetail] = useState();
  const user = useSelector((state) => state.user);
  const formValidation = [
    { key: "mobileNo", minLength: 10 },
    { key: "role", required: true },
    { key: "ic_number", minLength: 6 },
    { key: "password", minLength: 8 },
    {
      key: "confirmPassword",
      minLength: 8,
      confirmMatch: ["confirmPassword", "password"],
    },
  ];

  const getError = (key) => {
    // return methodModel.getError(key, form, formValidation)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (form.mobile_no == "") {
      toast.error("Mobile Number is Required");
      return false;
    }

    if (form.role == "") return false;
    let method = "post";
    let url = "merchant/customer";
    let value = {
      ...form,
      ...images,
    };
    value.fullName = value.first_name + " " + value.last_name;
    if (value.id) {
      method = "put";
      url = "merchant/customer";
    } else {
      delete value.id;
    }

    if (form.dial_code != "") {
      loader(true);
      ApiClient.allApi(url, value, method).then((res) => {
        if (res.success) {
          toast.success(res.message);
          let url = "/customers";
          // if(role) url="/users/"+role
          history(url);
        }
        loader(false);
      });
    }
  };

  const imageResult = (e, key) => {
    images[key] = e.value;
    setImages(images);
  };

  const addressResult = (e) => {
    setform((state) => {
      return { ...state, address: e.value };
    });
  };

  const back = () => {
    history.goBack();
  };

  const getRoles = () => {
    ApiClient.get("roles/listing", { status: "active" }).then((res) => {
      if (res.success) {
        let newarray = [];
        res.data &&
          res.data.map((item, index) => {
            if (item.id != environment.adminRoleId) {
              newarray.push(item);
            }
          });
        setRoles(newarray);
      }
    });
  };

  const emailCheck = (email) => {
    let isValid = methodModel.emailvalidation(email);
    if (isValid) {
      setEmailLoader(true);
      ApiClient.get("check/Email", { email: email }).then((res) => {
        if (!res.success) {
          if (detail?.email != email) {
            setEmailErr(res.error.message);
          }
        } else {
          setEmailErr("");
        }
        setEmailLoader(false);
      });
    }
  };

  useEffect(() => {
    setSubmitted(false);
    // setState()
    if (id) {
      loader(true);
      ApiClient.get("merchant/customer", { id }).then((res) => {
        if (res.success) {
          let value = res.data;
          setDetail(value);
          setform({
            mode: value?.mode,
            dial_code: value?.dial_code,
            mobile_no: value?.mobile_no,
            first_name: value?.first_name,
            last_name: value?.last_name,
            email: value?.email,
          });
          setImages(value?.image);
        }
        loader(false);
      });
    }
    getRoles();
  }, [id]);

  return (
    <>
      <Html
        form={form}
        detail={detail}
        emailCheck={emailCheck}
        emailLoader={emailLoader}
        emailErr={emailErr}
        ChangeStatu={ChangeStatus}
        back={back}
        setEyes={setEyes}
        eyes={eyes}
        role={role}
        setform={setform}
        roles={roles}
        submitted={submitted}
        images={images}
        addressResult={addressResult}
        handleSubmit={handleSubmit}
        imageResult={imageResult}
        getError={getError}
        id={id}
      />
    </>
  );
};

export default AddEditCustomer;
