import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApiClient from '../../apimethods/apiClient';
import './style.scss';
import Html from './html';

const Balance = (p) => {
    const user = useSelector(state => state.user)
    const searchState = useSelector((state) => state.search);
    const [filters, setFilter] = useState({ page: 1, count: 50, search: '', isDeleted: false })
    const [data, setData] = useState([])
    const [tab, setTab] = useState('list')
    const [total, setTotal] = useState(0)
    const [loaging, setLoader] = useState(true)
    const [tableCols, setTableCols] = useState([])
    const [roles, setRoles] = useState([]) 
    const modeType = user?.mode ? 'live' : 'sandbox'

    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data})
            getData({ search: searchState.data, page: 1 })
        }
    }, [])

    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data})
            getData({ search: searchState.data, page: 1 })
        }
    }, [user?.mode])

    const getData = (p = {}) => {
        setLoader(true)
        let filter = { ...filters, ...p,mode:modeType}
        let url='balance'
        ApiClient.get(url, filter).then(res => {
            if (res.success) {
                setData(res.data)
                // setTotal(res.total)
            }
            setLoader(false)
        })
    }

    return <><Html
        tab={tab}
        roles={roles}
        filters={filters}
        tableCols={tableCols}
        loaging={loaging}
        data={data}
        total={total}
    />
    </>;
};

export default Balance;
