import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../../apimethods/apiClient";
import loader from "../../../components/loader/loader";
import "./style.scss";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import methodModel from "../../../components/validations/Validations";

const Forgotpassword = () => {
  const history = useNavigate();
  const website = useSelector((state) => state.website);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      history("/dashboard");
    }
  }, []);

  const [form, setForm] = useState({ email: "" });

  useEffect(() => {}, []);

  const hendleSubmit = (e) => {
    e.preventDefault();
    loader(true);

    ApiClient.post("user/forgot/password", { ...form }).then((res) => {
      if (res.success) {
        toast.success(res.message);
        history("/login?message=" + res.message);
      }
      loader(false);
    });
  };

  return (
    <>
      <div className="main_signup bg_img">
        <div className="center-img">
          <div className="container ">
            <div className="row">
              <div className="col-md-12 ">
              <div className="right_side">
                    <div className="logo_image">
                      <img
                        src={methodModel.userImg(website.logo)}
                        className="logo_name"
                      />
                    </div>
                    <form className="centerLogin" onSubmit={hendleSubmit}>
                      <div className="forgot-pw-text ">
                        <h3 className="text-center lgtext">Forgot password?</h3>
                        <p className="para_forget">
                        "It's all good! Enter your email, and we'll send a reset
                        password link your way"
                      </p>
                      </div>
                    
                      <div className="mb-3">
                        <div className="inputWrapper">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control  mb-0 bginput changes"
                            placeholder="Email*"
                            value={form.email}
                            required
                            onChange={(e) =>
                              setForm({ ...form, email: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="buttons mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary loginclass mb-1@Sonakshi plz make a design for  products preview and blogs preview in admin
                          ￼
                          ￼
                          ￼
                          "
                        >
                          Send Recovery Email
                        </button>
                      </div>

                      <p className="accopuntt mt-1">
                        {" "}
                        Just Remember?
                        <Link className="sign_up" to="/login">
                          {" "}
                          Sign in
                        </Link>
                      </p>
                    </form>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgotpassword;
